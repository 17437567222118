import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: 30,
    height: 30,
    margin: 0,
    background: "transparent",
    borderTop: "2px solid #009688",
    borderRight: "2px solid transparent",
    borderRadius: "50%",
  },
  animation: {
    animation: "1s spin linear infinite",
  },
  "@global": {
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      }
    },
  },
}));
