import React from "react";
import useStyles from './table-row.styles'
import clsx from "clsx";
import {TColumn} from "interfaces/table-interface";

export interface TableRowProps {
  className?: string;
  columns?: TColumn[]
  children?: React.ReactNode;
}

function TableRow({ className, columns = [], children }: TableRowProps) {
  const classes = useStyles()

  return (
    <div className={clsx(className, classes.root)}>
      {
        React.Children.toArray(children).map((child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...columns[index] })
          }
          return child
        })
      }
    </div>
  )
}

export default TableRow