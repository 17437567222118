import React from 'react';
import lodash from 'lodash';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  Line,
  CartesianGrid,
  LegendValueFormatter,
  AxisDomain
} from 'recharts';
import { useTheme, Box } from '@material-ui/core';

import VerticalComposedChartTooltip from './vertical-composed-chart-tooltip';

import { IssueAgeingChartValues } from 'interfaces/charts-interface';

import useStyles from './vertical-composed-chart.styles';

interface IProps {
  values: IssueAgeingChartValues[];
  isLoading: boolean;
}

const VerticalComposedChart = (props: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const domain: [AxisDomain, AxisDomain] = [0, props.values[0]?.value];

  const LegendFormatter: LegendValueFormatter = (value, entry) => (
    <span color={entry?.color}>{lodash.startCase(value)}</span>
  );

  return (
    <Box className={classes.verticalChartContainer}>
      <ResponsiveContainer aspect={1} minWidth={305} maxHeight={80}>
        <ComposedChart layout="vertical" data={props.values} className={classes.verticalChart}>
          <CartesianGrid stroke={(theme.palette as any).gunSmoke.main} fill={(theme.palette as any).mustard.main} />

          <XAxis domain={domain} type="number" />
          <YAxis hide type="category" />

          <Tooltip content={<VerticalComposedChartTooltip />} />

          <Legend formatter={LegendFormatter} />

          <Bar dataKey="remediationAge" barSize={30} fill={theme.palette.secondary.main} />

          <Line
            r={7}
            dataKey="openViolationsAge"
            fill={theme.palette.error.main}
            stroke={theme.palette.error.main}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default VerticalComposedChart;
