import Dashboard from "pages/dashboard";
import InventoryOld from "pages/inventory";
import Inventory from "pages/inventoryTest";
import Violations from "pages/violations";
import Compliance from "pages/compliance";
import ActiveRules from "pages/active-rules";
import ProfileSettings from "pages/profile-settings";
import BallotIcon from '@material-ui/icons/Ballot';
import {ReactComponent as DashboardIcon} from "icons/dashboardIcon.svg";
import {ReactComponent as CompilanceIcon} from "icons/compilanceIcon.svg";
import {ReactComponent as MissConfigurationIcon} from "icons/missconfigurationIcon.svg";
import {ReactComponent as InventoryIcon} from "icons/inventoryIcon.svg";
import {ReactComponent as SettingIcon} from "icons/settingIcon.svg";

import { Paths } from "interfaces/route-interface";

const paths: Paths = {
  dashboard: {
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  inventoryOld: {
    name: "InventoryOld",
    path: "/inventoryold",
    component: InventoryOld,
    icon: InventoryIcon,
  },
  inventory: {
    name: "Inventory",
    path: "/inventory",
    component: Inventory,
    icon: InventoryIcon,
  },
  violations: {
    name: "Misconfiguration",
    path: "/violations",
    component: Violations,
    icon: MissConfigurationIcon,
  },
  compliance: {
    name: "Compliance",
    path: "/compliance",
    component: Compliance,
    icon: CompilanceIcon,
  },
  activeRules: {
    name: "Active Rules",
    path: "/active-rules",
    component: ActiveRules,
    icon: BallotIcon,
  },
  profileSettings: {
    name: "Settings",
    path: "/settings",
    component: ProfileSettings,
    icon: SettingIcon,
  },
};

export default paths;
