import { AxiosPromise } from "axios";
import { axiosIntegrationsInstance } from "./http-service";

import {
  Integrations,
  IntegrationServices,
  ServiceSummary,
} from "interfaces/settings-interface";

export const listIntegration = (): AxiosPromise<
  Integrations
> => {
  return axiosIntegrationsInstance.get(`/integrations/v1/services-metadata`);
};

export const integrationService = (serviceType: string, service: string): AxiosPromise<
  IntegrationServices
> => {
  return axiosIntegrationsInstance.get(`/integrations/v1/services-metadata/${serviceType}/${service}`);
};

export const serviceSummary = (serviceType: string): AxiosPromise<
  ServiceSummary
> => {
  return axiosIntegrationsInstance.get(`/integrations/v1/services-metadata/${serviceType}/summary`);
};

export const deleteIntegrationService = (
  serviceType: string,
  service: string,
): AxiosPromise => {
  return axiosIntegrationsInstance.delete(`/integrations/v1/services/${serviceType}/${service}`);
};

export const putIntegrationService = (
  serviceType: string,
  service: string,
  body: any,
): AxiosPromise => {

  return axiosIntegrationsInstance.put(`/integrations/v1/services/${serviceType}/${service}`, {
    ...body,
  });
};

export const putIntegrationServiceToggle = (
  serviceType: string,
  service: string,
  data: string,
): AxiosPromise => {
  return axiosIntegrationsInstance.put(`/integrations/v1/services/${serviceType}/${service}/${data}`);
};