import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  expansionRow: {
    backgroundColor: (theme.palette as any).tableBackground.main,
  },
  expansionCell: {
    padding: "0 !important",
    border: "none",
  },
  detailsColumn: {
    padding: theme.spacing(7, 9),
    borderRight: `solid 1px ${(theme.palette as any).alto.main}`,
  },
  expandButton: {
    borderRadius: "50%",
    border: `solid 1px ${(theme.palette as any).alto.main}`,
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  resourceText: {
    wordWrap: "break-word",
    display: "inline",
  },
  tableCell: {
    wordBreak: "break-word",
  },
  assetCell: {
    minWidth: theme.spacing(100),
  },
}));
