import { EventEmitter } from "events";

var eventEmitter = new EventEmitter();

export enum DialogEventKeys {
  CONFIRM = "CONFIRM",
}

export enum Events {
  DialogEvent = "DIALOG_{0}",
}

const createEventKey = (event: Events, key: string) => {
  switch (event) {
    case Events.DialogEvent:
      return event.replace("{0}", key);
    default:
      throw new Error("event not defined");
  }
};

const eventFactory = {
  once: (event: Events, key: string, listener: (...args: any[]) => void) => {
    eventEmitter.once(createEventKey(event, key), listener);
  },
  emit: <T>(event: Events, key: string, props?: T) => {
    eventEmitter.emit(createEventKey(event, key), props);
  },
  removeAllListeners: (event: Events, key: string) => {
    eventEmitter.removeAllListeners(createEventKey(event, key));
  },
  // attach
};

export default eventFactory;
