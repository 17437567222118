import React, {useState} from "react";
import useStyles from './date-picker.styles'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import clsx from "clsx";
import Button from "../button";
import {dateToString, getFormattedDate} from "../../utils/text-utils";
import DropdownWrapper from "../dropdown-wrapper";
import {ReactComponent as CalendarIcon} from "icons/calendarIcon.svg";

interface DatePickerProps {
  className?: string;
  name: string;
  value: string;
  adopted?: boolean;
  onChange: (e: { target: { name: string, value: string } }) => void
  showIcon?: boolean; 
}

function DatePicker({ className, name, value, onChange, adopted = false, showIcon = false }: DatePickerProps) {
  const classes = useStyles()
  const [isOpened, setOpened] = useState(false)

  function toggleDropdown() {
    setOpened(prevState => !prevState)
  }

  function handleClose() {
    setOpened(false)
  }

  function handleDayClick(day: Date) {
    onChange({ target: { name, value: dateToString(day) } })
  }

  return (
    <div className={classes.calWrapper}>
      <DropdownWrapper
        className={[clsx(className, classes.root)].join(" ")}
        opened={isOpened}
        onClose={handleClose}
        position="center"
        triggerEl={
          <Button className={[clsx(classes.btnDate, { [classes.grayText]: adopted })]} onClick={adopted ? undefined : toggleDropdown} type={'icon'}>
            { getFormattedDate(value) }
          </Button>
        }>
        <DayPicker
        className={[classes.picker].join(" ")}
        selectedDays={new Date(value)}
        onDayClick={handleDayClick} /> 
      </DropdownWrapper>
      {showIcon === true?
        <CalendarIcon onClick={adopted ? undefined : toggleDropdown}/> 
      : null}
    </div>
    
  )
}

export default DatePicker
