import get from 'lodash/get';
import { Rule } from 'interfaces/rule-interface';
import { Column, Row } from 'interfaces/table-interface';
import { IExpandedRule } from 'interfaces/active-rules-interface';
import { Alignment } from 'interfaces/table-interface';

const VALUE_NOT_FOUND = 'Not defined';

const customColumns: Column[] = [
  {
    title: 'Platform',
    sortable: true
  },
  {
    title: 'Service',
    sortable: false
  },
  {
    title: 'Rule name',
    sortable: false
  },
  {
    title: 'Risk rating',
    sortable: false
  },
  {
    title: 'Actions',
    sortable: false,
    alignment: Alignment.center
  }
];

const defaultColumns: Column[] = [
  {
    title: 'Platform',
    sortable: true
  },
  {
    title: 'Service',
    sortable: false
  },
  {
    title: 'Rule name',
    sortable: false
  },
  {
    title: 'Risk rating',
    sortable: false
  },
  {
    title: 'Actions',
    sortable: false,
    alignment: Alignment.center
  }
];

type RowsType = Row<string | boolean, IExpandedRule>;
type ReturnValue = [Column[], RowsType[]];

const useActiveRules = (rules: Rule[], ruleType: string): ReturnValue => {
  const rows: RowsType[] = [];

  for (const rule of rules) {
    const platform: string = get(rule, 'platform', VALUE_NOT_FOUND) as string;
    const service: string = get(rule, 'service', VALUE_NOT_FOUND) as string;
    const ruleName: string = get(rule, 'name', VALUE_NOT_FOUND) as string;
    const riskRating: string = get(rule, 'severity', VALUE_NOT_FOUND) as string;
    const isEnabled: boolean = get(rule, 'is_active', false) as boolean;
    const ruleId: string = get(rule, 'rule_id', '') as string;

    const description: string = get(rule, 'description', VALUE_NOT_FOUND) as string;
    const remediation: string = get(rule, 'remediation', VALUE_NOT_FOUND) as string;
    const query: string = get(rule, 'properties.query', VALUE_NOT_FOUND);

    if (ruleType === 'default') {
      rows.push({
        values: [platform, service, ruleName, riskRating, isEnabled],
        ruleId,
        expanded: {
          description,
          remediation
        }
      });
    } else if (ruleType === 'custom') {
      rows.push({
        values: [platform, service, ruleName, riskRating, isEnabled],
        ruleId,
        expanded: {
          description,
          remediation,
          query
        }
      });
    }
  }

  const columns = ruleType === 'default' ? defaultColumns : customColumns;

  return [columns, rows];
};

export default useActiveRules;
