import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapperText: {
    width: "100%",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "0 0px 10px 0",
    '& input': {
      paddingLeft: "10px",
      position: "relative",
    top: "4px",
    }
  },
  btnTrigger: {
    position: "relative",
    width: '400px',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px 8px 0px 8px",
    marginTop: "30px",
    '&:hover': {
      borderColor: "#1B2E3F",
    },
    '&:focus-within': {
      border: "2px solid #1B2E3F",
      borderColor: "#1B2E3F",
    },
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#1B2E3F',
      textAlign: 'left',
      width: "100%",
    },
    '& span': {
      fontWeight: 600,
      color: '#99A9B8',
      fontSize: 14,
      lineHeight: '14px',
      background: 'rgba(183, 194, 205, 0.2)',
      borderRadius: 4,
      padding: '10px 8px',
      marginLeft: 8,
      marginTop: "9px",
      // marginBottom: "8px",
      '& span':{
        background: 'none',
        cursor: 'pointer',
        marginBottom: "0px",
        marginLeft: 0,
        paddingRight: 0,
      }
    },
    '& svg': {
      minWidth: 24,
      marginLeft: 8,
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)',
      position: "absolute",
      right: "10px",
      top: "8px",
    }
  },
  search: {
    font: "inherit",
    color: "currentColor",
    width: "100%",
    margin: 0,
    display: "block",
    padding: "10px 10px 10px",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    "-webkit-tap-highlight-color": "transparent",
    outline: "initial",
    border: "none",
    borderRadius: "8px 8px 0px 8px",
  },
  wrapperInput: {
    width: "100%",
    position: "relative",
    // marginBottom: "15px",
    // marginTop: "15px"
  },
  wrapperInputWithVal: {
    width: "100%",
    position: "relative",
    // marginTop: "-23px",
    zIndex: 9,
  },
  chipVal: {
    position: "relative",
    top: "10px",
    // left: "10px",
    // zIndex: 9,
    "& span": {
      background: "rgba(183, 194, 205, 0.2)",
      borderRadius: "4px",
      padding: "5px 10px",
      marginBottom: "2px",
      display: "inline-block",
      position: "relative",
      zIndex: 99,
      "& span": {
        background: "none",
        padding: "0px 0px",
        cursor: "pointer",
      }
    }
  },
  chipValTypeData: {
    position: "relative",
    top: "10px",
    "& span": {
      background: "rgba(183, 194, 205, 0.2)",
      borderRadius: "4px",
      padding: "5px 10px",
      marginBottom: "2px",
      display: "inline-block",
      position: "relative",
      zIndex: 99,
      "& span": {
        background: "none",
        padding: "0px 0px",
        left: "0px !important",
        cursor: "pointer",
      }
    },
    "& span:nth-last-child(1)": {
      left: "100px",
      /* margin-left: 7px; */
    }
  },
  wrapperBelowButon: {
    position: "absolute",
    top: "100%",
    right: 0,
    bottom: 0,
  },
  belowButon: {
    background: "rgba(183, 194, 205, 0.1)",
    border: "1px solid #DBE0E6",
    boxSizing: "border-box",
    borderRadius: "0px 0px 6px 6px",
    width: "56px",
    padding: "3px",
    '& span': {
      padding: '3px 4px',
      margin: 0,
    }
  },
  checkButton: {
    minWidth: "22px",
    padding: "5px 5px",
    margin: 0,
    marginRight: "3px",
    height: "22px",
    background: "#33CCA8",
    "&:hover": {
      background: "#33CCA8"
    },
    '& span':{
      marginLeft:0,
      color:"#fff",
    },
    '& svg': {
      top: "4px",
      right: "0px",
      position: "absolute",
  },
  },
  crossButton: {
    minWidth: "22px",
    padding: "5px 5px",
    margin: 0,
    height: "22px",
    '& span':{
      marginLeft:0,
    },
    '& svg': {
      top: "5px",
      right: "-1px",
      position: "absolute",
  }
  },
}));
