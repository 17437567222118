/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, LinearProgress } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { fetchAccounts } from "redux/features/settings-store";
import { fetchRegions } from "redux/features/inventory-store";
import { getHelp } from "redux/features/ui-store";
import useStyles from "./profile-settings.styles";

import SectionWrapper from "components/section-wrapper";
import TabsWrapper from "components/tabs-wrapper";
import ProfileSettingsAccounts from "./profile-settings-accounts";
import ProfileSettingsIntegrations from "./profile-settings-integrations";
import ProfileSettingsNewAccount from "./profile-settings-accounts/profile-settings-new-account";

import { AccountResponse } from "interfaces/settings-interface";
import { AppState } from "interfaces/app-state-interface";
import { InventoryRegionData } from "interfaces/inventory-interface";
import ProfileSettingsPolicies from "./profile-settings-policies";
import ProfileSettingsVendors from "./profile-settings-vendors";
import ProfileSettingsControlTests from "./profile-settings-control-tests";
import Divider from '@material-ui/core/Divider';
const tabs = [
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Integrations",
    value: "integrations",
  },
  {
    label: "Policies",
    value: "policies",
  },
  {
    label: "Vendors",
    value: "vendors",
  },  
  {
    label: "Control-Tests",
    value: "control-tests",
  },
];

const ProfileSettings = () => {
  // route
  const history = useHistory()
  const { search } = useLocation();

  //state
  const [currentTab, setCurrentTab] = useState("accounts");
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  //redux
  const dispatch = useDispatch();
  const allAccounts = useSelector<AppState, AccountResponse[]>(
    (state) => state.settings.accounts
  );
  const regions = useSelector<AppState, InventoryRegionData[]>(
    (state) => state.inventory.regions
  );
  const helpIndex = useSelector<AppState, any>(
    (state) => state.settings.helpIndex
  );
  const accounts = currentTab === "accounts";
  const integrations = currentTab === "integrations";
  const Vendors = currentTab === "vendors";
  const controlTest = currentTab === "control-tests";

  const onPageInit = async () => {
    if (accounts) {
      setLoading(true);
      await dispatch(fetchAccounts());
      await dispatch(getHelp("settings/accountGrid"));
      setLoading(false);
      if (regions.length === 0) {
        await dispatch(fetchRegions());
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let path = search.split('=')[search.split('=').length - 1];
    if(path) {
      setCurrentTab(path)
    } else {
      path = "accounts"
      setCurrentTab("accounts")
    }

    onPageInit();
    history.push({
      pathname: window.location.pathname,
      search: '?tab=' + path
    })
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (isLoading) {
    return <LinearProgress />;
  }

  function changeTabs(currentTab: string) {
    history.push({
      pathname: window.location.pathname,
      search: '?tab=' + currentTab
    })
    setCurrentTab(currentTab)
  }

  const renderAccounts = () => {
    return (
      <Grid container spacing={10}>
        {allAccounts.map((account, index) => (
          <Grid item xs={12} key={`${account.account}-${index}`}>
            <ProfileSettingsAccounts
              accountData={account}
              allRegions={regions}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ProfileSettingsNewAccount />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
        <TabsWrapper styleTab={true} value={currentTab} tabs={tabs} onChange={changeTabs} />
        <SectionWrapper
            title=""
            helpIndex={helpIndex}
            type="formType"
        >
        <Divider className={classes.divider}/>
        {currentTab !== 'policies' && <Box my={10} />}
        {accounts && renderAccounts()}
        {integrations && <ProfileSettingsIntegrations />}
        {currentTab === 'policies' && <ProfileSettingsPolicies />}
        {controlTest && <ProfileSettingsControlTests />}
        {Vendors && <ProfileSettingsVendors/>}
        </SectionWrapper>
    </Grid>
  );
};

export default ProfileSettings;
