import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { useTheme } from '@material-ui/core';

import BarChartSkeleton from 'components/skeletons/charts-skeleton/bar-chart-skeleton';
import ViolationSummaryChartTooltip from './violation-summary-chart-tooltip';

import { MultiLineChartValue } from 'interfaces/charts-interface';
import { Severity } from 'interfaces/violations-interface';

import useStyles from './violations-summary-bar-chart.styles';

interface IProps {
  values: MultiLineChartValue[];
  isLoading?: boolean;
}

const ViolationsSummaryBarChart = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles();

  if (props.isLoading) {
    return <BarChartSkeleton numberOfBars={10} maxBarHeight={150} />;
  }

  return (
    <ResponsiveContainer aspect={2}>
      <BarChart data={props.values} className={classes.barChart}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="shortName" interval={0} angle={-45} textAnchor="end" />
        <YAxis />

        <Legend wrapperStyle={{ bottom: -5 }} />
        <Tooltip content={<ViolationSummaryChartTooltip />} isAnimationActive={false} />

        <Bar
          dataKey={Severity.Low}
          stackId="x"
          fill={(theme.palette as any).blue.main}
          isAnimationActive={false}
        />
        <Bar
          dataKey={Severity.Medium}
          stackId="x"
          fill={(theme.palette as any).yellow.main}
          isAnimationActive={false}
        />
        <Bar
          dataKey={Severity.High}
          stackId="x"
          fill={theme.palette.error.main}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ViolationsSummaryBarChart;
