import React from 'react';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface IProps {
  onChangeText: (text: string) => void;
}

const SearchInput = (props: IProps) => {
  return (
    <TextField
      size="small"
      label="Search"
      variant="outlined"
      color="secondary"
      fullWidth
      InputProps={{
        startAdornment: <SearchIcon color="secondary" />
      }}
      onChange={(e) => props.onChangeText(e.target.value)}
    />
  );
};

export default SearchInput;
