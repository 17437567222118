import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  alignElement:{
    // marginLeft: "20px",
    // padding: "0 20px",
    // position: "relative",
  },
  backButton: {
    background: "none",
    marginBottom: "10px",
    marginLeft: "25px",
  },
  divider: {
    width: "100%",
    backgroundColor: (theme.palette as any).tableBackground.main,
    height: theme.spacing(1),
    // margin: `${theme.spacing(20)}px 0`,
    margin: "10px 0",
    marginBottom: "25px",
  },
  backButtonEle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#7B90A3",
    width: "78px",
    height: "32px",
    textTransform: 'none',
  },
  backIcon:{
    position: "relative",
    fontSize: "12px",
    top: "1px",
  },
  serviceTitle: {
    fontWeight: 500,
    position: "relative",
    top: "4px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "1B2E3F",
  },
  activeSwitch: {
    paddingTop: "12px",
    color: "#61778B",
  },
  status: {
    marginRight: "5px",
  },
  activeSwitcher:{
    marginLeft: "10px",
  },

  DeleteBtn:{
    width: "73px",
    height: "26px",
    background: "#F38F8F",
    color: "#fff",
    borderRadius: '30px',
    textTransform: 'none',
    marginTop: "7px",
  },
  disableButtonGrid: {
    position: 'absolute',
    right: "10px",
    top: "5px",
    width: "320px",
    paddingTop: "5px",
  },
  leftGrid: {
    width: "30%",
    float: "left"
  },
  rightGrid: {
    width: "70%",
    float: "right",
    paddingTop: "10px"
  },
  formTitle: {
    fontWeight: 600,
    position: "relative",
    top: "4px",
    marginBottom: "20px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
    marginLeft: "27px"
  },
  alignLeft: {
    textAlign: "left",
    padding: "0 25px",
  },
  mainBoxContent: {
    marginLeft: "15px",
  },
  saveBtnWrapper: {
    marginBottom: "50px",
  },
  label: {
    paddingTop: "40px",
    
  },
  commonLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#61778B',
    lineHeight: '22px',
  },
  InputField: {
    width: "400px",
    marginTop: "35px"
    /* marginLeft: "100px", */
  },
  switch: {
    display: "inline-block",
    /* marginLeft: "160px", */
  },
  statusActiveBtn:{
    marginTop: "35px"
  },
  connTitle2: {
    fontWeight: 500,
    /* position: "relative",
    top: "50px",
    marginBottom: "30px", */
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    /* lineHeight: "27px", */
    color: "#1B2E3F",
    marginTop: '54px'
  },
  mainCommonLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    color: '#1B2E3F',
  },
  ticket:{
    marginTop: "25px"
  },
  cardPlusMinus: {
    display: "inline-block",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "5px",
  },
  plusMinusbutton : {
    background: "rgba(183, 194, 205, 0.2)",
    border: "1px solid rgba(183, 194, 205, 0.1)",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: "35px",
    minWidth: "40px",
  },
  text: {
    marginLeft: "40px",
    display: "inline-block",
    fontSize: "14px",
    color: "#99A9B8",
    paddingTop: "10px",
  },
  risk: {
    display: "inline-block",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "5px",
  },
  InputField3: {
    width: "400px",
  },
  frequency:{
    marginTop: "10px",
    paddingRight: "15px",
  },
  followup:{
    marginTop: "35px",
  },
  saveConnectBtn: {
    textTransform: "none",
    // padding: theme.spacing(5, 15),
    marginRight: theme.spacing(40),
    borderRadius: "30px",
    background: "#33CCA8",
    marginTop: "40px",
    marginLeft: 0,
    padding: "16px 40px",
  },
  checkbox:{
    display: "inline-block",
    "&.MuiCheckbox-root": {
      "&.icon": {
        border: '1px solid #B7C2CD',
        borderRadius: '5px',
      }
    },
  },
  radiogroup:{
    marginTop: "35px",
  },
  // "& .vendorHelpIndexPg > div":{
  //   marginBottom: "10px !important",
  // },
  // "& .MuiBox-root":{
  //   marginTop: 0,
  //   marginBottom: 0,
  // },
  mainClassVenderForm:{
    marginBottom: "10px !important",
  },
  mainClassVenderFormTpContent:{
    background: "#FDFDFD",
    boxShadow: "1px 1px 0px #F0F0F0",
    paddingTop: "10px",
    paddingBottom: "5px",
  },
  riskthresholdtext:{
    width: "65px",
    '& .MuiInput-input': {
      textAlign:"center",
      color: "#99A9B8",
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },}
  },
  date: {
    display: "inline-block",
    /* marginLeft: "130px", */
    marginTop: "25px",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "13px 16px",
    paddingRight: "50px",
  },
}))