import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, LinearProgress } from "@material-ui/core";

import {
  fetchIntegrationService,
  fetchServiceSummary,
  putIntegrationService,
  deleteIntegrationService,
  fetchIntegrations,
  putIntegrationServiceToggle,
  setHelpIndex
} from "redux/features/settings-store";
import { getHelp, setHelpText } from "redux/features/ui-store";

// import SectionWrapper from "components/section-wrapper";
import IntegrationsForm from "./components/integrations-form";
import IntegrationsCard from "./components/integrations-card";

import { AppState } from "interfaces/app-state-interface";
import {
  IntegrationList,
  IntegrationServices,
  ServiceSummary,
} from "interfaces/settings-interface";

import useStyles from "./profile-settings-integrations.styles";

const ProfileSettingsIntegrations = () => {
  const classes = useStyles();

  const [isServiceFiled, setIsServiceFiled] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const integrationService = useSelector<AppState, IntegrationServices>(
    (state) => state.settings.integrationService
  );
  
  const { fields: integrationFields, help = '', icon = 'https://dummyimage.com/130x130/d1d1d1/0011ff.png', service = '', service_type = '', status = "active" } = integrationService;

  const integrationServices = useSelector<AppState, IntegrationList[]>(
    (state) => state.settings.integrations
  );
  const ServiceSummaryData = useSelector<AppState, ServiceSummary>(
    (state) => state.settings.serviceSummary
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(setHelpText('settings/integrationForm', help));
  }, [help]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);
    await dispatch(setHelpIndex("settings/integrationForm"));
    await dispatch(fetchIntegrations());
    await dispatch(getHelp("settings/integrationsGrid"));
    setLoading(false);
  };

  const onIntegrationClick = async (serviceType: string, service: string) => {
    setLoading(true);
    await dispatch(fetchIntegrationService(serviceType, service));
    await dispatch(fetchServiceSummary(serviceType));
    setIsServiceFiled(true);
    setLoading(false);
  }
  const onDeleteIntegration = async (serviceType: string, service: string) => {
    await dispatch(deleteIntegrationService(serviceType, service));
    await dispatch(fetchIntegrationService(serviceType, service));
  }
  const onBackClick = () => {
    setLoading(true);
    setIsServiceFiled(false);
    setLoading(false);
  }
  
  const onSubmitSavedata = async (serviceType: string, service: string, body: any) => {
    setLoading(true);
    await dispatch(putIntegrationService(serviceType, service, body));
    await dispatch(fetchIntegrationService(serviceType, service));
    setLoading(false);
  }
  const onPutIntegrationServiceToggle = async (serviceType: string, service: string, data: string) => {
    await dispatch(putIntegrationServiceToggle(serviceType, service, data));
    await dispatch(fetchIntegrationService(serviceType, service));
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  
  if (isLoading) {
    return <LinearProgress data-testid="Linear-Progress"/>;
  }
  /* eslint-enable react-hooks/exhaustive-deps */
  
  // will remove once put api integration done
  // useEffect(() => {
  //   onIntegrationClick('Employees', 'Google');
  // }, []);
  
  return (
    <Box className={classes.boxWrapper} data-testid="settings-integrations">
        <Box m={20} mt={0} className={classes.boxWrapperInner}>
          {!isServiceFiled? 
            integrationServices.length !== 0? 
            <>
              {integrationServices.map((integration, index) => {
                return(
                  <React.Fragment key={index}>
                    <Typography variant="body2" data-testid={`service_type-${integration.service_type}-${index}`} className={classes.gridItemBoxTitle}>{integration.service_type}</Typography>
                    <Grid container spacing={10} alignItems="flex-start" justifyContent="flex-start" className={classes.gridBoxWrapperInner}>
                      {integration.services.length !== 0 ? 
                        integration.services.map((service, innerIndex) => {
                          return(
                            <Grid item className={classes.gridItemBox} key={innerIndex} data-testid={`services-${service.name}-${innerIndex}`}>
                              <IntegrationsCard
                                onIntegrationClick={onIntegrationClick}
                                imageUrl={service.icon}
                                serviceType={integration.service_type}
                                service={service.name}
                              />
                            </Grid>
                          )
                        })
                      : null}
                      </Grid>
                  </React.Fragment>
                )
              })}
            </>
            : null
          :
              <React.Fragment>
                <IntegrationsForm
                  icon={icon}
                  service={service}
                  service_type={service_type}
                  onBackClick={onBackClick}
                  status={status}
                  onSubmitSavedata={onSubmitSavedata}
                  integrationFields={integrationFields}
                  ServiceSummary={ServiceSummaryData}
                  onDeleteIntegration={onDeleteIntegration}
                  onPutIntegrationServiceToggle={onPutIntegrationServiceToggle}
                />
              </React.Fragment>
          }
        </Box>
    </Box>
  );
};

export default ProfileSettingsIntegrations;
