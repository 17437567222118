import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  searchInput:{
    position: "relative",
    marginRight: "10px",
    width: '476px',
  },
  bold: {
    fontWeight: 'bold',
  },
  subTabs: {
    order: 1,
    '@media (max-width:900px)': {
      overflow: 'scroll',
      width: "100%",
      order: 2,
      "&::-webkit-scrollbar": {
        display: "none",
      }
    },
    "& button": {
      paddingBottom: "25px",
      paddingTop: "25px",
    }
  },
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: "12px",
    order: 2,
    '@media (max-width:900px)': {
      width: "100%",
      order: 1,
      flexDirection: "column",
      "& button": {
        marginBottom: '5px',
        marginLeft: 0,
      }
    }
  },
  btnDownload: {
    paddingLeft: 24,
    '& svg': {
      marginRight: 8
    }
  },
  inventoryTabs: {
    '& button': {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#1B2E3F",
    }
  },
  btnAdd: {
    marginLeft: "8px",
    '& span': {
      marginRight: 6,
      fontSize: 21,
      fontWeight: 500
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    '@media (max-width:900px)': {
      flexDirection: "column",
    }
  },
  scanNowBtn: {
    padding: "13px 16px !important",
    background: "#497DAB !important",
    borderRadius: "8px !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 500,
    fontSize: "15px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    marginRight: "10px",
  },
  downloadBtn:{
    padding: "11px 16px !important",
    background: "#47C2A4 !important",
    borderRadius: "8px !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 500,
    fontSize: "15px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    "& span":{
      position: "relative",
        left: "8px",
        top: "-1px",
    }
  },
  inventoryTtle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
    marginLeft: "25px"
  },

  headerPaddingRight:{
    paddingRight: '16px',
  },
  btnDownloadWrapper:{
    position: "relative",
    "& ul":{
        position: "absolute",
        background: "#fff",
        width: "150px",
        listStyle: "none",
        padding: 0,
        boxShadow: "0px 0px 8px rgb(90 108 118 / 15%)",
        borderRadius: "10px",
        top: "31px",
        display: "none",
    },
    "& li":{
      padding: "10px 8px",
      textAlign: "center",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#61778B",
    },
    "& li span":{
      position: "relative",
      top: "3px",
      left: "-2px",
    }
  }
}));