import React from 'react';
import clsx from "clsx";
import TabsSkeleton from 'components/skeletons/tabs-skeleton';

import { Tab as ITab } from 'interfaces/tabs-wrapper';

import useStyles from './tabs-wrapper.styles';

interface IProps<T> {
  className?: string;
  value: T;
  tabs: ITab<T>[];
  isLoading?: boolean;
  onChange: (value: T) => void;
  styleTab?: boolean;
}

function TabsWrapper<T>(props: IProps<T>) {
  const classes = useStyles();

  function handleClick(value: T) {
    return function () {
      props.onChange(value)
    }
  }

  if (props.isLoading && props.tabs.length === 0) {
    return <TabsSkeleton />;
  }

  return (
    <div className={props.styleTab === true ?  clsx(props.className, classes.root, classes.rootStyle) : clsx(props.className, classes.root)}>
      {props.tabs.map((tab, index) => (
        <button
          key={`tab-${index}`}
          className={props.styleTab === true ?  clsx(classes.tab, classes.tabStyle, { [classes.tabActiveStyle]: tab.value === props.value }) : clsx(classes.tab, { [classes.tabActive]: tab.value === props.value }) }
          onClick={handleClick(tab.value)}>
          { tab.label }
          {
            tab.count !== undefined &&
            <span className={classes.count}>
              {tab.count}
            </span>
          }
        </button>
      ))}
    </div>
  );
}

export default TabsWrapper;
