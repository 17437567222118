import React from 'react';
import clsx from 'clsx';
import { Grid, Typography, TableCell } from '@material-ui/core';

import { useViolationAssets } from 'hooks/useViolationsTable';

import TableWrapper from 'components/table-wrapper';
import ViolationsRowAssets from '../violations-row-assets';

import useStyles from './violations-row-expanded.styles';

interface IProps {
  ruleId: string;
}

const ViolationsRowExpanded = ({ ruleId }: IProps) => {
  const classes = useStyles();
  const violationAssets = useViolationAssets(ruleId);

  if (!violationAssets) {
    return null;
  }

  const [columns, rowExpandedData] = violationAssets;

  return (
    <div className={classes.expandedRowWrapper}>
      <Grid container>
        <Grid item xs={6} className={clsx(classes.withBorder, classes.containerPadding)}>
          <div className={classes.headingStyle}>Rule Description</div>
          <Typography variant="body2" className={classes.bodyText}>{rowExpandedData?.description}</Typography>
        </Grid>
        <Grid item xs={6} className={clsx(classes.withBorder, classes.containerPadding)}>
          <div className={classes.headingStyle}>Remediation</div>
          <Typography variant="body2" className={classes.bodyText}>{rowExpandedData?.remediation}</Typography>
        </Grid>
        <Grid item xs={12} className={clsx(classes.withBorder, classes.containerPadding)}>
          {rowExpandedData && (
            <TableWrapper
              columns={columns}
              rows={rowExpandedData.assetsDetails}
              emptyColumn={<TableCell size="small" />}
              renderRow={(row, i) => (
                <ViolationsRowAssets ruleId={ruleId} row={row} index={i} key={`row-${i}`} />
              )}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default ViolationsRowExpanded;
