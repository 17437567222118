import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 500ms ${theme.transitions.easing.easeOut}`
  },
  animatedItemExiting: {
    animation: `$myEffectExit 1000ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
    transform: "translateY(-200%)"
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
      marginLeft: '100%',
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
      marginLeft: '0%',
    }
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
      marginLeft: '100%',
    },
    "100%": {
      opacity: 0,
      transform: "translateY(200%)",
      marginLeft: '0%',
    }
  },
  boxWrapper: {
    position: "relative",
  },
  boxWrapperInner: {
    // margin: "20px",
    position: "relative",
    top: "-19px",
  },
  connectBtn: {
    textTransform: "none",
    padding: theme.spacing(5, 15),
    marginRight: theme.spacing(40),
    marginLeft: "12px",
    borderRadius: "30px",
    background: "#33CCA8"
  },
  saveConnectBtn: {
    textTransform: "none",
    // padding: theme.spacing(5, 15),
    marginRight: theme.spacing(40),
    borderRadius: "30px",
    background: "#33CCA8",
    marginTop: "40px",
    marginLeft: 0,
    padding: "16px 40px",
  },
  mainHeading: {
    padding: "32px 40px",
    background: "#FDFDFD",
    boxShadow: "1px -1px 0px #F0F0F0, 1px 1px 0px #F0F0F0",
    marginBottom: "2px",
  },
  slightTopMargin: {
    marginTop: theme.spacing(5),
  },
  activeSwitch: {
    marginRight: "10px",
  },
  timelineContent: {
    textAlign: "left",
    padding: "0",
  },
  alignLeft: {
    textAlign: "left",
    // padding: "32px 40px",
    padding: "0px 0px",
  },
  formTitle: {
    fontWeight: 600,
    position: "relative",
    top: "4px",
    marginBottom: "0px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
  },
  formBlwBxTitle: {
    fontWeight: 600,
    position: "relative",
    top: "4px",
    marginBottom: "20px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#1B2E3F",
  },
  connTitle: {
    fontWeight: 500,
    position: "relative",
    top: "0px",
    marginBottom: "20px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
  },
  connTitle2: {
    fontWeight: 500,
    /* position: "relative",
    top: "50px",
    marginBottom: "30px", */
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    /* lineHeight: "27px", */
    color: "#1B2E3F",
    marginTop: '54px'
  },
  connTitle3: {
    fontWeight: 500,
    /* position: "relative",
    top: "50px",
    marginBottom: "30px", */
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    /* lineHeight: "27px", */
    color: "#1B2E3F",
    marginTop: '35px'
  },
  mainBoxContent: {
    marginLeft: "0px",
  },
  connStatusBox: {
    border: "1px solid #3333",
    height: "80px",
    borderRadius: "5px",
    padding: "16px",
    color: "#61778B",
  },
  timeBtn: {
    background: "#B7C2CD",
    marginLeft: "8px",
    fontSize: "9px",
    padding: "2px",
    color: "#FFFFFF",
    position: "relative",
    top: "-2px",
  },
  connStatusBoxPadd: {
    padding: "0px 10px",
  },
  backButton: {
    background: "none",
    marginBottom: "15px",
  },
  timelineDotFill: {
    background: "#33CCA8 !important",
  },
  timelineDot: {
    padding: "0px 6px !important",
    marginTop: "1px !important",
    boxShadow: 'none !important',
  },
  timelineConnector: {
    color: "#61778B",
    opacity: 0.3,
  },
  errorMsg: {
    background: "#FFEBEB",
    position: "relative",
    padding: "5px 10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  crossIcon: {
    background: "#FFEBEB",
    marginRight: "5px",
    position: "absolute",
    color: "#F38F8F",
    height: '20px',
    width: '15px',
  },
  errorText: {
    marginLeft: "25px",
    color: "#F38F8F",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  errorTopText: {
    display: "inline-block",
    background: "#FFEBEB",
    position: "relative",
    left: "10px",
    padding: "2px 5px",
    color: "#F38F8F",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    alignItems: 'center',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
  backIcon: {
    position: "relative",
    fontSize: "12px",
    top: "1px",
  },
  InputField: {
    width: "400px",
    marginTop: "35px"
    /* marginLeft: "100px", */
  },
  InputField1: {
    width: "400px",
    marginTop: "30px"
    /* marginLeft: "47px", */
  },
  InputField3: {
    width: "400px",
    marginTop: "35px"
    /* marginLeft: "140px", */
  },
  InputLabel: {
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#61778B",
  },
  saveBtnWrapper: {
    /* marginBottom: "50px", */
  },
  timelineContentWrapper: {
    position: "relative",
    padding: "0",
    border: "1px solid #F3F5F6",
    borderBottom: 0,
    paddingTop: "15px",
  },
  disableButtonGrid: {
    position: 'absolute',
    right: "10px",
    top: "5px",
    width: "320px",
    paddingTop: "15px",
  },
  leftGrid: {
    width: "30%",
    float: "left"
  },
  rightGrid: {
    width: "70%",
    float: "right",
  },
  serviceTitle: {
    Weight: 500,
    position: "relative",
    top: "4px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "1B2E3F",
  },
  DeleteBtn: {
    width: "73px",
    height: "26px",
    background: "#F38F8F",
    color: "#fff",
    borderRadius: '30px',
  },
  divider: {
    width: "100%",
    backgroundColor: (theme.palette as any).tableBackground.main,
    height: theme.spacing(1),
    // margin: `${theme.spacing(20)}px 0`,
    // margin: "10px 0",
    marginBottom: "0px",
  },
  alignElement: {
    // marginLeft: "20px",
    padding: "0 20px",
  },
  backButtonEle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#7B90A3",
    width: "78px",
    height: "32px",
    textTransform: 'none',
  },
  controltxt: {
    display: "inline-block",
    position: "relative",
    top: "0px",
    marginBottom: "5px",
  },
  label: {
    paddingTop: "40px",
  },
  badge: {
    borderRadius: '4px',
    border: "2px solid #F3F5F6",
    background: "#FFFFFF",
    color: "#99A9B8",
    width: "85px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: "14px",
    padding: "4px 4px",
  },
  button: {
    background: "#99A9B8",
    size: "small",
    height: "25px",
    width: "25px",
  },
  plusMinusbutton : {
    background: "rgba(183, 194, 205, 0.2)",
    border: "1px solid rgba(183, 194, 205, 0.1)",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: "35px",
    minWidth: "40px",
  },
  card: {
    display: "inline-block",
    /* marginLeft: "90px", */
    border: "1px solid #F3F5F6",
  },
  cardPlusMinus: {
    display: "inline-block",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "5px",
  },
  risk: {
    display: "inline-block",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "5px",
  },
  text: {
    marginLeft: "40px",
    display: "inline-block",
    fontSize: "14px",
    color: "#99A9B8"
  },
  date: {
    display: "inline-block",
    /* marginLeft: "130px", */
    marginTop: "25px",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "13px 16px",
    paddingRight: "50px",
  },
  switch: {
    display: "inline-block",
    /* marginLeft: "160px", */
  },
  commonLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#61778B',
    lineHeight: '22px',
  },
  mainCommonLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    color: '#1B2E3F',
  },
  ticket:{
    marginTop: "25px"
  },
  statusActiveBtn:{
    marginTop: "35px"
  },
  controlTextBox: {
    '& p': {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#1B2E3F",
      margin: 0,
      textAlign: "justify",
    }
  },
  fieldWidthTextarea: {
    // width: "400px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2E3F",
    padding: "10px",
    // border: "1px solid rgba(183, 194, 205, 0.5)",
    width: "100%",
    borderRadius: "8px 8px 8px 8px",
    border: "none",
    height: "60px !important",
    overflow: "auto !important",
    resize: "none",
    '&:hover': {
      border: 0 + '!important',
    },
    '&:focus-visible': {
      border: 0 + '!important',
      outline: 0,
    },
  },
  clanderIcon: {
    
  },
  mainWrapperAddEdit: {
    background: "#FDFDFD",
    // boxShadow: "1px -1px 0px #F0F0F0, 1px 1px 0px #F0F0F0",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "32px",
  },
  form: {
    padding: "32px 5px",
    marginLeft: "20px",
  },
  riskthresholdtext:{
    width: "65px",
    
    '& .MuiInput-input': {
      textAlign:"center",
      color: "#99A9B8",
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },}
  }
}));
