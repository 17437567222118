import React from "react";

export interface Column {
  title: string;
  sortable: boolean;
  alignment?: Alignment;
}

export interface Row<T, K = undefined> {
  values: T[];
  ruleId: string;
  expanded?: K;
}

export enum Alignment {
  center = 'center',
  left = 'left',
  right = 'right'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface TColumn {
  className?: string;
  field?: string;
  title?: string;
  children?: React.ReactNode;
  maxWidth?: number | string | undefined;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  noWrap?: boolean;
  flex?: string;
  display?: string,
  justifyContent?: string,
}
