import React, { useState, useRef } from "react";
import useStyles from './DropDownPolicy.style';
import clsx from "clsx";
import {AppState} from "interfaces/app-state-interface";
import {useSelector} from "react-redux";
import {Policy} from 'interfaces/settings-interface';
import { Option } from "interfaces/common-intefaces";
import DropdownWrapper from "components/dropdown-wrapper";
import CheckboxGroup from "components/checkbox-group/checkbox-group";
import { ReactComponent as CrossIcon } from "icons/cross.svg";
import ChipInput from 'material-ui-chip-input';
import { ReactComponent as TriangleIcon } from "icons/triangle.svg";

interface DropDownPolicyprops {
    options: Option[];
    name: string;
    values?: string[];
    itemId?: string;
    adopted?: boolean;
    tab?: string;
    onChange: (e: { target: { name: string, value: string[] } }) => void
}

function DropDownPolicy({ options, name, values = [], onChange, itemId, adopted = false, tab = '' }: DropDownPolicyprops) {
    const classes = useStyles();
    const [opened, setOpened] = useState(false);
    const [searchPolicy, setSearchPolicy] = useState<string>('');
    const policyInputRef = useRef<HTMLInputElement>(null)

    let resultSearchPolicies: Option[] = useSelector<AppState, Policy[]>(
        (state) => state.settings.activePolicies
      ).map((item) => {
        return {label: item.policy_name, value: item.policy_name}
      });

    function toggleDropdown() {
        setOpened(prevState => !prevState)
    }

    function handleClose() {
        setOpened(false)
    }

    function handleCross(index: any) {
        let updatedValues = [...values]
        updatedValues = values.filter(value => value !== values[index])
        onChange({ target: { name, value: updatedValues } })
    }

    const resultSearchPoliciesData = resultSearchPolicies.filter(policy => policy.value.toUpperCase().indexOf(searchPolicy.toUpperCase()) > -1);
    return (
        <DropdownWrapper
        className={classes.root}
        opened={opened}
        search={true}
        topicsSearch={true}
        addTopAuto
        triggerEl={
            <div className={classes.btnTrigger}>
                <div className={classes.wrapperText} data-tip data-for={'registerTip-' + itemId + ((values?.length === 0) ? 'Topic' : values[values?.length - 1])} >
                    <div className={values?.length === 0 ? clsx(classes.wrapperInput) : clsx(classes.wrapperInputWithVal)} onClick={adopted ? undefined : toggleDropdown}>
                        <ChipInput
                            ref={policyInputRef}
                            fullWidthInput
                            fullWidth
                            placeholder= {values?.length === 0 ? "Associated Policy(s)" : ""}
                            alwaysShowPlaceholder
                            disableUnderline
                            chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) : any => {
                                return (
                                <React.Fragment key={key + itemId}><span>{value}<span onClick={() => {handleCross(key);}}><CrossIcon/></span></span>
                                </React.Fragment>
                                )
                            }}
                            value={values}
                            onUpdateInput={(e: any) => setSearchPolicy(e.target.value)}
                            onDelete={(chip, index) => handleCross(index)}
                        />
                        <span className={classes.triabgleButton}>{adopted ? "" : <TriangleIcon onClick={toggleDropdown} />}</span>
                    </div>
                </div>
            </div>
        }
        onClose={handleClose}>
           {
                resultSearchPoliciesData.length === 0 && <div className={classes.notResult}>No Policy found</div>
           }
           {resultSearchPolicies &&
            <CheckboxGroup
                name={name}
                tab={tab}
                checkboxClassName={classes.checkbox}
                options={resultSearchPoliciesData}
                values={values}
                onChange={onChange} />
          }
    </DropdownWrapper>
    );
}

export default DropDownPolicy;