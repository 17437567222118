import { ThemeOptions } from '@material-ui/core';

const palette: ThemeOptions['palette'] = {
  text: {
    primary: '#1B2E3F'
  },
  subtext: {
    main: '#61778B'
  },
  primary: {
    main: '#2B4964',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#47C2A4',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#F05E5E'
  },
  example: {
    main: '#CCCCCC'
  },
  tableBackground: {
    main: '#F3F5F6'
  },
  alto: {
    main: '#E0E0E0'
  },
  gallery: {
    main: '#EBEBEB'
  },
  gunSmoke: {
    main: '#878b89'
  },
  royalBlue: {
    main: '#51BDCD'
  },
  mercury: {
    main: '#e8e8e8'
  },
  silver: {
    main: '#C9C9C9'
  },
  oxfordBlue: {
    main: '#384856'
  },
  yellow: {
    main: '#EFBE5F'
  },
  blue: {
    main: '#4BC6D9'
  },
  mineShaft: {
    main: '#333'
  },
  dustyGrey: {
    main: '#999'
  },
  mustard: {
    main: '#FFD54F'
  },
  primaryBarChartShades: {
    shade_400: '#4F7D91',
    shade_300: '#6797AC',
    shade_200: '#89AEBE',
    shade_100: '#A9C4D0'
  },
  tertiaryTreemapShades: {
    shade_400: '#2B4E5E',
    shade_300: '#446878',
    shade_200: '#5F8393',
    shade_100: '#8DA7B2'
  }
} as any;

export default palette;
