import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > div': {
      padding: 0,
      transformOrigin: 'left top',
      transform: 'scale(0.8) translateX(-50%) !important'
    }
  },
  dropdown: {
    position: 'absolute',
    top: '100%'
  },
  picker: {
    '& div': {
      borderRadius: '0'
    },
    '& .DayPicker-Day.DayPicker-Day--today': {
      background: "#ebebeb",
      color: "initial",
      borderRadius: "8px",
      position: "relative",
      // left: "3px",
      left: "-2px",
    },
    '& .DayPicker-Day.DayPicker-Day--selected': {
      // background: "#ebebeb",
      // color: "initial",
      borderRadius: "8px",
      position: "relative",
      // right: "2px",
      right: "0px",
    },
  },
  grayText: {
    color: "rgb(183, 194, 205) !important",
  },
  btnDate: {
    width: '100%',
    textAlign: 'center',
    // fontFamily: `"Inter", sans-serif`,
    fontSize: "16px",
    fontWeight: 500,
    color: '#1B2E3F',
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "24px",
  },
  calWrapper: {
    position: "relative",
    display: "inline-block",
    '& svg': {
      position: "absolute",
      top: "39px",
      right: "24px",
      cursor: "pointer",
      color: "#DADADA",
    }
  }
}));
