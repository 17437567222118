import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCell } from "@material-ui/core";

import {
  onPageInit,
  scanViolations,
  downloadViolations,
  fetchViolations,
} from "redux/features/violations-store";
import { useViolationsRules } from "hooks/useViolationsTable";

import SectionWrapper from "components/section-wrapper";
import TableWrapper from "components/table-wrapper";
import ViolationsRow from "./components/violations-row";

import { AppState } from "interfaces/app-state-interface";
import useStyles from "./violations.styels";

const Violations = () => {
  const classes = useStyles();
  //state
  const [search, setSearch] = useState<string>("");
  const [isSuppressed, setSuppressed] = useState<boolean>(false);

  //hooks
  const [violationColumns, violationsRow] = useViolationsRules();

  //redux
  const isLoading = useSelector<AppState, boolean>(
    (state) => state.ui.isLoading
  );
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleDownloadXLS = async () => {
    await dispatch(downloadViolations("xls"));
  };

  const handleDownloadJSON = async () => {
    await dispatch(downloadViolations("json"));
  };

  const handleOnScan = async () => {
    await dispatch(scanViolations(isSuppressed));
  };

  const onToggleVisibility = (isVisible: boolean) => {
    setSuppressed(!isVisible);
    dispatch(fetchViolations(!isVisible));
  };

  return (
    <>
      <SectionWrapper
        title="Cloud-Misconfig"
        helpIndex="violations/table"
        onScan={handleOnScan}
        scanBtnTitle="Re-run Policies"
        onDownloadJSON={handleDownloadJSON}
        onDownloadXLS={handleDownloadXLS}
        onSearch={setSearch}
        onToggleVisibility={onToggleVisibility}
        visibilityTooltipText="Filter/Unfilter suppressed misconfigurations"
        isDownloadIcon
      >
        <div className={classes.tabelContainer}>
          <TableWrapper
            isLoading={isLoading}
            search={search}
            rows={violationsRow}
            columns={violationColumns}
            emptyColumn={<TableCell size="small" />}
            renderRow={(row, i) => <ViolationsRow row={row} key={`row-${i}`} />}
          />
        </div>
      </SectionWrapper>
    </>
  );
};

export default Violations;
