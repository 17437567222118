import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  expansionRow: {
    // backgroundColor: (theme.palette as any).tableBackground.main,
    '& td':{
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    '& td > div:nth-child(1)':{
      background: "rgba(174, 234, 220, 0.18)",
      border: "1px solid rgba(174, 234, 220, 0.3)",
      boxSizing: "border-box",
      borderRadius: "10px",
      margin: "10px 10px",
    }
  },
  nonExpansionRow: {
    '& td':{
      borderBottom: 0,
    }
  },
  expansionCell: {
    padding: "0 !important",
    border: "none",
  },
  detailsColumn: {
    padding: theme.spacing(7, 9),
    // borderRight: `solid 1px ${(theme.palette as any).alto.main}`,
  },
  expandButton: {
    borderRadius: "50%",
    border: `solid 1px ${(theme.palette as any).alto.main}`,
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  resourceText: {
    wordWrap: "break-word",
    display: "inline",
  },
  tableCell: {
    wordBreak: "break-word",
  },
  assetCell: {
    minWidth: theme.spacing(100),
  },
}));
