import React, {useState} from "react";
import useStyles from './policy-templates-table.styles'
import Table from "../../../../../components/table";
import Spinner from "../../../../../components/spinner";
import {TColumn} from "../../../../../interfaces/table-interface";
import TableRow from "../../../../../components/table/table-row";
import TableColumn from "../../../../../components/table/table-column";
import TopicsDropdown from "../../topics-dropdown";
import OwnerDropdown from "../../owner-dropdown";
import Switcher from "../../../../../components/switcher";
import {TableRowProps} from "../../../../../components/table/table-row/table-row";
import Button from "../../../../../components/button";
import {ReactComponent as PdfIcon} from "../../../../../icons/pdf.svg";
import {ReactComponent as MagicPenIcon} from "icons/magic-pen.svg";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../interfaces/app-state-interface";
import {Option} from "../../../../../interfaces/common-intefaces";
import {
  createPolicy,
  downloadTemplateFile,
  fetchTemplates
} from "../../../../../redux/features/settings-store";
import DatePicker from "../../../../../components/date-picker";
import {dateToString} from "../../../../../utils/text-utils";
import {Policy} from "../../../../../interfaces/settings-interface";
import Badge from "../../../../../components/badge";
import ReactTooltip from "react-tooltip";

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(24% - 24px)',
    field: 'policy',
    title: 'Policy'
  },
  {
    flex: '1 0 calc(20% - 24px)',
    field: 'topics',
    title: 'Topics Covered'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    align: 'center',
    field: 'reviewDate',
    title: 'Review Date'
  },
  {
    flex: '1 0 calc(20% - 24px)',
    align: 'center',
    field: 'owner',
    title: 'Owner'
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'attestation',
    title: 'Ann. Empl. Attestation',
    noWrap: false
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'file',
    title: 'Policy File',
    noWrap: false
  },
  {
    flex: '1 0 calc(7% - 24px)',
    align: 'center',
    field: 'actions',
    title: 'Generate Policy',
    noWrap: false
  },
]


interface TableItemProps extends TableRowProps {
  item: Policy,
  setTab: (tab: string) => void
}

function TableItem({ item, setTab, ...otherProps }: TableItemProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [hideTooltipPolicyName, setHideTooltipPolicyName] = useState(false)
  const topicsOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.topics.map(value => ({ label: value, value }))
  );
  const ownersOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.employees.map(({ name }) => ({
      label: name,
      value: name
    }))
  );

  const [name, setName] = useState(item.policy_name)
  const [topics, setTopics] = useState<string[]>(item.topics)
  const [adopted] = useState<boolean | undefined>(item.adopted)
  const [owner, setOwner] = useState('')
  const [attestation, setAttestation] = useState(item.reattestation_by_employee === 'yes')
  const [reviewDate, setReviewDate] = useState(dateToString(new Date()))
  const [policyCreated, setPolicyCreated] = useState(false)
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false)
  const [isLoadingGeneratePolicy, setIsLoadingGeneratePolicy] = useState<boolean>(false)

  function handleChange({ target: { name, value } }: { target: { name: string, value: any } }) {
    if (name === 'topics')
      setTopics(value)
    if (name === 'owner')
      setOwner(value)
    if (name === 'attestation')
      setAttestation(value)
    if (name === 'reviewDate')
      setReviewDate(value)
    if (name === 'name')
      setName(value)
  }

  async function handleGeneratePolicy() {
    const data: Policy = {
      ...item,
      policy_name: name,
      topics,
      owner_name: owner,
      reattestation_by_employee: attestation ? 'yes' : 'no',
      policy_review_date: reviewDate,
      status: 'active'
    }

    if (item.template_file)
      data.policy_file = item.template_file

    setIsLoadingGeneratePolicy(true)

    await dispatch(createPolicy(data))
    setPolicyCreated(true)
    dispatch(fetchTemplates())

    setIsLoadingGeneratePolicy(false)
    setTab('active')
  }

  async function downloadFilePolicy() {
    setIsLoadingDownloadFile(true)
    await dispatch(downloadTemplateFile(item.policy_name))
    setIsLoadingDownloadFile(false)
  }

  function displayTextWidth(text: any, font: any): HTMLCanvasElement {
    // @ts-ignore
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function handleCell(target: any) {
    const parentWidth = target.currentTarget.offsetWidth
    if(parentWidth < displayTextWidth(target.currentTarget.firstChild.outerText, "normal 16px 'Inter', sans-serif ")) {
      setHideTooltipPolicyName(true)
    } else {
      setHideTooltipPolicyName(false)
    }
  }

  return (
    <TableRow { ...otherProps }>
      <TableColumn className={classes.colName}>
        { adopted && <Badge>adopted</Badge> }
        {/*<input type="text" name="name" onChange={handleChange} value={name} />*/}
        <div style={{ color: adopted ? "rgb(183, 194, 205)" : "" }} className={classes.noWrap} onMouseEnter={(e) => handleCell(e)} data-tip data-for={'registerTip-' + item.policy_id + item.policy_name + item.node_id}><p>{ item.policy_name }</p></div>

        { hideTooltipPolicyName && <ReactTooltip id={'registerTip-' + item.policy_id + item.policy_name + item.node_id} place="top" effect="solid">
          { item.policy_name }
        </ReactTooltip> }
      </TableColumn>
      <TableColumn>
        <TopicsDropdown
          name="topics"
          values={topics}
          adopted={adopted}
          onChange={handleChange}
          options={topicsOptions} />
      </TableColumn>
      <TableColumn>
        <DatePicker
          name="reviewDate"
          adopted={adopted}
          value={reviewDate}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn align={'center'}>
        <OwnerDropdown
          className={''}
          name="owner"
          adopted={adopted}
          value={owner}
          onChange={handleChange}
          options={ownersOptions} />
      </TableColumn>
      <TableColumn>
        <Switcher
          name="attestation"
          adopted={adopted}
          value={attestation}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn>
        { isLoadingDownloadFile && <Spinner /> }
        { !isLoadingDownloadFile &&
          <Button className={clsx({ [classes.iconDisabled]: !policyCreated && !item?.template_file })} onClick={(!policyCreated && !item?.template_file) ? undefined : downloadFilePolicy} type={'icon'}>
            <PdfIcon />
          </Button>
        }

      </TableColumn>
      <TableColumn>
        { isLoadingGeneratePolicy && <Spinner /> }
        { !isLoadingGeneratePolicy &&
          <Button
            onClick={handleGeneratePolicy}
            className={classes.btnSquare}
            disabled={!owner || !name}
            type={'icon'}>
            <MagicPenIcon />
          </Button>
        }
      </TableColumn>
    </TableRow>
  )
}

function PolicyTemplatesTable({ data, setTab }: { data: Policy[], setTab: (tab: string) => void }) {

  function rowRenderer(item: Policy, index: number) {
    return (
      <TableItem key={index} item={item} setTab={setTab}  />
    )
  }

  return (
    <Table
      columns={tableColumns}
      data={data}
      rowRenderer={rowRenderer} />
  )
}

export default PolicyTemplatesTable
