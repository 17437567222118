import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
    input: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        width: 0,
        height: 0,
        overflow: "hidden"
      },
      btnSquare: {
        width: 24,
        height: 24,
        background: '#497DAB !important',
        borderRadius: '3px',
      },
      tableHeader: {
        paddingLeft:0,
        "& li:hover": {
          background:"none",
        },
        "& li": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "15px",
          lineHeight: "18px",
          color: "#7B90A3",
          padding: "0 10px",
          "& span": {
            padding: "0 21px",
        },
        "& span:nth-child(1)": {
          width: "290px",
      }
      },
      },
      tableData: {
        paddingLeft:0,
        "& li:hover": {
          background:"none",
        },
        "& li:nth-last-child(1)": {
          paddingBottom: "12px",
        },
        "& li": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "19px",
          color: "#1B2E3F",
          padding: "0 10px",
          paddingTop: "10px",
          "& span": {
            padding: "0 21px",
        },
        "& span:nth-child(1)": {
          width: "290px",
      },
        "& span:nth-child(2)": {
          width: "130px",
      },
      "& svg": {
        position: "relative",
        top: "4px",
        marginRight: "10px",
    },
    "& .redIcon":{
      // "& path": {
      //   fill: "#EB6E4D",
      // },
      overflow: 'hidden !important',
      "& p": {
        display: "inline",
        position: "relative",
        top: "-3.99px",
      }
    },
    "& .blueIcon":{
      // "& path": {
      //   fill: "#4A86E3",
      // },
      overflow: 'hidden !important',
    },
    "& .purpleIcon":{
      // "& path": {
      //   fill: "#9364F1",
      // },
      overflow: 'hidden !important',
    },
    "& .yelloIcon":{
      // "& path": {
      //   fill: "#9364F1",
      // },
      overflow: 'hidden !important',
    },
    "& .greenIcon":{
      // "& path": {
      //   fill: "#9364F1",
      // },
      overflow: 'hidden !important',
    },

      },
      },
      thirdRow:{
        "& svg:nth-child(1)":{
          fill: "#fff",
          padding: "2px",
          background: "#497DAB",
          borderRadius: "3px",
        },
        "& svg:nth-child(2)":{
          fill: "#F38F8F"
        },
        
      }, bold: {
        fontWeight: 'bold',
    },noWrap: {
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    iconDisabled: {
      '& path': {
        fill: '#B7C2CD'
      }
    },
    settingIcon: {
      marginRight: "15px",
    }
}))
