import React, {useState} from "react";
import useStyles from './retired-policies-table.styles'
import Table from "../../../../../components/table";
import {TColumn} from "../../../../../interfaces/table-interface";
import TableRow from "../../../../../components/table/table-row";
import TableColumn from "../../../../../components/table/table-column";
import TopicsDropdown from "../../topics-dropdown";
// import OwnerDropdown from "../../owner-dropdown";
import Switcher from "../../../../../components/switcher";
import {TableRowProps} from "../../../../../components/table/table-row/table-row";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../interfaces/app-state-interface";
import {Option} from "../../../../../interfaces/common-intefaces";
import DatePicker from "../../../../../components/date-picker";
import {Policy} from "../../../../../interfaces/settings-interface";
import {createPolicy, fetchPolicies} from "../../../../../redux/features/settings-store";
import Spinner from "../../../../../components/spinner";
import Button from "../../../../../components/button";
import {ReactComponent as MagicPenIcon} from "../../../../../icons/magic-pen.svg";
// import {ReactComponent as CheckMarkIcon} from "../../../../../icons/checkmark.svg";

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(24% - 24px)',
    field: 'policy',
    title: 'Policy'
  },
  {
    flex: '1 0 calc(20% - 24px)',
    field: 'topics',
    title: 'Topics Covered'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    align: 'center',
    field: 'reviewDate',
    title: 'Review Date'
  },
  {
    flex: '1 0 calc(20% - 24px)',
    align: 'center',
    field: 'owner',
    title: 'Owner'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    align: 'center',
    field: 'attestation',
    title: 'Ann. Empl. Attestation',
    noWrap: false
  },
  {
    flex: '1 0 calc(10% - 24px)',
    align: 'center',
    field: 'activate',
    title: 'Activate',
    noWrap: false
  }
]

interface TableItemProps extends TableRowProps {
  item: Policy
}

function TableItem({ item, ...otherProps }: TableItemProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const topicsOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.topics.map(value => ({ label: value, value }))
  );
  // const ownersOptions = useSelector<AppState, Option[]>(
  //   (state) => state.settings.employees.map(({ name }) => ({
  //     label: name,
  //     value: name
  //   }))
  // );

  const [topics] = useState<string[]>(item.topics)
  const [owner] = useState(item.owner_name)
  const [attestation] = useState(item.reattestation_by_employee === 'yes')
  // const [activate, setActivate] = useState(item.status === 'active')
  const [reviewDate] = useState(item.policy_review_date)
  const [isLoadingActivatePolicy, setIsLoadingActivatePolice] = useState<boolean>(false)

  function handleChange({ target: { name, value } }: { target: { name: string, value: any } }) {
  }

  async function handleChangeActivate() {
    // setActivate(true)
    const data: Policy = {
      policy_name: item.policy_name,
      topics,
      source: item.source,
      policy_id: item.policy_id,
      node_id: item.node_id,
      owner_name: owner,
      reattestation_by_employee: attestation ? 'yes' : 'no',
      policy_review_date: reviewDate,
      status: 'active',
      file: item.file
    }

    setIsLoadingActivatePolice(true)
    // setDisableCreatePolicy(true)

    await dispatch(createPolicy(data))
    await dispatch(fetchPolicies());
    setIsLoadingActivatePolice(false)
  }

  return (
    <TableRow { ...otherProps }>
      <TableColumn className={classes.colName}>
        <p>{ item.policy_name }</p>
      </TableColumn>
      <TableColumn>
        <TopicsDropdown tab={'retired'} name="topics" values={topics} onChange={handleChange} options={topicsOptions} />
      </TableColumn>
      <TableColumn>
        <DatePicker
          className={classes.datePicker}
          name="reviewDate"
          value={reviewDate}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn align={'center'}>
        { owner }
        {/*<OwnerDropdown*/}
        {/*  name="owner"*/}
        {/*  value={owner}*/}
        {/*  onChange={handleChange}*/}
        {/*  options={ownersOptions} />*/}
      </TableColumn>
      <TableColumn>
        <Switcher
          name="attestation"
          value={attestation}
          onChange={handleChange} />
      </TableColumn>
      <TableColumn>
        { isLoadingActivatePolicy && <Spinner /> }
        {!isLoadingActivatePolicy &&
        <Button
          onClick={handleChangeActivate}
          className={classes.btnSquare}
          type={'icon'}>
          <MagicPenIcon/>
        </Button>
        }
      </TableColumn>
    </TableRow>
  )
}

function RetiredPoliciesTable({ data }: { data: Policy[] }) {

  function rowRenderer(item: Policy, index: number) {
    return (
      <TableItem key={index} item={item}  />
    )
  }

  return (
    <Table
      columns={tableColumns}
      data={data}
      rowRenderer={rowRenderer} />
  )
}

export default RetiredPoliciesTable
