import React, {useEffect, useMemo, useRef, useState} from "react";
import useStyles from './taskOwner-dropdown.styles'
import DropdownWrapper from "../../../../components/dropdown-wrapper";
import {Option} from "../../../../interfaces/common-intefaces";
import Button from "../../../../components/button";
import clsx from "clsx";
import RadioButtonGroup from "../../../../components/radio-button-group";
import {searchEmployees} from "../../../../redux/features/settings-store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../interfaces/app-state-interface";
import Spinner from "../../../../components/spinner";
import ReactTooltip from "react-tooltip";
import { ReactComponent as TriangleIcon } from "icons/triangle.svg";

interface TaskOwnerDropdownProps {
  name: string;
  value: string;
  itemId?: string;
  onChange: (e: { target: { name: string, value: string }}) => void;
  options: Option[];
  copy?: boolean;
  error?: boolean;
  adopted?: boolean;
  className: string;
  required:boolean;
}

interface SearchResultEmployeesProps {
  name: string
  node_id: string
  role: string
  workEmail: string
}

function TaskOwnerDropdown({ name, value, onChange, options, itemId, copy = false, error = false, adopted = false,  className, required }: TaskOwnerDropdownProps) {
  const classes = useStyles()
  const taskownerInputRef = useRef<HTMLInputElement>(null)
  const [opened, setOpened] = useState(false)
  const [searchTaskOwner, setSearcTaskhOwner] = useState<string>()
  const [isLoadingResultEmployees, setIsLoadingResultEmployees] = useState<boolean>(false)
  const dispatch = useDispatch();
  const [hideTooltipPolicyName, setHideTooltipPolicyName] = useState(false)

  const employees = useSelector<AppState, SearchResultEmployeesProps[]>(
    (state) => state.settings.resultSearchEmployees
  );
  
  let resultSearchEmployees: Option[] = employees.map((item) => {
    return {label: item.name, value: item.node_id}
  });

  function displayTextWidth(text: any, font: any): HTMLCanvasElement {
    // @ts-ignore
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function handleCell(target: any) {
    const parentWidth = target.currentTarget.offsetWidth
    if(parentWidth < displayTextWidth(target.currentTarget.firstChild.outerText, "normal 16px 'Inter', sans-serif ")) {
      setHideTooltipPolicyName(true)
    } else {
      setHideTooltipPolicyName(false)
    }
  }

  useEffect(() => {
    setIsLoadingResultEmployees(true)
    const delayDebounceFn = setTimeout(() => {
      if (searchTaskOwner) {
        (async () => {
          dispatch(searchEmployees(searchTaskOwner))
        })()
      }
    }, 2000)
    const delayDebounceSpinner = setTimeout(() => {
      setIsLoadingResultEmployees(false)
    }, 3000)
    return () => {
      clearTimeout(delayDebounceFn)
      clearTimeout(delayDebounceSpinner)
    }
  }, [searchTaskOwner, dispatch])

  let displayValue = useMemo(() => {
    return options.find((option) => option.value === value)?.label
  }, [value, options])

  function toggleDropdown() {
    if(!opened && taskownerInputRef.current !== null) {
      taskownerInputRef.current.focus()
    }
    setOpened(prevState => !prevState)
  }

  function handleClose() {
    setOpened(false)
  }

  function handleChange({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) {
    setSearcTaskhOwner('')
    setOpened(false)
    onChange({ target: { name, value }})
    displayValue = options.find((option) => option.value === value)?.label 
  }

  return (
    <DropdownWrapper
      className={classes.root}
      opened={!!searchTaskOwner && opened}
      position={'center'}
      search={true}
      // addTop42
      triggerEl={
        <>
        <Button
          className={clsx(classes.btnTrigger)}
          type={'icon'}>
          <div className={classes.wrapperInput} onClick={adopted ? undefined : toggleDropdown}>
            <input
              ref={taskownerInputRef}
              autoComplete="off"
              type="text"
              name="TaskOwner"
              value={searchTaskOwner}
              onChange={(e: any) => setSearcTaskhOwner(e.target.value)}
              className={clsx(classes.search, { [classes.inputText]: copy, [classes.errorName]: error })}
            />
            {!opened && !searchTaskOwner && <div className={clsx(classes.wrapperText, { [classes.wrapperTextPaddingLeft]: copy })} data-tip
                                                    data-for={'registerTip-' + itemId + (displayValue || 'TaskOwner')} onMouseEnter={(e) => handleCell(e)}>
              <div className={className}>{displayValue || <div style={{color: "#B7C2CD"}}>All Employees</div>}</div>
            </div>}
          </div>
          { hideTooltipPolicyName && <ReactTooltip id={'registerTip-' + itemId + (displayValue || 'TaskOwner')} place="top" effect="solid">
            { displayValue }
          </ReactTooltip> }
          {adopted ? "" : <TriangleIcon onClick={toggleDropdown} />}
        </Button>
          <div style={{color: "#F05E5E", fontSize: '0.75rem', fontWeight: 400, }}>{required === true && displayValue === undefined ? " * please select task owner" : null}</div>
        </>
      }
      onClose={handleClose}>
      { isLoadingResultEmployees &&
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      }
      {
        !isLoadingResultEmployees && resultSearchEmployees.length === 0 && <div className={classes.notResult}>No employees found</div>
      }
      { !isLoadingResultEmployees && resultSearchEmployees &&
        <RadioButtonGroup
          name={name}
          options={resultSearchEmployees}
          value={value}
          onChange={handleChange} />
      }
    </DropdownWrapper>
  )
}

export default TaskOwnerDropdown
