import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  root:{
    padding: "30px 30px 30px 30px",
  },
  textChartsConatiner: {
    height: 'calc(100% + 30px)',
  },
  button:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'static',
    width: '130px',
    height: '38px',  
    borderRadius: '100px',
    background:'#FDFDFD',
    cursor: 'pointer',
   },
   buttonBackground: {
    background: '#61778B',
    color: '#FDFDFD',
   },
   buttonborder : {
     border: '2px solid rgba(183, 194, 205, 0.5)',
     width:'250px',
     display: 'flex',
     flexDirection: 'row',
     borderRadius: '100px',
    //  marginLeft:'1500px',
     padding: '5px 5px',
     margin: "auto 0 auto auto",
     position: "relative",
    top: "-5px",
   },
   pieBox:{
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.7)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "18px 22px",
    margin: "20px 0px",
    height: "148px",
   },
   pieTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    textTransform: "uppercase",
    color: "#99A9B8",
   },
   mainHeading:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
   },
   chipWrapper:{
    marginTop: "20px",
    paddingRight: "20px",
    '& .activeChip':{
      color: "#1078FF !important",
      background: "rgba(16, 120, 255, 0.1) !important",
    }
   },
   chipItem:{
    padding: "4px 10px !important",
    background: "rgba(183, 194, 205, 0.2) !important",
    borderRadius: "4px !important",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px !important",
    lineHeight: "18px !important",
    display: "inline-block !important",
    alignItems: "center !important",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
    color: "#99A9B8 !important",
    marginRight: "5px !important",
    marginBottom: "5px",
    height: "26px",
   },
   controlChip:{
    // padding: "6px 10px 5px",
    padding: "4px 10px 5px !important",
    background: "#E0F8F1 !important",
    borderRadius: "4px !important",
    position: "relative",
    top: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px !important",
    lineHeight: "18px !important",
    display: "inline-block !important",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
    color: "#47C2A4 !important",
    height: "26px",
   },
   compliancePosture:{
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.7)",
    boxSizing: "border-box",
    borderRadius: "8px",
    marginTop: "10px",
    height: "271px",
   },
   rightBox:{
     '& > div':{
        background: "#FFFFFF",
        border: "1px solid rgba(183, 194, 205, 0.7)",
        boxSizing: "border-box",
        borderRadius: "8px",
     }
   },
   mainWrapperPage:{
     padding: "0 10px",
   },
   assetCountCloud:{
    '& > div':{
      background: "#FFFFFF",
      border: "1px solid rgba(183, 194, 205, 0.7)",
      boxSizing: "border-box",
      borderRadius: "8px",
    }
   },
   borderBox:{
    '& > div':{
      background: "#FFFFFF",
      border: "1px solid rgba(183, 194, 205, 0.7)",
      boxSizing: "border-box",
      borderRadius: "8px",
    }
   },
   pieBoxInnerBox:{
     height: "130px",
   },
   dot:{
    width: "6px",
    height: "6px",
    background: "#47C2A4",
    display: "inline-block",
    borderRadius: "50px",
    marginRight: "5px",
    position: "relative",
    top: "-1px",
   },
   complienceTitle:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
   },
   cloudMiscon:{
     marginTop: "20px",
   },
   box:{
     height:"271px"
   },
   assetCount:{
    
    '& > div':{
      height:"271px",
      background: "#FFFFFF",
      border: "1px solid rgba(183, 194, 205, 0.7)",
      boxSizing: "border-box",
      borderRadius: "8px",
    }
   },
   '@media (max-width:960px)': {
    pieBox:{
      height: "auto !important",
    },
    compliancePosture:{
      height: "auto !important",
    },
    textChartsConatiner:{
      paddingTop: "10px !important",
    },
    rightBox:{
      padding: "0px !important",
      paddingTop: "10px !important",
    },
    assetCount:{
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    }
  },
  '@media (max-width:530px)': {
    pieBoxInnerBox:{
      height: "auto !important",
    },
  },
  chartContainer: {
    padding: theme.spacing(10),
    height: "calc(100% + 10px)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5),
    },
  },
}));
