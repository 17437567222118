import React, { useState, useEffect } from "react";
import { Grid, TableCell } from "@material-ui/core";
import { useDispatch } from "react-redux";

import WorldMapChart from "components/charts/world-map-chart";
import SimpleBarChart from "components/charts/simple-bar-chart";
import SectionWrapper from "components/section-wrapper";
import TableWrapper from "components/table-wrapper";
import InventoryTableRow from "./components/inventory-table-row";

import * as InventoryStore from "redux/features/inventory-store";
import useInventorySummary from "hooks/useInventorySummary";
import useInventoryRegions from "hooks/useInventoryRegions";
import { useInventoryTable } from "hooks/useInventoryTable";

const InventoryOld = () => {
  // state
  const [search, setSearch] = useState<string>("");
  const [currentRegion, setCurrentRegion] = useState<string | undefined>();

  // hooks
  const {
    data: barChartData,
    loading: isBarChartLoading,
  } = useInventorySummary(true);
  const mapChartMarkers = useInventoryRegions();
  const {
    data: [inventoryColumns, inventoryRows],
    loading: inventoryTableLoading,
  } = useInventoryTable();

  // redux
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(InventoryStore.onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleDownloadXLSX = () => {
    return dispatch(InventoryStore.downloadInventory("xlsx"));
  };

  const handleDownloadJSON = () => {
    return dispatch(InventoryStore.downloadInventory("json"));
  };

  const handleOnScan = async () => {
    await dispatch(InventoryStore.scanInventory(currentRegion));
  };

  const onMarkerClick = (region: string) => {
    setCurrentRegion(region);

    dispatch(InventoryStore.fetchRegionArns(region));
  };

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} sm={6}>
        <SectionWrapper
          title="Inventory By Geo. Regions"
          helpIndex="inventory/map"
        >
          <WorldMapChart
            markers={mapChartMarkers}
            onMarkerClick={onMarkerClick}
          />
        </SectionWrapper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <SectionWrapper
          title="Inventory By Service"
          helpIndex="inventory/assetsTable"
        >
          <SimpleBarChart
            aspect={1.8}
            isLoading={isBarChartLoading}
            data={barChartData}
          />
        </SectionWrapper>
      </Grid>

      <Grid item xs={12} sm={12}>
        <SectionWrapper
          title="Asset Inventory"
          helpIndex="dashboard/inventory"
          onScan={handleOnScan}
          onDownloadJSON={handleDownloadJSON}
          onDownloadXLS={handleDownloadXLSX}
          onSearch={setSearch}
        >
          <TableWrapper
            search={search}
            columns={inventoryColumns}
            emptyColumn={<TableCell size="small" />}
            rows={inventoryRows}
            renderRow={(row, k) => (
              <InventoryTableRow key={`row-${k}`} row={row} />
            )}
            isLoading={inventoryTableLoading}
          />
        </SectionWrapper>
      </Grid>
    </Grid>
  );
};

export default InventoryOld;
