import React, { useState, useEffect, createElement } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {ReactComponent as LogoutIcon} from "icons/logoutIcon.svg";
import {ReactComponent as ArrowleftIcon} from "icons/arrowleft.svg";
import { setIsTokenValid } from "redux/features/auth-store";
import { Route } from "interfaces/route-interface";
import paths from "routes/paths";
import CloudRemedyLogo from "__mocks__/logos/CloudRemedyLogo.png";

import useStyles from "../layout-wrapper.styles";
import { AppState } from "interfaces/app-state-interface";

interface IProps {
  open: boolean;
  handleDrawerToggle?: () => void;
}

export const SIDEBAR_ROUTES: Route[] = [
  paths.dashboard,
  // paths.inventoryOld,
  paths.inventory,
  paths.violations,
  paths.compliance,
  paths.activeRules,
  paths.profileSettings,
];

const NavigationDrawer = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [active, setActive] = useState<Route>(SIDEBAR_ROUTES[0]);
  const dispatch = useDispatch();

  const isTokenValid = useSelector<AppState, boolean>(
    (state) => state.auth.isTokenValid
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const { pathname } = history.location;
    const currentRoute = SIDEBAR_ROUTES.find(
      (route) => route.path === pathname
    );

    if (currentRoute) {
      setActive(currentRoute);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!isTokenValid) {
    return null;
  }

  const renderIcon = (icon?: any) => createElement(icon);

  const onClick = (route: Route) => {
    setActive(route);
    history.push(route.path);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setIsTokenValid(false));
    // set timeout to address issue in chrome
    setTimeout(function () {
      window.location.href = "/logout";
    }, 500);
  };

  return (
    <Drawer
      open={props.open}
      variant="permanent"
      anchor="left"
      onClose={props.handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      classes={{
        paper: clsx({
          [classes.drawerPaper]: true,
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
    >
      <List key={"navigation-list"}>
        <ListItem>
          <ListItemIcon className={classes.logoListItem} key={"sidebar-logo-icon"}>
            <img
              src={CloudRemedyLogo}
              width={41}
              className={classes.logo}
              alt="logo"
            />
          </ListItemIcon>
          <ListItemText className={classes.logotext} primary="CloudRemedy" />
        </ListItem>

        {SIDEBAR_ROUTES.map((route, index) => (
          <>
          <ListItem
            button
            className={clsx({
              [classes.drawerListItem]: true,
              [classes.drawerListItemActive]: active.path === route.path,
            })}
            key={`sidebar-route-${index}`}
            data-testid="sidebar-route"
            onClick={() => onClick(route)}
          > { active.path === route.path ? 
            <span className={classes.covermenu}></span>  :null}
            {route.icon && (
              <ListItemIcon className={classes.icon} key={`sidebar-icon-${index}`}>
                {renderIcon(route.icon)}
              </ListItemIcon>
            )}
            <ListItemText primary={route.name} />
            { active.path === route.path ? 
            <span className={classes.covermenu2}></span>:null}
          </ListItem>
          </>
        ))}
      </List>

      <List className={classes.bottomListContainer} key={"sidebar-bottom"}>
        <ListItem
        key={"sidebar-logout"}
          button
          className={classes.drawerListItem}
          onClick={handleLogout}
        >
          <ListItemIcon className={classes.icon} key={"logout-button"}>
            <LogoutIcon key={"logout-icon"}/>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>

        <ListItem
        key={"sidebar-collapse"}
          button
          className={classes.drawerListItem}
          onClick={props.handleDrawerToggle}
        >
          <ListItemIcon className={classes.icon} key={"collapse-icon"}>
            {props.open ? <ArrowleftIcon key={"arrowleft"} /> : <ArrowForwardIcon  key={"arrowforward"}/>}
          </ListItemIcon >
          <ListItemText primary="Collapse" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default NavigationDrawer;
