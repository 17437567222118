import React, { useState, useRef } from "react";
import useStyles from "./TagInputControl.style";
import { Grid, Button } from "@material-ui/core";
import { ReactComponent as CheckMarkIcon } from "icons/checkmark.svg";
import { ReactComponent as CrossMarkIcon } from "icons/cross.svg";
import ChipInput from 'material-ui-chip-input'
interface TagInputControlprops {
  name: string;
  values?: string[];
  itemId?: string;
  onChange: (e: { target: { name: string; value: string[] } }) => void;
}

function TagInputControl({
  name,
  values = [],
  onChange,
  itemId,
}: TagInputControlprops) {
  const classes = useStyles();
  const [tag, setTag] = useState<string>("");
  const ownerInputRef = useRef<HTMLInputElement>(null);

  function handleCross(index: any) {
    let updatedValues = [...values];
    updatedValues = values.filter((value) => value !== values[index]);
    onChange({ target: { name, value: updatedValues } });
  }

  const handleAddChip = (chip: any) => {
    if (chip === "" || values.includes(tag.toString())) return;
    let updatedValues = [...values, chip];
    onChange({ target: { name, value: updatedValues } });
  }

  function handleClick(){
    if (tag === "" || values.includes(tag.toString())) return;
    let updatedValues = [...values, tag];
    onChange({ target: { name, value: updatedValues } });
    setTag("");
  }

  return (
    <>
      <div className={classes.btnTrigger}>
        <div
          className={classes.wrapperText}
          data-tip
          data-for={
            "registerTip-" +
            itemId +
            (values?.length === 0 ? "Topic" : values[values?.length - 1])
          }
        >
          <div>
            <ChipInput
              ref={ownerInputRef}
              fullWidthInput
              fullWidth
              placeholder={values?.length > 0 ? " " : 'Tags'}
              alwaysShowPlaceholder
              disableUnderline
              chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) : any => {
                return (<React.Fragment key={key}>
                <span>
                  {value} <span onClick={handleDelete}>X</span>
                </span>
              </React.Fragment>)
              }}
              value={values}
              onAdd={(chip) => handleAddChip(chip)}
              onDelete={(chip, index) => handleCross(index)}
            />
            <div className={classes.wrapperBelowButon}>
              <Grid className={classes.belowButon}>
                <Button className={classes.checkButton} onClick={handleClick}>
                  <CheckMarkIcon />
                </Button>
                <Button className={classes.crossButton}>
                  <CrossMarkIcon  onClick={() => setTag("")}/>
                </Button>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TagInputControl;
