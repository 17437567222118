import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import lodash from "lodash";

import * as SettingsService from "services/settings-service";
import * as SettingsIntegrations from "services/settings-integrations";
import * as SettingsVendors from "services/settings-vendor-service";

import * as SettingsControlTests from "services/setings-control-tests-service";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  AccountResponse,
  ConnectionAction,
  IntegrationName,
  IntegrationData,
  IntegrationList,
  IntegrationType, Policy, PolicyResponse, IntegrationServices, ServiceSummary, ControlTests, ControlTestsList,ControlIcon,Vendorslist
} from "interfaces/settings-interface";
import { DownloadFormat } from "interfaces/download-interface";
import * as SettingsComplianceService from "../../services/settings-compliance-service";
import { setLoading } from "./ui-store";

export interface SettingsStore {
  accounts: AccountResponse[];
  activeIntegration: IntegrationName;
  integrations: IntegrationList[];
  singleControlTest: any;
  controlTests: ControlTests;
  control_tests_config: ControlTestsList[];
  control_filter_data: ControlTestsList[];
  searchCompilanceData: any[];
  integrationsOld: IntegrationData;
  activePolicies: Policy[];
  policies: Policy[];
  templates: any[];
  topics: string[];
  employees: any[];
  resultSearchEmployees: any[];
  integrationService: IntegrationServices;
  serviceSummary: ServiceSummary;
  putIntegrationService: IntegrationServices;
  putIntegrationServiceToggle: IntegrationServices;
  deleteIntegrationService: IntegrationServices;
  vendors: Vendorslist[];
  singleVendor: any;
  deleteVendorFile: any;
  uploadVendorFile: any;
  deleteVendorForm: any;
  updateVendorForm:Vendorslist[];
  helpIndex?: string;
  controlTestIcon: ControlIcon[];
  updateControltest:ControlTestsList[];
}

const initialState: SettingsStore = {
  accounts: [],
  activeIntegration: "slack",
  integrations: [],
  integrationsOld: {
    slack: { "Service Url": "", active: false, disabled: true },
    "pager-duty": {
      "Api Key": "",
      active: false,
      disabled: true,
    },
    "service-now": {
      "Service Url": "",
      "User Id": "",
      Password: "",
      active: false,
      disabled: true,
    },
    jira: {
      "Service Url": "",
      "User Id": "",
      "Api Key": "",
      active: false,
      disabled: true,
    },
  },
  controlTests: {},
  singleControlTest: undefined,
  control_tests_config: [],
  control_filter_data: [],
  searchCompilanceData: [],
  activePolicies: [],
  policies: [],
  templates: [],
  topics: [],
  employees: [],
  resultSearchEmployees: [],
  integrationService: {},
  serviceSummary: {},
  putIntegrationService: {},
  putIntegrationServiceToggle: {},
  deleteIntegrationService: {},
  vendors: [],
  singleVendor: undefined,
  deleteVendorFile: {},
  uploadVendorFile: {},
  deleteVendorForm: {},
  updateVendorForm: [],
  helpIndex: "",
  controlTestIcon: [],
  updateControltest : [],
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccounts: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse[]>
    ) => {
      store.accounts = action.payload;
    },
    addAccount: (store: SettingsStore) => {
      store.accounts.push({
        name: "",
        account: "",
        role: "",
        externalid: "",
        regions: [],
        notes: "",
        active: false,
      });
    },
    updateAccount: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex] = { ...action.payload };
      }
    },
    setConnection: (
      store: SettingsStore,
      action: PayloadAction<ConnectionAction>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex].connection = action.payload.status;
      }
    },
    deleteStoreAccount: (
      store: SettingsStore,
      action: PayloadAction<string>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload
      );

      if (currentAccountIndex > -1) {
        store.accounts.splice(currentAccountIndex, 1);
      }
    },
    setActiveIntegration: (
      store: SettingsStore,
      action: PayloadAction<IntegrationName>
    ) => {
      store.activeIntegration = action.payload;
    },
    setIntegrationService: (
      store: SettingsStore,
      action: PayloadAction<IntegrationServices>
    ) => {
      store.integrationService = action.payload;
    },
    setDeleteIntegrationService: (
      store: SettingsStore,
      action: PayloadAction<IntegrationServices>
    ) => {
      store.deleteIntegrationService = action.payload;
    },
    setPutIntegrationService: (
      store: SettingsStore,
      action: PayloadAction<IntegrationServices>
    ) => {
      store.putIntegrationService = action.payload;
    },
    setPutIntegrationServiceToggle: (
      store: SettingsStore,
      action: PayloadAction<IntegrationServices>
    ) => {
      store.putIntegrationServiceToggle = action.payload;
    },
    setServiceSummary: (
      store: SettingsStore,
      action: PayloadAction<ServiceSummary>
    ) => {
      store.serviceSummary = action.payload;
    },
    setIntegrationDataOld: (
      store: SettingsStore,
      action: PayloadAction<IntegrationType>
    ) => {
      const { activeIntegration } = store;
      const integrationData = action.payload;

      if (!lodash.isEmpty(integrationData)) {
        store.integrationsOld[activeIntegration] = {
          ...store.integrationsOld[activeIntegration],
          ...integrationData,
        };

        store.integrationsOld[activeIntegration].connected = true;
      }
    },
    setIntegrationData: (
      store: SettingsStore,
      action: PayloadAction<IntegrationList[]>
    ) => {
      store.integrations = action.payload;
    },
    setsingleControlTestData: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.singleControlTest = action.payload;
    },
    setControlTestsData: (
      store: SettingsStore,
      action: PayloadAction<ControlTests>
    ) => {
      store.controlTests = action.payload;
    },
    setControlTestsConfigData: (
      store: SettingsStore,
      action: PayloadAction<ControlTestsList[]>
    ) => {
      store.control_tests_config = action.payload
    },
    setControlFilterData: (
      store: SettingsStore,
      action: PayloadAction<ControlTestsList[]>
    ) => {
      store.control_filter_data = action.payload;
    },
    setSearchCompilanceData: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.searchCompilanceData = action.payload
    },
    setActivePoliciesdata: (
      store: SettingsStore,
      action: PayloadAction<Policy[]>
    ) => {
      store.activePolicies = action.payload
    },
    setPolicies: (
      store: SettingsStore,
      action: PayloadAction<Policy[]>
    ) => {
      store.policies = action.payload
    },
    addPolicy: (
      store: SettingsStore,
      action: PayloadAction<Policy>
    ) => {
      store.policies.push(action.payload)
    },
    deletePolicy: (
      store: SettingsStore,
      action: PayloadAction<{ policy_id: string | undefined }>
    ) => {
      store.policies = store.policies.filter(item => item.policy_id !== action.payload.policy_id)
    },
    setTemplates: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.templates = action.payload
    },
    setResultSearchEmployees: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.resultSearchEmployees = action.payload
    },
    resetSearchEmployees: (
      store: SettingsStore,
    ) => {
      store.resultSearchEmployees = []
    },
    setTopics: (
      store: SettingsStore,
      action: PayloadAction<string[]>
    ) => {
      store.topics = action.payload
    },
    setEmployees: (
      store: SettingsStore,
      action: PayloadAction<any[]>
    ) => {
      store.employees = action.payload
    },
    setVendorsData: (
      store: SettingsStore,
      action: PayloadAction<Vendorslist[]>
    ) => {
      store.vendors = action.payload;
    },
    setSingleVendorsData: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.singleVendor = action.payload;
    },
    setdeleteVendorfile: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.deleteVendorFile = action.payload;
    },
    setUploadVendorfile: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.uploadVendorFile = action.payload;
    },
    setdeleteVendorform: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.deleteVendorForm = action.payload;
    },

    setUpdateVendorform: (
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.updateVendorForm = action.payload;
  },
    setHelpIndex: (
      store: SettingsStore,
      action: any,
    ) => {
      store.helpIndex = action.payload
    },

    setControlIconData : (
      store: SettingsStore,
      action: PayloadAction<ControlIcon[]>
    ) => {
      store.controlTestIcon = action.payload
    },

    putControlTests :(
      store: SettingsStore,
      action: PayloadAction<any>
    ) => {
      store.updateControltest = action.payload
    },
}});

export const fetchAccounts = (): AppThunk => async (dispatch, store) => {
  await SettingsService.listAccounts()
    .then(({ data }) => dispatch(setAccounts(data.accounts)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveSettings = (account: AccountResponse): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.saveSettings(account)
    .then(() => {
      dispatch(
        addSnackbar({
          text: `Account ${account.account} details saved.`,
          severity: "success",
        })
      );
      dispatch(updateAccount(account));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      let msg = ""
      if (error.response?.status === 405) {
        msg = error.response?.data.error
      }
      if (msg === undefined) {
        msg = "Can't fetch data"
      }
      dispatch(addSnackbar({
        text: msg,
        severity: "error"
      }));
    });
};

export const testConnection = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.testConnection(accountId)
    .then(({ data }) =>
      dispatch(setConnection({ account: accountId, status: data.status }))
    )
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const scanAccounts = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.scanAccounts(accountId)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const deleteAccount = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.deleteAccount(accountId)
    .then(() => {
      dispatch(deleteStoreAccount(accountId));
      dispatch(
        addSnackbar({
          text: `Account ${accountId} deleted.`,
          severity: "error",
        })
      );
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const downloadAccount = (
  accountId: string,
  format: DownloadFormat
): AppThunk => async (dispatch, store) => {
  await SettingsService.downloadAccount(accountId, format)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchActiveIntegration = (
  integration: IntegrationName
): AppThunk => async (dispatch, store) => {
  await SettingsService.listIntegration(integration)
    .then(({ data }) => dispatch(setIntegrationDataOld(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchIntegrationService = (
  serviceType: string,
  service: string,
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.integrationService(serviceType, service)
    .then(({ data }) => dispatch(setIntegrationService(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const deleteIntegrationService = (
  serviceType: string,
  service: string,
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.deleteIntegrationService(serviceType, service)
    .then(({ data }) => dispatch(setDeleteIntegrationService(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const putIntegrationService = (
  serviceType: string,
  service: string,
  body: any
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.putIntegrationService(serviceType, service, body)
    .then(({ data }) => {
      dispatch(setPutIntegrationService(data));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const putIntegrationServiceToggle = (
  serviceType: string,
  service: string,
  data: string
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.putIntegrationServiceToggle(serviceType, service, data)
    .then(({ data }) => {
      dispatch(setPutIntegrationServiceToggle(data));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchServiceSummary = (
  serviceType: string,
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.serviceSummary(serviceType)
    .then(({ data }) => dispatch(setServiceSummary(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchIntegrations = (
): AppThunk => async (dispatch, store) => {
  await SettingsIntegrations.listIntegration()
    .then(({ data: { integrations } }) => dispatch(setIntegrationData(integrations)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveIntegration = (
  integration: IntegrationName,
  integrationData: IntegrationType
): AppThunk => async (dispatch, store) => {
  await SettingsService.postIntegration(integration, integrationData)
    .then(() => {
      dispatch(addSnackbar({ text: "Details saved", severity: "success" }));
      // dispatch(setIntegrationData(integrationData));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchControlTests = (
  ): AppThunk => async (dispatch, store) => {
    await SettingsControlTests.listControlTests()
      .then(({ data }) => dispatch(setControlTestsData(data)))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }
  
        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  };

export const searchCompilance = (query: string): AppThunk => async (dispatch, store) => {
  await SettingsControlTests.getResultsearchCompilance(query)
    .then(({ data }) => {
      dispatch(setSearchCompilanceData(data.controls));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Compilance not found" }));
    });
};
  
export const updateControlTests = (
  controlId:any,
  payload: any,
): AppThunk => async (dispatch, store) => {
  await SettingsControlTests.putControlTests(controlId,payload)
    .then(() => {
      dispatch(addSnackbar({ text: "Details saved", severity: "success" }));
      dispatch(fetchControlTestsConfig());
    })
    .catch((error: any) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const AddControlTest = (
  payload:any,
): AppThunk => async (dispatch, store) => {
  await SettingsControlTests.addControlTest(payload)
  .then(() => {
    dispatch(addSnackbar({ text: "ControlTest created" }));
  })
  .catch((error:any) => {
    if (error.response?.status === 401) {
      dispatch(setIsTokenValid(false));
    }

    dispatch(addSnackbar({ text: "Error while control creating" }));
  }); 
};

export const fetchControlTestsConfig = ( query?: string
): AppThunk => async (dispatch, store) => {
  await SettingsControlTests.listControlTestsConfig(query)
    .then(({ data }) => dispatch(setControlTestsConfigData(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchActivePolicies = (
): AppThunk => async (dispatch, store) => {
  await SettingsControlTests.getActivePolicies()
  .then(({ data }) => {
    const transformedPolicies = data.policies.map(({ policy, topics, template }: PolicyResponse) => ({
      ...policy,
      topics,
      template
    }))
    dispatch(setActivePoliciesdata(transformedPolicies))
  })
  .catch((error) => {
    if (error.response?.status === 401) {
      dispatch(setIsTokenValid(false));
    }

    dispatch(addSnackbar({ text: "Can't fetch data" }));
  });
dispatch(setLoading(false))
};


export const fetchPolicies = (): AppThunk => async (dispatch, store) => {
  dispatch(setLoading(true))
  await SettingsComplianceService.listPolicies()
    .then(({ data }) => {
      const transformedPolicies = data.policies.map(({ policy, topics, template }: PolicyResponse) => ({
        ...policy,
        topics,
        template
      }))
      dispatch(setPolicies(transformedPolicies))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
  dispatch(setLoading(false))
};

export const createPolicy = ({ topics, file,  ...policy }: Policy): AppThunk => async (dispatch, store) => {
  const builtPolicy = {
    policy,
    topics
  }

  const formData = new FormData()

  if (file) {
    formData.append('policy_file', file)
  }

  formData.append('payload', JSON.stringify(builtPolicy))
  await SettingsComplianceService.updatePolicy(formData)
    .then(() => {
      dispatch(addSnackbar({ text: "Policy successfully created" }));
      dispatch(fetchPolicies())
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      if (error.response?.status === 405) {
        dispatch(addSnackbar({ text: error.response?.data.status }));
        return;
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const updatePolicy = ({ topics, file, copy, ...policy }: Policy): AppThunk => async (dispatch, store) => {
  const builtPolicy = {
    policy,
    topics
  }

  const formData = new FormData()

  if (file) {
    formData.append('policy_file', file)
  }
  formData.append('payload', JSON.stringify(builtPolicy))

  await SettingsComplianceService.updatePolicy(formData)
    .then(() => {
      dispatch(addSnackbar({ text: "Policy updated" }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Error while policy updating" }));
    });
};

export const fetchTemplates = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listTemplates()
    .then(({ data }) => {
      dispatch(setTemplates(data.templates))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const searchEmployees = (name: string): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.getResultSearchEmployees(name)
    .then(({ data }) => {
      dispatch(setResultSearchEmployees(data.employees));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Employees not found" }));
    });
};

export const resetResultSearchEmployees = (): AppThunk => async (dispatch, store) => {
  dispatch(resetSearchEmployees());
};

export const fetchTopics = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listTopics()
    .then(({ data }) => {
      dispatch(setTopics(data.topics))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchEmployees = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.listEmployees()
    .then(({ data }) => {
      dispatch(setEmployees(data.employees))
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const downloadPolicy = (policy_name: string, file_name: string | undefined): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadPolicyFile(policy_name)
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name || 'policy.txt');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadPolicies = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadPolicies()
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'policies.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadTemplates = (): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadTemplates()
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'templates.zip');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const downloadTemplateFile = (policy_name: string): AppThunk => async (dispatch, store) => {
  await SettingsComplianceService.downloadTemplateFile(policy_name)
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", 'template_file');
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
    });
};

export const fetchVendorTests = (
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.listVendors()
    .then(({ data: { vendors } }) => dispatch(setVendorsData(vendors)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const SingleVendordata = (
  vendorId: string
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.listsingleVendor(vendorId)
    .then(({ data }) => dispatch(setSingleVendorsData(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const DownloadVendorsfile = (
  vendorId: string,
  evidenceId: string): AppThunk => async (dispatch, store) => {
    await SettingsVendors.DownloadVendorFile(vendorId, evidenceId)
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'template_file');
        document.body.appendChild(link);
        link.click();
      })
      dispatch(addSnackbar({ text: "Can't fetch data" }));
    };

export const fetchControlIcon = (
  query:string
  ): AppThunk => async (dispatch, store) => {
    await SettingsControlTests.getControlIcon(query)
      .then(({ data }) => dispatch(setControlIconData(data)))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }
        dispatch(addSnackbar({ text: "Error downloading file. Contact support" }));
      });
  };

export const DeletedVendorsfile = (
  vendorId: string,
  evidenceId: string
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.DeleteVendorFile(vendorId, evidenceId)
    .then(({ data }) => dispatch(setdeleteVendorfile(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
        dispatch(
          addSnackbar({
            text: `vendor ${vendorId} deleted.`,
            severity: "error",
          })
        );
      }
      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const UploadVendorfile = (
  { vendor_id, file }: Vendorslist
): AppThunk => async (dispatch, store) => {

  const formData = new FormData()

  if (file) {
    formData.append('evidence_file', file)
  }
  await SettingsVendors.UploadVendorFile(vendor_id, formData)
    .then(() => {
      dispatch(addSnackbar({
        text: `vendor ${vendor_id} uploaded.`,
        severity: "success",
      }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const DeletedVendorsform = (
  vendorId: string,
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.DeleteVendorForm(vendorId)
    .then(({ data }) => {
      dispatch(setdeleteVendorform(data));
      dispatch(addSnackbar({ text: `Vendor ${vendorId} Delete Successfull`, severity: "success", }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const UpdateVendorsform = (
  vendorId: string,
  payload: any,
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.UpdateVendorForm(vendorId, payload)
    .then(() => {
      dispatch(addSnackbar({ text: "Vendor updated" }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Error while vendor updating" }));
    });
};

export const SwitchVendorsform = (
  vendorId: string,
  data: string
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.SwitchVendorForm(vendorId, data)
    .then(() => {
      dispatch(addSnackbar({ text: `Vendor ${data} successfull` }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Error while vendor enable" }));
    });
};
export const AddVendorsdata = (
  payload: any,
): AppThunk => async (dispatch, store) => {
  await SettingsVendors.AddVendors(payload)
    .then(() => {
      dispatch(addSnackbar({ text: "Vendor created" }));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Missing fields", severity: "error", }));
    });
};

  
export const { reducer } = slice;
export const {
  setAccounts,
  addAccount,
  updateAccount,
  setConnection,
  deleteStoreAccount,
  setActiveIntegration,
  setIntegrationData,
  setIntegrationService,
  setDeleteIntegrationService,
  setPutIntegrationService,
  setPutIntegrationServiceToggle,
  setServiceSummary,
  setIntegrationDataOld,
  setsingleControlTestData,
  setControlTestsData,
  setControlTestsConfigData,
  setControlFilterData,
  setSearchCompilanceData,
  setActivePoliciesdata,
  setPolicies,
  setTemplates,
  setTopics,
  setEmployees,
  addPolicy,
  deletePolicy,
  setResultSearchEmployees,
  resetSearchEmployees,
  setVendorsData,
  setSingleVendorsData,
  setdeleteVendorfile,
  setdeleteVendorform,
  setUpdateVendorform,
  setHelpIndex,
  setControlIconData,
  putControlTests,
} = slice.actions;
