import React, { } from "react";
import { TableCell } from "@material-ui/core";

import TableWrapper from "components/table-wrapper";
import InventoryTableRow from "../components/inventory-table-row";

import { useInventoryTable } from "hooks/useInventoryTable";

 const  InventoryTestCloud = ({SearchValue}: {SearchValue: string}) => {
  const {
    data: [inventoryColumns, inventoryRows],
    loading: inventoryTableLoading,
  } = useInventoryTable();

    return (
      <TableWrapper
        search={SearchValue}
        columns={inventoryColumns}
        emptyColumn={<TableCell size="small" />}
        rows={inventoryRows}
        renderRow={(row, k) => (
          <InventoryTableRow key={`row-${k}`} row={row} />
        )}
        isLoading={inventoryTableLoading}
      />
    )
}
export default InventoryTestCloud;