import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    // border: `${theme.spacing(1)}px solid ${theme.palette.tableBackground.main}`,
    cursor: 'pointer',
    padding: theme.spacing(5),
    boxShadow: '0px 2px 4px rgb(230 230 230 / 60%)',
    borderRadius: '8px',
    border : '1px solid #E2E9EF',
    display: "block",
    height: "150px",
  },
  activeCard: {
    border: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`
  },
  gridItemBoxTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    marginBottom: "12px",
    color: "#1B2E3F",
  },
  gridItemBoxContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "27px",
    marginBottom: "12px",
    color: "#1B2E3F",
  },
  imgSection: {
    textAlign: "center",
    width: "100%",
    marginTop: "12px",
  },
  imgTxtSection: {
    textAlign: "center",
    width: "100%",
  },
}));
