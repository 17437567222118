import { AxiosPromise } from "axios";
import { axiosIntegrationsInstance } from "./http-service";
import { Vendors,Vendorslist } from '../interfaces/settings-interface';



export const listVendors = (): AxiosPromise<Vendors> => {
  return axiosIntegrationsInstance.get(`/settings-compliance/v1/vendors`);
};


export const listsingleVendor = (vendorId: string): AxiosPromise<Vendorslist> => {
  return axiosIntegrationsInstance.get(`/settings-compliance/v1/vendors/${vendorId}`);
};

export const DownloadVendorFile = (vendorId: string, evidenceId: string):AxiosPromise<any> => {
  return axiosIntegrationsInstance.get(`/settings-compliance/v1/vendors/${vendorId}/evidences/${evidenceId}`);
}
export const DeleteVendorFile = (vendorId: string , evidenceId: string): AxiosPromise<any> => {
  return axiosIntegrationsInstance.delete(`/settings-compliance/v1/vendors/${vendorId}/evidences/${evidenceId}`);
};

export const UploadVendorFile = (vendorId: string, file: FormData): AxiosPromise<any> => {
  return axiosIntegrationsInstance.put(`/settings-compliance/v1/vendors/${vendorId}/evidences`,
  file);
};

export const DeleteVendorForm = (vendorId:string): AxiosPromise<any> => {
  return axiosIntegrationsInstance.delete(`/settings-compliance/v1/vendors/${vendorId}`);
};
export const UpdateVendorForm = (vendorId:string,payload:any): AxiosPromise<any> => {
  return axiosIntegrationsInstance.put(`/settings-compliance/v1/vendors/${vendorId}`,
  payload);
}; 
export const SwitchVendorForm = (vendorId:string,data:string): AxiosPromise<any> => {
  return axiosIntegrationsInstance.put(`/settings-compliance/v1/vendors/${vendorId}/${data}`);
}; 
export const AddVendors = (payload:any):AxiosPromise<any> => {
  return axiosIntegrationsInstance.post(`/settings-compliance/v1/vendors`,payload);
};