import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SectionWrapper from "../../components/section-wrapper";
import TabsWrapper from "../../components/tabs-wrapper";
import SearchInput from 'components/search-input';
import Button from 'components/button/button';
import DropdownWrapper from 'components/dropdown-wrapper';

import { ReactComponent as DownloadIcon } from 'icons/download.svg' 
import { ReactComponent as ExcleIcon } from 'icons/excle.svg' 
import { ReactComponent as DropdownArrw } from 'icons/dropDownArrow.svg';
import { ReactComponent as DownloadJasonIcon } from 'icons/json.svg';
import {
  Box,
  MenuItem,
} from "@material-ui/core";

import useStyles from './inventoryTest.styles';
import InventoryTestEmployees from "./inventoryTest-employees";
import InventoryTestWorkstations from "./inventoryTest-workstations";
import InventoryTestCloud from "./inventoryTest-cloud";

import * as InventoryStore from "redux/features/inventory-store";
import { 
  fetchEmployees, 
  fetchWorkstations,
  fetchCloudsCount,
} from "redux/features/inventory-store";
import { AppState } from "interfaces/app-state-interface";
import {
  InventoryEmployees, 
  InventoryWorkstations, 
} from 'interfaces/inventory-interface';

const Inventory = () => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("employees");
  const [SearchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  
  const isLoading = useSelector<AppState, boolean>(
    (state) => state.ui.isLoading
  );

  const onPageInit = async () => {
    dispatch(fetchCloudsCount());
    dispatch(fetchEmployees());
    dispatch(fetchWorkstations());
    dispatch(InventoryStore.onPageInit());
  };

 /* eslint-disable react-hooks/exhaustive-deps */ 
  useEffect(() => {
      onPageInit();
  }, [])
 /* eslint-disable react-hooks/exhaustive-deps */ 

  const cloudCount = useSelector<AppState, any>(
    (state) => state.inventory.cloudCount
  );
  const employeeList = useSelector<AppState, InventoryEmployees[]>(
    (state) => state.inventory.inventoryEmployees
  );
  const workstationList = useSelector<AppState, InventoryWorkstations[]>(
    (state) => state.inventory.inventoryWorkstations
  );

  const tabs = [
    {
      label: 'Employees',
      value: 'employees',
      count: employeeList.length
    },
    {
      label: 'Workstations',
      value: 'workstations',
      count: workstationList.length
    },
    {
      label: 'Cloud',
      value: 'cloud',
      count: cloudCount.count
    },
  ]
  
  function onSearch(value: any){
    setSearchValue(value);
  }
  function toggleDropdown() {
    setOpen(prevState => !prevState)
  }
  const handleDownloadXLSX = () => {
    return dispatch(InventoryStore.downloadInventory("xlsx"));
  };

  const handleDownloadJSON = () => {
    return dispatch(InventoryStore.downloadInventory("json"));
  };

  const DefaultCard = (props: any) => {
    return (
      <span>
        <span>{props.nameBefore}</span>
        <span className={classes.bold}>{props.bold}</span>
        <span>{props.nameAfter}</span>
      </span>
    );
  }

  const textValue = (value?: string) => {
    let isHighlighted = false;
    SearchValue.trim().replace(/ +(?= )/g, '');
    const index = (value ? value.toLowerCase().indexOf(SearchValue.toLowerCase()) : 0);
    let nameBefore = value || '';
    let queryData = '';
    let nameAfter = '';
    if (index !== -1) {
      nameBefore = (value ? value.slice(0, index) : '');
      queryData = (value ? value.slice(index, index + SearchValue.length) : '');
      nameAfter = (value ? value.slice(index + SearchValue.length) : '');
    }
    if (isHighlighted) {
      return <DefaultCard isHighlighted={true}  nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter}  />;
    }
    else{
      return (
        <DefaultCard nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter} />
      );
    }
  }

  return (
    <SectionWrapper policies={true} title={''} type='formType' helpIndex={'dashboard/inventory'}>
      <h1 className={classes.inventoryTtle}>Inventory</h1>
      <div className={[classes.header,classes.headerPaddingRight].join(" ")}>
        <TabsWrapper value={currentTab} tabs={tabs} className={[classes.subTabs,classes.inventoryTabs].join(" ")} onChange={setCurrentTab} />
        <div className={classes.buttons}>
          <div className={classes.searchInput}>
            <SearchInput onChangeText={(e: any) => onSearch(e)}/>
          </div>
          {/* {currentTab === 'workstations' &&
            <Button className={[classes.btnAdd,classes.scanNowBtn].join(" ")} type={'info'}>
              Scan Now
            </Button>
          } */}
          <div className={classes.btnDownloadWrapper}>
          <DropdownWrapper
            opened={isOpen}
            onClose={() => setOpen(false)}
            // position={'right'}
            triggerEl={
              <Button className={[classes.btnDownload,classes.downloadBtn].join(" ")} type={'infoBlue'} onClick={toggleDropdown}>
                <DownloadIcon />Download<span><DropdownArrw /></span>
              </Button>
            }>
            <MenuItem onClick={handleDownloadXLSX}>
              <ExcleIcon fontSize="small" />
              <Box ml={3}>Download.xls</Box>
            </MenuItem>
            <MenuItem onClick={handleDownloadJSON}>
              <DownloadJasonIcon fontSize="small" />
              <Box ml={3}>Download.json</Box>
            </MenuItem>
            </DropdownWrapper>
          </div>
        </div>
      </div>
      {
        currentTab === 'employees' &&
        <InventoryTestEmployees  data={employeeList} SearchValue={SearchValue} isLoading={isLoading} textValue={textValue}/>
      }
      {
        currentTab === 'workstations' &&
        <InventoryTestWorkstations data={workstationList} SearchValue={SearchValue} isLoading={isLoading} textValue={textValue}/>
      }
      {
        currentTab === 'cloud' &&
        <InventoryTestCloud SearchValue={SearchValue} />
      }
    </SectionWrapper>
    
  );
};

export default Inventory;
