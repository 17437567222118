import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { LineChart, Line, Tooltip, ResponsiveContainer, TooltipProps, Legend, CartesianGrid, XAxis, YAxis } from 'recharts';

import BarChartSkeleton from 'components/skeletons/charts-skeleton/bar-chart-skeleton';

import useStyles from './summary-line-chart.styles';

interface IProps {
  values: { name: string; value: number }[] | undefined;
  lineColor: string;
  isLoading?: boolean;
  aspect: number;
}

const SummaryLineChart = (props: IProps) => {
  const classes = useStyles();
  if (props.isLoading) {
    return <BarChartSkeleton numberOfBars={6} maxBarHeight={60} />;
  }

  const CustomTooltip = ({ active, payload }: TooltipProps) => {
    if (active) {
      return (
        <>
          {payload && (
            <Paper className={classes.tooltipPaper}>
              {/* <Box fontWeight="bold">{payload[0].payload.name}</Box> */}
              <Box className="label">{payload[0].value}</Box>
            </Paper>
          )}
        </>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer aspect={props.aspect} width="100%" >
      <LineChart className={classes.hoverLineEffect} data={props.values} /* margin={{ top: 35 , right: 10, left: 0, bottom: 90 }} */ >
        <Legend align='right' verticalAlign='top' wrapperStyle={{left: 90, top: -10}} formatter={() => <span className={classes.legend}>Past 90 Day Trendline</span>} />
        <Tooltip isAnimationActive={false} content={CustomTooltip} />
        <CartesianGrid  vertical={false} stroke="rgba(183, 194, 205, 1)" />
        <XAxis dataKey="name" dy={10} axisLine={false} tickLine={false} />
        <YAxis dx={-10}  axisLine={false} tickLine={false} />
        <Line
          type="monotone"
          connectNulls
          dataKey="value"
          stroke={props.lineColor}
          fill={props.lineColor}
          dot={{fill: props.lineColor, r: 4}}
          activeDot={{fill: "#FDFDFD", r: 5, stroke: props.lineColor, strokeWidth: 3}}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SummaryLineChart;
