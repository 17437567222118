import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as UiService from "services/ui-service";
import * as NotificationsStore from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";

export interface UiStore {
  isLoading: boolean;
  help: {
    [key: string]: string;
  };
}

const initialState: UiStore = {
  isLoading: false,
  help: {},
};

const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLoading: (state: UiStore, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setHelp: (
      state: UiStore,
      action: PayloadAction<{ index: string; text: string }>
    ) => {
      state.help[action.payload.index] = action.payload.text;
    },
  },
});

export const getHelp = (index: string): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    const help = store().ui.help;

    if (help[index]) {
      return;
    }

    await UiService.getHelp(index)
      .then(({ data }) => {
        dispatch(setHelp({ index, text: data.text }));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(NotificationsStore.addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const setHelpText = (index: string, text: string): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    const help = store().ui.help;
    if (help[index]) {
      return;
    }
    dispatch(setHelp({ index, text }));
  }
};

export const { reducer } = slice;
export const { setLoading, setHelp } = slice.actions;
