import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
  boxWrapper: {
    position: "relative",
  },
  boxWrapperInner: {
    margin: "20px 0",
    "& .headerHelpClass":{
      position: "absolute",
      right: "24px",
    }
  },
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: "12px",
    order: 2,
    '@media (max-width:900px)': {
      width: "100%",
      order: 1,
      flexDirection: "column",
      "& button": {
        marginBottom: '5px',
        marginLeft: 0,
      }
    }
  },
  btnAdd: {
    marginLeft: "8px",
    lineHeight: "18px !important",
    borderRadius: "8px !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 500,
    // padding: "13px 16px !important",
    fontSize: "15px !important",
    '& span': {
      marginRight: 6,
      fontSize: 21,
      fontWeight: 500
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingRight: "25px",
    // paddingTop: "25px",
    // paddingBottom: "15px",
    background: "#FDFDFD",
    // boxShadow: "1px -1px 0px rgba(197, 197, 197, 0.25), 1px 1px 0px rgba(197, 197, 197, 0.25)",
    '@media (max-width:900px)': {
      flexDirection: "column",
    }
  },
  /* commonText: {
    fontFamily: "Inter",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1B2E3F',
  } */
  vendorPage: {
    '& .vendorHelpIndexPg > div':{
      // marginBottom: "-30px",
      // height: 0,
    margin: 0,
    },
    '& .vendorHelpIndexPg > div:nth-child(1) > div:nth-child(1) > div:nth-child(1)':{
      marginBottom: "0px !important",
    },
    '& .tableStaticCls': {
      padding: "0 22px 0 22px",
      '& p': {
        textAlign: "center",
        fontWeight: 400,
      },
    },
    '& .tableStaticCls > div:nth-child(1) p': {
      textAlign: "left !important",
    },
    '& .tableBodyStaticCls': {
      overflowY: "auto",
      minHeight: "52vh"
    },
    '& .tableBodyStaticCls div': {
      // padding: "0 11px 0 11px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#1B2E3F",
      alignItems: "center",
      justifyContent: "center",
    },
    '& .tableBodyStaticCls div:nth-child(1)': {
      justifyContent: "left",
    },
    '& .High > span': {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      color: "#F38F8F",
      border: "1px solid",
      padding: "5px 10px",
      borderRadius: "4px",
    },
    '& .Medium > span': {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      color: "#FFBC00",
      border: "1px solid",
      padding: "5px 10px",
      borderRadius: "4px",
    },
    '& .Low > span': {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      color: "#33CCA8",
      border: "1px solid",
      padding: "5px 10px",
      borderRadius: "4px",
    },
    '& .securityArti button':{
      position: 'relative',
      transform: 'rotate(40deg)',
    },
    '& .securityArti button span':{
      position: 'absolute',
      background: "skyblue",
      padding: "2px 6px",
      borderRadius: "32px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "#FFFFFF",
      top: "-14px",
      left: "12px",
      transform: 'rotate(320deg)',
    },
    '& span.circlena':{
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "9px",
      lineHeight: "13px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "#61778B",
      background: "#ecf2f7",
      padding: "5px 3px",
      borderRadius: "32px",
    },
    '& .Actions svg':{
      cursor: "pointer",
    },
    '& .Actions button': {
      /* margin-right: 10px; */
        marginLeft: "10px",
    },
    // '& .Actions > svg > path': {
    //     fill: "#B7C2CD",
    // },
    '& .helpStaticTxt': {
      position: "absolute",
      right: "50px",
      top: "37px",
    }
  },
  searchInputWrapper: {
    position: "relative",
    marginRight: "10px",
    width: "418px",
  },
  searchInpt: {
    marginRight: "15px",
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px",
    width: "418px",
    height: "42px",
    paddingLeft: "40px",
    "&::-webkit-input-placeholder": { /* Edge */
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#B7C2CD",
    },
    
    "&:-ms-input-placeholder": { /* Internet Explorer 10-11 */
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#B7C2CD",
    },
    
    "&::placeholder": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#B7C2CD",
    },
  },
  // headerTopWrapper: {
  //   // position: "relative",
  //   // top: "-40px",
  // },
  // '& .MuiBox-root':{
  //   margin: "0px !important",
  // }
}));
    

