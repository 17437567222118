import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  colName: {
    '& input': {
      width: '100%',
      padding: 0,
      border: 0,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      outline: 'none',
      fontWeight: 500,
      fontFamily: `"Inter", sans-serif`,
      fontSize: 16,
      lineHeight: '19px',
      color: '#1B2E3F',
      backgroundColor: "transparent",
    }
  },
  btnSquare: {
    width: 24,
    height: 24,
    background: '#497DAB !important',
    borderRadius: '3px'
  },
  iconDisabled: {
    pointerEvents: 'none',
    '& path': {
      fill: '#B7C2CD'
    }
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  adopted: {
    backgroundColor: "#e9e9e9"
  },
  noWrap: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
}));
