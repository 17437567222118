import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(10),
    height: "100%",
    // boxShadow: "0px 0px 24px -15px rgba(0,0,0,0.75)",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5),
    },
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.7)",
    borderRadius: "8px",
  },
  tooltip: {
    marginLeft: theme.spacing(10),
    // color: (theme.palette as any).gunSmoke.main,
    color: "#33CCA8",
    position: "absolute",
    right: theme.spacing(10),
    top: "5px",
  },
  textChartContainer: {
    margin: "auto",
    width: "100%",
    // display: "flex",
    alignItems: "center",
    // padding: theme.spacing(10),
    position: "relative",
  },
  text: {
    color: '#99A9B8',
    textTransform: 'uppercase',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.04em",
  }
}));
