import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tooltipPaper: {
    // padding: theme.spacing(5),
    background: "#61778B",
    width: "35px",
    height: "25px",
    borderRadius: "6px 6px 0px 6px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "3px 7px",
    color: "#FFFFFF",
  },
  legend: {
    color: '#61778B',
  },
  hoverLineEffect: {
    '& .recharts-surface': {
      overflow: 'visible',
    },
    '& .recharts-legend-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(10),
    },
    '& .recharts-layer.recharts-line path:hover':{
      strokeWidth: "3",
      strokeDasharray: "3 3",
    }
  }
}));
