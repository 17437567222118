import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  toolsContainer: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  button: {
    minWidth: theme.spacing(57),
    textTransform: "none",
    borderRadius: (theme as any).borderRadius.small,
    boxShadow: "none",
    height: theme.spacing(18),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  actionsContainer: {
    [theme.breakpoints.down("md")]: {
      order: 3,
      marginLeft: "auto",
    },
  },
  testBtn: {
    marginLeft: theme.spacing(23),
    color: theme.palette.common.white,
    backgroundColor: (theme.palette as any).royalBlue.main,
    marginRight: theme.spacing(3),
    "&:hover": {
      backgroundColor: (theme.palette as any).royalBlue.main,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  deleteBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    marginLeft: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  downloadBtn: {
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      order: 2,
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  formTitle: {
    marginBottom: theme.spacing(3),
  },
  activeSwitch: {
    margin: 0,
  },
}));
