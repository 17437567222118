
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';
import useStyles from "./pie-chart.styles";

interface Iprops {
    data: { name: string, value: number, color: string}[],
    aspect: number,
}

const PieChartSummary = ({data, aspect}: Iprops) => {
    const classes = useStyles();
    return (
        <ResponsiveContainer aspect={aspect} className={classes.pieChartCircle}>
            <PieChart /* width={200} height={200} */ /* margin={{ top: 0, right: 0, left: 80, bottom: 0 }} */>
                <Pie
                    stroke="none"
                    legendType="circle"
                    data={data}
                    dataKey="value"
                    startAngle={180}
                    endAngle={-180}
                    cornerRadius={100}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={-10}
                    isAnimationActive={true}
                    // cx={120}
                    // cy={200}
                >
                {data.map((entry: any, index: any) => (
                    <Cell key={`slice-${index}`} fill={entry.color} />
                ))}
                <Label width={50} position="center">
                  { `${data.length}`}
                </Label>
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
export default PieChartSummary;