import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  colName: {
    maxWidth: "10rem",
    '& p': {
      fontWeight: 500
    },
  },
  inputText: {
    width: '100%',
    padding: "13px 16px",
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
    fontWeight: 500,
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    border: 'initial',
    borderBottom: "1px solid rgba(183, 194, 205, 0.5)",
    "&::placeholder": {
      color: "#B7C2CD"
    },
    "&:focus": {
      border: 'initial',
      borderBottom: "1px solid #33CCA8",
      boxShadow: "0px 3px 0px 0px rgb(51 204 168 / 20%)"
    }
  },
  errorName: {
    '& input': {
      border: "1px solid #F38F8F",
      boxShadow: "0px 0px 0px 3px rgba(255, 94, 94, 0.1)"
    },
  },
  btnSquare: {
    width: 24,
    height: 24,
    background: '#497DAB !important',
    borderRadius: '3px',
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  btnsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  btnSave: {
    height: 30,
    width: 30,
    backgroundColor: '#33CCA8',
    fontSize: '15px',
    padding: '0 7px',
    marginRight: '8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  btnCancel: {
    height: 30,
    width: 30,
    backgroundColor: '#F38F8F',
    fontSize: '15px',
    padding: '0 8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      borderTopColor: 'white',
    }
  },
  noWrap: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
}));
