import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  connectBtn: {
    textTransform: "none",
    padding: theme.spacing(5, 15),
    marginRight: theme.spacing(40),
    marginLeft: "12px",
    borderRadius: "30px",
    background: "#33CCA8"
  },
  slightTopMargin: {
    marginTop: theme.spacing(5),
  },
  activeSwitch: {
    marginRight: "10px",
  },
  timelineContent: {
    textAlign: "left",
    padding: "0",
  },
  alignLeft: {
    textAlign: "left",
    padding: "0",
  },
  formTitle: {
    fontWeight: 600,
    position: "relative",
    top: "4px",
    marginBottom: "20px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
  },
  formBlwBxTitle: {
    fontWeight: 600,
    position: "relative",
    top: "4px",
    marginBottom: "20px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#1B2E3F",
  },
  connTitle: {
    fontWeight: 500,
    position: "relative",
    top: "0px",
    marginBottom: "24px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "#1B2E3F",
  },
  mainBoxContent: {
    marginLeft: "15px",
  },
  connStatusBox: {
    border: "1px solid #3333",
    height: "80px",
    borderRadius: "5px",
    padding: "16px",
    color: "#61778B",
  },
  timeBtn: {
    background:"#B7C2CD",
    marginLeft: "8px",
    fontSize: "9px",
    padding: "2px",
    color: "#FFFFFF",
    position: "relative",
    top: "-2px",
  },
  connStatusBoxPadd: {
    padding:"0px 10px",
  },
  backButton: {
    background: "none",
  },
  timelineDotFill: {
    background:"#33CCA8 !important",
  },
  timelineDot: {
    padding: "0px 6px !important",
    marginTop: "1px !important",
    boxShadow: 'none !important',
  },
  timelineConnector: {
    color: "#61778B",
    opacity: 0.3,
  },
  errorMsg:{
    background: "#FFEBEB",
    position: "relative",
    padding: "5px 10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  crossIcon:{
    background: "#FFEBEB",
    marginRight: "5px",
    position: "absolute",
    color: "#F38F8F",
    height: '20px',
    width: '15px',
  },
  errorText:{
    marginLeft: "25px",
    color: "#F38F8F",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  errorTopText: {
    display: "inline-block",
    background: "#FFEBEB",
    position: "relative",
    left: "10px",
    padding: "2px 5px",
    color: "#F38F8F",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    alignItems: 'center',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
  backIcon:{
    position: "relative",
    fontSize: "12px",
    top: "1px",
  },
  InputField:{
    width: "400px",
  },
  InputLabel: {
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#61778B",
  },
  saveBtnWrapper: {
    marginBottom: "50px",
  },
  timelineContentWrapper: {
    position: "relative",
    padding: "0",
    border: "1px solid #F3F5F6",
    borderBottom: 0,
    paddingTop: "15px",
  },
  disableButtonGrid: {
    position: 'absolute',
    right: "10px",
    top: "5px",
    width: "320px",
    paddingTop: "15px",
  },
  leftGrid: {
    width: "30%",
    float: "left"
  },
  rightGrid: {
    width: "70%",
    float: "right",
  },
  serviceTitle: {
    Weight: 500,
    position: "relative",
    top: "4px",
    display: "inline-block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "27px",
    color: "1B2E3F",
  },
  DeleteBtn:{
    width: "73px",
    height: "26px",
    background: "#F38F8F",
    color: "#fff",
    borderRadius: '30px',
  },
  divider: {
    width: "100%",
    backgroundColor: (theme.palette as any).tableBackground.main,
    height: theme.spacing(1),
    // margin: `${theme.spacing(20)}px 0`,
    margin: "10px 0",
    marginBottom: "25px",
  },
  alignElement:{
    // marginLeft: "20px",
  },
  backButtonEle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#7B90A3"
  }
}));
