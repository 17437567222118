import React, {useEffect, useState} from "react";
// import SectionWrapper from "../../../components/section-wrapper";
import useStyles from './profile-settings-policies.styles'
import TabsWrapper from "../../../components/tabs-wrapper";
import Button from "../../../components/button";
import Spinner from "../../../components/spinner";
import { ReactComponent as DownloadIcon } from 'icons/download.svg'
import ActivePoliciesTable from "./tables/active-policies-table";
import RetiredPoliciesTable from "./tables/retired-policies-table";
import PolicyTemplatesTable from "./tables/policy-templates-table";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../interfaces/app-state-interface";
import {
  fetchEmployees,
  fetchPolicies,
  fetchTemplates,
  fetchTopics,
  addPolicy,
  downloadPolicies,
  downloadTemplates,
  setHelpIndex
} from "../../../redux/features/settings-store";
import {Policy} from "../../../interfaces/settings-interface";
import {dateToString} from "../../../utils/text-utils";

const ProfileSettingsPolicies = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector<AppState, boolean>(
    (state) => state.ui.isLoading
  );
  const activePolicies = useSelector<AppState, Policy[]>(
    (state) => state.settings.policies.filter(({ status }) => status === 'active').reverse()
  );
  const retiredPolicies = useSelector<AppState, Policy[]>(
    (state) => state.settings.policies.filter(({ status }) => status === 'inactive').reverse()
  );
  const templates = useSelector<AppState, Policy[]>(
    (state) => state.settings.templates
  );

  const tabs = [
    {
      label: 'Active',
      value: 'active',
      count: activePolicies.length
    },
    {
      label: 'Retired',
      value: 'retired',
      count: retiredPolicies.length
    },
    {
      label: 'Templates',
      value: 'templates',
      count: templates.length
    },
  ]

  const [currentTab, setCurrentTab] = useState("active");
  const [isLoadingDownloadTemplates, setIsLoadingDownloadTemplates] = useState<boolean>(false);
  const [isLoadingDownloadPolicies, setIsLoadingDownloadPolicies] = useState<boolean>(false);

  function handleAddPolicy() {
    const resultPolicy: Policy = {
      policy_id: Date.now().toString(),
      owner_name: '',
      policy_name: '',
      policy_review_date: dateToString(new Date()),
      reattestation_by_employee: 'no',
      topics: [],
      status: 'active',
      copy: true
    }

    dispatch(addPolicy(resultPolicy))
  }

  async function handleDownloadPolicies() {
    setIsLoadingDownloadPolicies(true)
    await dispatch(downloadPolicies())
    setIsLoadingDownloadPolicies(false)
  }

  async function handleDownloadTemplates() {
    setIsLoadingDownloadTemplates(true)
    await dispatch(downloadTemplates())
    setIsLoadingDownloadTemplates(false)
  }

  const onPageInit = async () => {
    dispatch(setHelpIndex("settings/policiesTable"));
    dispatch(fetchPolicies());
    dispatch(fetchTemplates());
    dispatch(fetchTopics());
    dispatch(fetchEmployees());
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit()
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      {/* <SectionWrapper policies={true} title={''}> */}
      {/*<Box style={{ marginBottom: 15, }} m={20} mt={0}>*/}
      {/*  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }}>*/}
      {/*    <Tooltip title={'Policies are used for governing an organization and are mandated by all compliance standards. To setup your policies, you can use CloudRemedy templates to generate your policies OR upload your custom policies OR do both on this page.'} className={classes.tooltip}>*/}
      {/*      <div style={{ display: "flex", alignItems: "center" }}>*/}
      {/*        <span className={classes.help}>Help</span>*/}
      {/*        <HelpIcon fontSize="small" />*/}
      {/*      </div>*/}
      {/*    </Tooltip>*/}
      {/*  </div>*/}
      {/*</Box>*/}
      <div className={classes.header}>
        <TabsWrapper value={currentTab} tabs={tabs} className={classes.subTabs} onChange={setCurrentTab} />
        <div className={classes.buttons}>
          {
            currentTab !== 'retired' &&
            <>
              {
                currentTab === 'active' ?
                  <>
                    <Button onClick={handleDownloadPolicies} className={classes.btnDownload} type={'infoBlue'}>
                      { isLoadingDownloadPolicies &&
                      <div className={classes.spinnerWrapper}>
                        <Spinner />
                      </div>
                      }
                      { !isLoadingDownloadPolicies && <DownloadIcon /> }
                      Download Policies
                    </Button>
                  </>
                  :
                  <Button onClick={handleDownloadTemplates} className={classes.btnDownload} type={'infoBlue'}>
                    { isLoadingDownloadTemplates &&
                    <div className={classes.spinnerWrapper}>
                      <Spinner />
                    </div>
                    }
                    { !isLoadingDownloadTemplates && <DownloadIcon /> }
                    Download Templates
                  </Button>
              }
            </>
          }
          { currentTab === 'active' &&
          <Button onClick={handleAddPolicy} className={classes.btnAdd} type={'info'}>
            <span>+</span>
            Add Custom Policy
          </Button>
          }
        </div>
      </div>
      {
        currentTab === 'active' &&
          <ActivePoliciesTable isLoading={isLoading} data={activePolicies} />
      }
      {
        currentTab === 'retired' &&
          <RetiredPoliciesTable data={retiredPolicies} />
      }
      {
        currentTab === 'templates' &&
          <PolicyTemplatesTable setTab={setCurrentTab} data={templates} />
      }
    {/* </SectionWrapper> */}
    </>
  );
};

export default ProfileSettingsPolicies;
