import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Box,
  MenuItem,
  Radio,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import lodash from "lodash";
import useStyles from "./integrations-form.styles";
// import SectionWrapper from "components/section-wrapper";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { TimelineOppositeContent } from "@material-ui/lab";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateRounded from '@material-ui/icons/CreateRounded';

interface CheckboxGroupProps {
  name: string;
  options: string[],
  values: string[],
  onChange: (e: { target: { type: string, name: string, value: string[] } }) => void;
}
interface SecretFieldProps {
  name: string;
  defaultValue: string,
  label: string,
  className: string,
  onChange: (e: { target: {name: string, value: string } }) => void;
}
function CheckboxGroup({ name, options, values, onChange }: CheckboxGroupProps) {
  
  const [valuesData, setValuesData] = React.useState<string[]>([])

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    setValuesData(values || []);
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  function handleChange({ target: { type, name: checkboxName, checked } }: React.ChangeEvent<HTMLInputElement>) {
    let updatedValues = [...valuesData]

    if (checked)
      updatedValues.push(checkboxName)
    else
      updatedValues = valuesData.filter(value => value !== checkboxName)
    
    setValuesData(updatedValues);
    onChange({ target: { type, name, value: updatedValues } })
  }

  return (<>
        {options.map((option: any) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                checked={valuesData.includes(option) || false}
                onChange={handleChange}
                name={option}
                color="primary"
              />
            }
            label={option}
          />
        ))}
        </>
  )
}

function SecretField({ label, className, name, defaultValue, onChange }: SecretFieldProps) {
  
  const [SecrateValue, setSecrateValue] = React.useState<string>('');
  const [showpassword, setshowpassword] = React.useState({showPassword: true, editable: false});
  const [isFirstTime, setisFirstTime] = React.useState(true);
  /* eslint-disable react-hooks/exhaustive-deps */
  
  React.useEffect(() => {
    setSecrateValue(defaultValue || '');
    setisFirstTime(true);
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  function handleChange({ target: {name, value} }: React.ChangeEvent<HTMLInputElement>) {
    setSecrateValue(value);
    onChange({ target: {name, value: value} })
  }
  const handleClickShowPassword = () => {
    setshowpassword({ ...showpassword, showPassword: !showpassword.showPassword, editable: !showpassword.editable,});
  };
  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        className={className}
        name={name}
        label={label}
        type={showpassword.showPassword ? 'password' : 'text'}
        value={SecrateValue}
        onChange={handleChange}
        InputProps={{
          autoComplete: "new-password",
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  if(isFirstTime){
                    handleChange({target: {name, value: ''}} as any);
                    setisFirstTime(false); 
                  }
                  handleClickShowPassword();
                }}
              >
                {showpassword.showPassword ? (<CreateRounded />) : (<CreateRounded />)}
              </IconButton>
            </InputAdornment>
        }}
      />
    </>
  )
}
interface IProps {
  icon: string;
  service: string;
  service_type: string;
  status: string;
  integrationFields: any;
  onBackClick(): void;
  ServiceSummary: any;
  onDeleteIntegration(serviceType: string, service: string): void;
  onSubmitSavedata(serviceType: string, service: string, payload: any): void;
  onPutIntegrationServiceToggle(serviceType: string, service: string, data: string): void;
}

const IntegrationsForm = (props: IProps) => {
  const [EnableChecked, setEnableChecked] = React.useState(false);
  const classes = useStyles();
  const [serviceFields, setServiceFields] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [isSaveEnabled, setIsSaveEnabled] = React.useState(true);
  const [isHasValue, setIsHasValue] = React.useState(false);

  useEffect(() => {
    setEnableChecked(props.status === "active");
  }, [props.status])

  const fields: any = {};
  const fieldsData: any = {};

  props.integrationFields.forEach((element: any) => {
    fields[element.name] = element.value || ''
    fieldsData[element.name] = element || {}
  });

  const handleChange = (event: any) => {
    setForm(lodash.pickBy({ ...form, [event.target.name]: event.target.value }, function(value, key) {
      return !(value === false || value === "");
    }));
  };

  props.integrationFields.forEach((element: any) => {
    fields[element.name] = element.value || '1234'
    fieldsData[element.name] = element || {}
  });

  useEffect(() => {
    setIsSaveEnabled(lodash.isEmpty(form))
  }, [form])  

  useEffect(() => {
    setServiceFields(props.integrationFields)

    let hasValue = false;
    props.integrationFields.forEach((element: any) => {
      if(element.value){
        hasValue= true;
        return;
      }
    });
    setIsHasValue(hasValue);
  }, [props.integrationFields])  

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let payload: any = {}
    const updatedFields: any = {};

    let checkboxFieldName;

    for (var key in fieldsData) {
      if (fieldsData.hasOwnProperty(key) && fieldsData[key].field_type === "checkbox" ) {
        checkboxFieldName = fieldsData[key].name;
      }
    }

    let checkboxValue = [];

    for (let key in form) {
      if (form.hasOwnProperty(key) && (form as any)[key] !== '' ) {
        // for boolean
        if((form as any)[key] && fieldsData[key] && fieldsData[key].field_type === "boolean") {
          updatedFields[key] = (form as any)[key];
        }
        // for checkbox
        else if((form as any)[key] && !fieldsData[key]) {
          checkboxValue.push(key);
        }
        else if((form as any)[key] && fieldsData[key]) {
          updatedFields[key] = (form as any)[key];
        }
      }
    }
    
    if(checkboxValue.length !== 0) updatedFields[checkboxFieldName] = checkboxValue;

    payload = {...updatedFields, status: props.status};

    onPutIntegrationService(props.service, payload);
  }

  const onPutIntegrationService = (service: string, payload: any) => {
    props.onSubmitSavedata(props.service_type, service, payload);
  }

  // const handlePasswordChange = (prop: any) => (event: any) => {
  //   setValuePwd({ ...valuePwd, [prop]: event.target.value });
  // };

  const onDeleteIntegration = (service: string) => {
    props.onDeleteIntegration(props.service_type, service);
  };

  const onChangeDelete = () => {
    onDeleteIntegration(props.service);
  };
  const onPutIntegrationServiceToggle = (service: string, data: string) => {
    props.onPutIntegrationServiceToggle(props.service_type, service, data);
  }
  const onChangeSwitch = (e: any) => {
    setEnableChecked(e.target.checked);
    if(e.target.checked){
      onPutIntegrationServiceToggle(props.service,'enable');
    }else{
      onPutIntegrationServiceToggle(props.service,'disable');
    }
  };

  return (
    <Timeline align="left" className={classes.timelineContentWrapper} data-testid="integration-form">
      <Grid item md={12} className={classes.alignElement}>
        <Typography variant="body2" className={classes.backButton}>
          {" "}
          <Button onClick={props.onBackClick} className={classes.backButtonEle} data-testid="integration-form-back-btn" >
            <ArrowBackIosIcon fontSize="small" className={classes.backIcon} />{" "}
            Back{" "}
          </Button>
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid item sm={12} md={12} className={classes.disableButtonGrid}>
          <Grid
            container
            item
            sm={6}
            md={3}
            alignItems="center"
            className={classes.leftGrid}
          >
            <img
              width={58}
              height={62}
              src={props.icon}
              alt="Integration-Icon"
            />
          </Grid>
          <Grid
            container
            item
            sm={6}
            md={9}
            alignItems="center"
            className={classes.rightGrid}
          >
            <Grid container item sm={6} md={12} alignItems="center">
              <Typography variant="body2" className={classes.serviceTitle}>
                {props.service}
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={6}
              md={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <FormControlLabel
                  label={EnableChecked ? "Enable" : "Disable"}
                  labelPlacement="end"
                  className={classes.activeSwitch}
                  control={
                    <Switch
                      checked={!!EnableChecked}
                      onChange={onChangeSwitch}
                      name="active"
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Button 
                  variant="contained" 
                  onClick={onChangeDelete} 
                  color="secondary" 
                  className={classes.DeleteBtn}>Delete</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className={classes.alignElement}>
        <Typography variant="body2" className={classes.formTitle}>
          Setup Intregation
        </Typography>
      </Grid>
      <Box className={classes.divider} />

      <TimelineOppositeContent
        style={{ flex: 0 }}
        className={classes.timelineContent}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className={`${classes.timelineDot} ${!isSaveEnabled || isHasValue? classes.timelineDotFill : ''}`}>1</TimelineDot>
            <TimelineConnector className={classes.timelineConnector} />
          </TimelineSeparator>
          {/* <SectionWrapper
            title=""
            helpIndex="settings/integrationForm"
            type="formType"
          > */}
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" className={classes.alignLeft}>
              <Grid
                container
                alignItems="center"
                className={classes.mainBoxContent}
              >
                <Grid item md={12}>
                  <Typography variant="body2" className={classes.connTitle}>
                    Connection Details
                  </Typography>
                </Grid>
                {
                    serviceFields.map((field: any, index: number) => {
                    const isTextField = field.field_type === "text";
                    const isSecretField = field.field_type === "secret";
                    const isSelectField = field.field_type === "dropdown";
                    const isCheckboxField =  field.field_type === "checkbox";
                    const isRadioField =  field.field_type === "radio";
                    const isBooleanField =  field.field_type === "boolean";
                    return (
                      <React.Fragment key={index}>
                        <Grid item sm={12} md={2}>
                          <Typography variant="body2" className={classes.InputLabel}>
                            {field.label}
                          </Typography>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          {isTextField ? (
                            <TextField
                              size="small"
                              autoComplete="new-password"
                              defaultValue={field.value? field.value : ''}
                              name={field.name}
                              className={classes.InputField}
                              variant="outlined"
                              fullWidth
                              type={"text"}
                              onChange={handleChange}
                            />
                          ):null}
                          {isSecretField?(
                            <SecretField
                              label={"Password"}
                              className={classes.InputField}
                              name={field.name}
                              defaultValue={field.value? field.value : ''}
                              onChange={handleChange}
                            />
                          ): null}
                          {isSelectField ? (
                            <TextField
                              size="small"
                              select
                              label="Select"
                              name={field.name}
                              className={classes.InputField}
                              variant="outlined"
                              fullWidth
                              onChange={handleChange}
                            >
                              {field.valid_values.map((option: any) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          ): null}
                          {isCheckboxField? (
                            <CheckboxGroup
                              name={field.name}
                              options={field.valid_values}
                              values={field.value}
                              onChange={handleChange} 
                            />
                          ):null}
                          {isRadioField? (
                            <RadioGroup row onChange={handleChange}>
                              {field.valid_values.map((checkvalue: any) => 
                                <FormControlLabel
                                  value={checkvalue}
                                  control={<Radio color="primary" />}
                                  label={checkvalue}
                                  labelPlacement="start"
                                />
                              )}
                            </RadioGroup>
                          ):null}
                          {isBooleanField? (
                            <FormControlLabel
                              label={field.label}
                              labelPlacement="end"
                              className={classes.activeSwitch}
                              control={
                                <Switch
                                  onChange={handleChange}
                                  name={field.name}
                                />
                              }
                            />
                          ):null}
                        </Grid>
                        <Box mt={13} width="100%" />
                      </React.Fragment>
                    );
                  })}
              </Grid>
              <Grid item sm={12} md={2} className={classes.saveBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  className={classes.connectBtn}
                  disabled={isSaveEnabled}
                >
                  Save
                </Button>
              </Grid>

              <Grid item sm={12} md={6} />
            </Grid>
          </form>
          {/* </SectionWrapper> */}
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className={`${classes.timelineDot} ${!isSaveEnabled || isHasValue? classes.timelineDotFill : ''}`}>2</TimelineDot>
          </TimelineSeparator>
          <Grid container alignItems="center" className={classes.alignLeft}>
            <Grid
              container
              alignItems="center"
              className={classes.mainBoxContent}
            >
              <Grid item md={12}>
                <Typography variant="body2" className={classes.connTitle}>
                  Connection Status
                </Typography>
                {props.ServiceSummary.sections[0].connection_status === 'failed' && <Typography variant="body2" className={classes.errorTopText}>Error</Typography>}
              </Grid>
              {props.ServiceSummary.sections[0].widgets.map(
                (widget: any, index: number) => {
                  return (
                    <Grid
                      item
                      sm={12}
                      md={4}
                      className={classes.connStatusBoxPadd}
                      key={index}
                    >
                      <Grid className={classes.connStatusBox}>
                        <div>{widget.widget_label}</div>
                        <div className={classes.formBlwBxTitle}>
                          <b>
                            {index === 0
                              ? moment(widget.value).format("MMMM D YYYY h:mm")
                              : widget.value}
                          </b>
                          {index === 0 && (
                            <span className={classes.timeBtn}>
                              {moment(widget.value).format("A")}
                            </span>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              )}
              {props.ServiceSummary.sections[0].connection_status === 'failed' &&
                <Grid container item sm={12} md={6} alignItems="center" className={classes.errorMsg}>
                  <CancelIcon fontSize="small" className={classes.crossIcon}/><span className={classes.errorText}>{props.ServiceSummary.sections[0].connection_error}</span>
                </Grid>
              }
            </Grid>
          </Grid>
        </TimelineItem>
      </TimelineOppositeContent>
    </Timeline>
  );
};

export default IntegrationsForm;
