import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  checkbox: {
    padding: "10px 17px",
  },
  wrapperText: {
    width: "100%",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  btnTrigger: {
    position: "relative",
    width: '400px',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    background: "#FFFFFF",
    border: "1px solid rgba(183, 194, 205, 0.5)",
    boxSizing: "border-box",
    borderRadius: "8px 8px 8px 8px",
    marginTop: "30px",
    '&:hover': {
      borderColor: "#1B2E3F",
    },
    '&:focus-within': {
      border: "2px solid #1B2E3F",
      borderColor: "#1B2E3F",
    },
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#1B2E3F',
      textAlign: 'left',
      width: "100%",
    },
    '& span': {
      fontWeight: 600,
      color: '#99A9B8',
      fontSize: 14,
      lineHeight: '14px',
      background: 'rgba(183, 194, 205, 0.2)',
      borderRadius: 4,
      padding: '10px 8px',
      marginLeft: 8,
      marginTop: "9px",
      // marginBottom: "8px",
      '& span':{
        background: 'none',
        cursor: 'pointer',
        marginBottom: "0px",
      }
    },
    '& svg': {
      // minWidth: 24,
      // marginLeft: 8,
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)',
      // position: "absolute",
      right: "10px",
      top: "8px",
      width: "10px",
      height: "10px",
    }
  },
  search: {
    font: "inherit",
    color: "currentColor",
    width: "100%",
    margin: 0,
    display: "block",
    padding: "10px 10px 10px",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    "-webkit-tap-highlight-color": "transparent",
    outline: "initial",
    border: "none",
    borderRadius: "8px 8px 8px 8px",
  },
  wrapperInput: {
    width: "100%",
    position: "relative",
    // marginBottom: "15px",
    // marginTop: "15px"
    padding: "0px 14px",
  },
  wrapperInputWithVal: {
    width: "100%",
    position: "relative",
    // marginTop: "-23px",
    zIndex: 9,
    padding: "0px 0px 10px",
    '& input': {
      paddingLeft: "10px",
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    display: "flex",
    margin: "15px 0",
    justifyContent: "center",
    "& div": {
      width: 22,
      height: 22,
      borderTopColor: '#33CCA8',
    }
  },
  notResult: {
    color: "#B7C2CD",
    textAlign: "center",
    margin: "10px 0"
  },
  chipVal: {
    position: "relative",
    top: "10px",
    // left: "10px",
    // zIndex: 9,
    "& span": {
      background: "rgba(183, 194, 205, 0.2)",
      borderRadius: "4px",
      padding: "5px 10px",
      marginBottom: "5px",
      display: "inline-block",
      position: "relative",
      zIndex: 99,
      "& span": {
        background: "none",
        marginBottom: "0px",
        padding: "0px 0px",
        cursor: "pointer",
      }
    }
  },
  wrapperBelowButon: {
    position: "absolute",
    top: "40px",
    right: 0,
    bottom: 0,
  },
  belowButon: {
    background: "rgba(183, 194, 205, 0.1)",
    border: "1px solid #DBE0E6",
    boxSizing: "border-box",
    borderRadius: "0px 0px 6px 6px",
    width: "55px",
    padding: "3px",
  },
  checkButton: {
    minWidth: "22px",
    padding: "5px 5px",
    margin: 0,
    marginRight: "3px",
    height: "22px",
    background: "#33CCA8",
    '& span':{
      marginLeft:0,
      color:"#fff",
      cursor: "pointer",
    },
    '& svg': {
      top: "4px",
      right: "0px",
      position: "absolute",
  }
  },
  crossButton: {
    minWidth: "22px",
    padding: "5px 5px",
    margin: 0,
    height: "22px",
    '& span':{
      marginLeft:0,
      cursor: "pointer",
    },
    '& svg': {
      top: "5px",
      right: "-1px",
      position: "absolute",
  }
  },
  triabgleButton: {
    position: "absolute",
    right: 0,
      background: "none !important",
      margin: "0px !important",
    top: "2px",
    padding: "0px !important",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "8px !important",
    '& svg': {
      width: '24px',
      height: '24px'
    }
  }
}));
