import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    height: "calc(100% + 10px)",
    // boxShadow: "0px 0px 24px -15px rgba(0,0,0,0.75)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5),
    },
  },
  policiesWrapper: {
    boxShadow: "initial",
    padding: 0,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(5),
  },
  formHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(5),
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  button: {
    fontWeight: "normal",
    textTransform: "initial",
    whiteSpace: "nowrap",
    fontSize: theme.spacing(7),
    padding: theme.spacing(4, 13),
  },
  downloadBtn: {
    '& svg': {
      width: '18px',
      marginRight: "8px",
    }
  },
  visibleBtn: {
    '& svg': {
      width: '18px',
    }
  },
  bgColor: {
    backgroundColor: '#497DAB',
  },
  arrowBtn: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    fontSize: theme.spacing(11),
    fontWeight: 600,
    color: '#1B2E3F',
  },
  sectionDescription: {
    fontSize: theme.spacing(7),
    fontWeight: 400,
  },
  tooltip: {
    color: (theme.palette as any).gunSmoke.main,
  },
  tooltipIntegration: {
    color: "#33CCA8"
  },
  helpText: {
    color: "#33CCA8",
    marginRight: "5px",
    fontSize: "16px"
  },
  iconColor: {
    color: " #33CCA8"
  },
  centerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  setHeaderGrid: {
    /* display: 'grid',
    gap: "10px",
    gridTemplateColumns: '1fr auto auto auto',
    width: '100%', */
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    gap: '10px',
    '@media (max-width:900px)': {
      gridTemplateColumns: 'auto',
    }
  },
  wrapCol: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
}));
