import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  colName: {
    maxWidth: "10rem",
    '& p': {
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  inputText: {
    width: '100%',
    padding: "13px 16px",
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
    fontWeight: 500,
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    border: 'initial',
    borderBottom: "1px solid rgba(183, 194, 205, 0.5)",
    "&::placeholder": {
      color: "#B7C2CD"
    },
    "&:focus": {
      border: 'initial',
      borderBottom: "1px solid #33CCA8",
      boxShadow: "0px 3px 0px 0px rgb(51 204 168 / 20%)"
    }
  },
  errorName: {
    '& input': {
      border: "1px solid #F38F8F",
      boxShadow: "0px 0px 0px 3px rgba(255, 94, 94, 0.1)"
    },
  },
  btnSquare: {
    width: 24,
    height: 24,
    background: '#497DAB !important',
    borderRadius: '3px',
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  btnsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  btnSave: {
    height: 30,
    width: 30,
    backgroundColor: '#33CCA8',
    fontSize: '15px',
    padding: '0 7px',
    marginRight: '8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  btnCancel: {
    height: 30,
    width: 30,
    backgroundColor: '#F38F8F',
    fontSize: '15px',
    padding: '0 8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      borderTopColor: 'white',
    }
  },
  noWrap: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  orienaTion: {
    '& span': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    }
  },
  mfaStatus: {
    '& .mfaWrapperP': {
      position: "relative",
    },
    '& .mfaWrapperP .mfaSuccess': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    },
    '& .mfaComplaint': {
        color: "#fff",
        display: "inline-block",
        padding: "2px 9px 2px 9px",
        background: "#F38F8F",
        borderRadius: "33px",
    },
    '& span.notAplicable': {
      position: "relative",
    },
    '& span.notAplicable > span': {
        position: "absolute",
        top: "-2px",
        left: "9px",
        color: "#fff",
    }
  },
  nameCol: {
    '& p':{
      textAlign:"left",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#1B2E3F",
    },
    '& span': {
    },
  },
  Icon: {
    '&.MuiAvatar-root': {
      backgroundColor: "#FAB26F !important",
      textAlign: "center",
      display: "inline-grid",
      borderRadius: "33px",
      marginRight: "10px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: '14px',
      width: '25px',
      height: '26px',
    },
    // padding: "6px 4px 0px 4px",
  },
  icon: {
    background: "orange",
    padding: "4px 4px 4px 6px",
    display: "inline-block",
    borderRadius: "33px",
    marginRight: "10px",
  },
}));
