import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  colName: {
    '& p': {
      fontWeight: 500
    },
    '& input': {
      width: '100%',
      padding: 0,
      border: 0,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      outline: 'none',
      fontWeight: 500,
      fontFamily: `"Inter", sans-serif`,
      fontSize: 16,
      lineHeight: '19px',
      color: '#1B2E3F'
    }
  },
  btnSquare: {
    width: 24,
    height: 24,
    background: '#497DAB !important',
    borderRadius: '3px'
  },
  datePicker: {
    pointerEvents: 'none'
  },
  btnSave: {
    borderRadius: "24rem",
    height: 40,
    border: "initial",
    backgroundColor: '#33CCA8',
    fontSize: '15px',
    padding: '0 12px',
    display: "flex",
    alignItems: "center",
  },
}));
