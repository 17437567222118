import React, {useRef} from "react";
import useStyles from './dropdown-wrapper.styles'
import clsx from "clsx";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";

interface DropdownWrapperProps {
  className?: string;
  opened: boolean;
  onClose: () => void;
  position?: 'left' | 'center' | 'right'
  triggerEl: React.ReactNode;
  children: React.ReactNode;
  search?: boolean,
  topicsSearch?: boolean,
  addTop72?: boolean,
  addTop42?: boolean,
  addTopAuto?: boolean,
}

function DropdownWrapper({ className, opened, onClose, triggerEl, children, position = 'left', search = false, topicsSearch = false, addTop72 = false, addTop42 = false, addTopAuto = false }: DropdownWrapperProps) {
  const classes = useStyles()
  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, onClose)

  return (
    <div className={clsx(className, classes.root, classes[position], { [classes.opened]: opened })}>
      { triggerEl }
      <div ref={dropdownRef} className={clsx("dropdown", { [classes.notSearch]: !search, [classes.dropdown]: !search, [classes.dropDownSearch]: search, [classes.dropdownSearchTopics]: topicsSearch, [classes.addTop72]: addTop72, [classes.addTop42]: addTop42, [classes.addTopAuto]: addTopAuto })}>
        { children }
      </div>
    </div>
  )
}

export default DropdownWrapper
