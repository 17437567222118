import React from "react";
import { useSelector } from "react-redux";
import { Paper, Typography, Tooltip, Box } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import clsx from "clsx";

import { UiSelectors } from "redux/selectors";

import SectionToolsWrapper, {
  IProps as ISectionTools,
} from "./components/section-tools-wrapper";

import useStyles from "./section-wrapper.styles";
import { HelpType } from "interfaces/help-interface";

interface IProps extends ISectionTools {
  title: string;
  description?: string;
  helpIndex?: HelpType;
  children: React.ReactNode;
  type?: string;
  policies?: boolean;
  isDownloadIcon?: boolean;
}

const SectionWrapper = (props: IProps) => {
  const classes = useStyles();
  const help = useSelector(UiSelectors.helpSelector);

  return (
    <Paper className={props.type === "formType" ? '' : clsx(classes.root, { [classes.policiesWrapper]: props.policies })} elevation={0}>
      <div className={props.type === "formType" ? classes.formHeaderWrapper : [clsx(classes.headerWrapper, "headerHelpClass")].join(" ")}>
        <Box width="100%">
          {props.title && (
            <>
              <Typography variant="subtitle2" className={classes.sectionTitle}>
                {props.title}
              </Typography>
              {props.description && (
                <Typography
                  variant="subtitle1"
                  className={classes.sectionDescription}
                >
                  {props.description}
                </Typography>
              )}
            </>
          )}

          {!props.title && (
            <SectionToolsWrapper
              defaultVisibility={props.defaultVisibility}
              toolsPosition={props.toolsPosition}
              onScan={props.onScan}
              onSearch={props.onSearch}
              onDownloadXLS={props.onDownloadXLS}
              onDownloadJSON={props.onDownloadJSON}
              onToggleVisibility={props.onToggleVisibility}
              visibilityTooltipText={props.visibilityTooltipText}
              onCreateCustomRule={props.onCreateCustomRule}
            />
          )}
        </Box>
        <div className={[classes.centerText, 'helpStaticTxt'].join(' ')}>
          <Typography variant="body2" className={classes.helpText}>Help</Typography>
          {props.helpIndex && (
            <Tooltip title={help[props.helpIndex] || ''} className={props.type === "formType" ? classes.tooltipIntegration : classes.tooltip}>
              <HelpIcon fontSize="small" className={classes.iconColor} />
            </Tooltip>
          )}
        </div>
      </div>

      {props.title && (
        <SectionToolsWrapper
          defaultVisibility={props.defaultVisibility}
          toolsPosition={props.toolsPosition}
          onScan={props.onScan}
          scanBtnTitle={props.scanBtnTitle}
          onSearch={props.onSearch}
          onDownloadXLS={props.onDownloadXLS}
          onDownloadJSON={props.onDownloadJSON}
          onToggleVisibility={props.onToggleVisibility}
          visibilityTooltipText={props.visibilityTooltipText}
          isDownloadIcon={props.isDownloadIcon}
        />
      )}

      {props.children}
    </Paper>
  );
};

export default SectionWrapper;
