import React, {useEffect, useMemo, useRef, useState} from "react";
import useStyles from './owner-dropdown.styles'
import DropdownWrapper from "../../../../components/dropdown-wrapper";
import {Option} from "../../../../interfaces/common-intefaces";
import Button from "../../../../components/button";
import clsx from "clsx";
import RadioButtonGroup from "../../../../components/radio-button-group";
import {searchEmployees} from "../../../../redux/features/settings-store";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../interfaces/app-state-interface";
import Spinner from "../../../../components/spinner";
import ReactTooltip from "react-tooltip";
import {ReactComponent as SearchIcon} from "../../../../icons/search.svg";
import {ReactComponent as CreateIcon} from "../../../../icons/edit.svg";

interface OwnerDropdownProps {
  name: string;
  value: string;
  itemId?: string;
  onChange: (e: { target: { name: string, value: string } }) => void;
  options: Option[];
  copy?: boolean;
  error?: boolean;
  adopted?: boolean;
  className: string;
}

interface SearchResultEmployeesProps {
  name: string
  node_id: string
  role: string
  workEmail: string
}

function OwnerDropdown({ name, value, onChange, options, itemId, copy = false, error = false, adopted = false,  className }: OwnerDropdownProps) {
  const classes = useStyles()
  const ownerInputRef = useRef<HTMLInputElement>(null)
  const [opened, setOpened] = useState(false)
  const [searchOwner, setSearchOwner] = useState<string>()
  const [isLoadingResultEmployees, setIsLoadingResultEmployees] = useState<boolean>(false)
  const dispatch = useDispatch();
  const [hideTooltipPolicyName, setHideTooltipPolicyName] = useState(false)

  let resultSearchEmployees: Option[] = useSelector<AppState, SearchResultEmployeesProps[]>(
    (state) => state.settings.resultSearchEmployees
  ).map((item) => {
    return {label: item.name, value: item.name}
  });

  // const [resultEmployees, setResultEmployees] = useState<Option[]>([])

  function displayTextWidth(text: any, font: any): HTMLCanvasElement {
    // @ts-ignore
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function handleCell(target: any) {
    const parentWidth = target.currentTarget.offsetWidth
    if(parentWidth < displayTextWidth(target.currentTarget.firstChild.outerText, "normal 16px 'Inter', sans-serif ")) {
      setHideTooltipPolicyName(true)
    } else {
      setHideTooltipPolicyName(false)
    }
  }

  useEffect(() => {
    setIsLoadingResultEmployees(true)
    const delayDebounceFn = setTimeout(() => {
      if (searchOwner) {
        (async () => {
          dispatch(searchEmployees(searchOwner))
        })()
      }
    }, 2000)
    const delayDebounceSpinner = setTimeout(() => {
      setIsLoadingResultEmployees(false)
    }, 3000)
    return () => {
      clearTimeout(delayDebounceFn)
      clearTimeout(delayDebounceSpinner)
    }
  }, [searchOwner, dispatch])

  let displayValue = useMemo(() => {
    return options.find((option) => option.value === value)?.label
  }, [value, options])

  function toggleDropdown() {
    if(!opened && ownerInputRef.current !== null) {
      ownerInputRef.current.focus()
    }
    setOpened(prevState => !prevState)
  }

  function handleClose() {
    setOpened(false)
  }

  function handleChange({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) {
    setSearchOwner('')
    setOpened(false)
    onChange({ target: { name, value } })
    displayValue = options.find((option) => option.value === value)?.label
  }

  return (
    <DropdownWrapper
      className={classes.root}
      opened={!!searchOwner && opened}
      position={'center'}
      search={true}
      triggerEl={
        <Button
          className={clsx(classes.btnTrigger)}
          type={'icon'}>
          <div className={classes.wrapperInput} onClick={adopted ? undefined : toggleDropdown}>
            <input
              ref={ownerInputRef}
              autoComplete="off"
              type="text"
              name="owner"
              value={searchOwner}
              onChange={(e: any) => setSearchOwner(e.target.value)}
              className={clsx(classes.search, { [classes.inputText]: copy, [classes.errorName]: error })}
            />
            { copy ? <SearchIcon className={classes.iconSearch} /> : "" }
            {!opened && !searchOwner && <div className={clsx(classes.wrapperText, { [classes.wrapperTextPaddingLeft]: copy })} data-tip
                                                    data-for={'registerTip-' + itemId + (displayValue || 'Owner')} onMouseEnter={(e) => handleCell(e)}>
              <div className={className}>{displayValue || <div style={{color: "#B7C2CD"}}>Select Owner</div>}</div>
            </div>}
          </div>
          { hideTooltipPolicyName && <ReactTooltip id={'registerTip-' + itemId + (displayValue || 'Owner')} place="top" effect="solid">
            { displayValue }
          </ReactTooltip> }
          { adopted ? "" : copy ? '' : (!copy && opened) ? <SearchIcon /> : <CreateIcon fontSize="small" onClick={toggleDropdown} /> }
        </Button>
      }
      onClose={handleClose}>
      { isLoadingResultEmployees &&
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
      }
      {
        !isLoadingResultEmployees && resultSearchEmployees.length === 0 && <div className={classes.notResult}>No employees found</div>
      }
      { !isLoadingResultEmployees && resultSearchEmployees &&
        <RadioButtonGroup
          name={name}
          options={resultSearchEmployees}
          value={value}
          onChange={handleChange} />
      }
    </DropdownWrapper>
  )
}

export default OwnerDropdown
