import React from 'react';
import { Skeleton } from '@material-ui/lab';
import useStyles from "./table-inline-skeleton.styles";
import TableRow from "../../table/table-row";
import TableColumn from "../../table/table-column";

interface IProps {
  columns: number;
  rows: number;
}

const TableInlineSkeleton = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      {[...Array(props.rows)].map((_, rowIndex) => (
        <TableRow
          key={`skeleton-row-${rowIndex}`}>
          {[...Array(props.columns)].map((_, columnIndex) => (
            columnIndex === 0 ? <TableColumn
              flex={'1 0 calc(29% - 24px)'}
              className={classes.skeleton}
              key={`skeleton-column-${columnIndex}`}>
              <Skeleton animation="wave" />
              </TableColumn> : (columnIndex === 1) ? <TableColumn
                flex={'1 0 calc(20% - 24px)'}
                className={classes.skeleton}
                key={`skeleton-column-${columnIndex}`}>
                <Skeleton animation="wave" />
              </TableColumn> : (columnIndex === 2) ? <TableColumn
                flex={'1 0 calc(10% - 24px)'}
                className={classes.skeleton}
                key={`skeleton-column-${columnIndex}`}>
                <Skeleton animation="wave" />
              </TableColumn> : (columnIndex === 3) ? <TableColumn
                flex={'1 0 calc(20% - 24px)'}
                className={classes.skeleton}
                key={`skeleton-column-${columnIndex}`}>
                <Skeleton animation="wave" />
              </TableColumn>: <TableColumn
                flex={'1 0 calc(7% - 24px)'}
                className={classes.skeleton}
                key={`skeleton-column-${columnIndex}`}>
                <Skeleton animation="wave" />
              </TableColumn>

          ))}
          <TableColumn
            flex={'1 0 calc(7% - 24px)'}
            className={classes.skeleton}
            key={`skeleton-column-${rowIndex + Math.random()}`}>
            <Skeleton animation="wave" />
          </TableColumn>
          <TableColumn
            flex={'1 0 calc(7% - 24px)'}
            className={classes.skeleton}
            key={`skeleton-column-additional-${rowIndex + Math.random()}`}>
            <Skeleton animation="wave" />
          </TableColumn>
        </TableRow>
      ))}
    </>
  );
};

export default TableInlineSkeleton;
