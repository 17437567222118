import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  subTabs: {
    order: 1,
    '@media (max-width:900px)': {
      overflow: 'scroll',
      width: "100%",
      order: 2,
      "&::-webkit-scrollbar": {
        display: "none",
      }
    },
    "& button": {
      paddingBottom: "25px",
      paddingTop: "25px",
    }
  },
  buttons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: "12px",
    order: 2,
    '@media (max-width:900px)': {
      width: "100%",
      order: 1,
      flexDirection: "column",
      "& button": {
        marginBottom: '5px',
        marginLeft: 0,
      }
    }
  },
  btnDownload: {
    paddingLeft: 24,
    marginTop:'10px',
    '& svg': {
      marginRight: 8
    }
  },
  btnAdd: {
    marginLeft: "8px",
    marginTop:'10px',
    '& span': {
      marginRight: 6,
      fontSize: 21,
      fontWeight: 500
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      width: 22,
      height: 22,
      borderTopColor: 'white',
    }
  },
  tooltip: {
    color: (theme.palette as any).gunSmoke.main,
  },
  header: {
    display: "flex",
    alignItems: "center",
    '@media (max-width:900px)': {
      flexDirection: "column",
    }
  },
  btnDownloadWrapper:{
    position: "relative",
    "& ul":{
        position: "absolute",
        background: "#fff",
        width: "150px",
        listStyle: "none",
        padding: 0,
        boxShadow: "0px 0px 8px rgb(90 108 118 / 15%)",
        borderRadius: "10px",
        top: "31px",
        display: "none",
    },
    "& li":{
      padding: "10px 8px",
      textAlign: "center",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#61778B",
    },
    "& li span":{
      position: "relative",
      top: "3px",
    left: "-2px",
    }
  }
}));
