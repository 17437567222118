import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { listToken, setIsTokenValid } from "redux/features/auth-store";
import { getCode } from "utils/auth-utils";

// const TOKEN = "eyJraWQiOiJ2U2VZRkpmTkdhRjZGTklvNzFIZzZiNitjbW9mWWYyaHN2TTFFdTlQaGZFPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoienNRUW5pb09WbDBPMEFaQVZtVFZ3USIsInN1YiI6ImNkNzZiZDU4LWZlMzAtNDY5OS1hOWVmLTIwMGM1YTlkZDAzYSIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX002djhRUWVkaSIsImNvZ25pdG86dXNlcm5hbWUiOiJzYWdhciIsIm9yaWdpbl9qdGkiOiI3ZWZlYTAyZi1mOWRjLTRhYjQtODdkOS0wYTg2MTNiZDA0NjciLCJhdWQiOiJkZHV1YmRzNzloNnI1NGJrbG9rcWxha3J1IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NDkzMjkwNTIsInBob25lX251bWJlciI6Iis5MTg4ODg5OTk5OCIsImV4cCI6MTY0OTMzMjY1MiwiaWF0IjoxNjQ5MzI5MDUyLCJqdGkiOiJlYzQ3NWVkZi1iYzBlLTRiNGItODQ0NC00N2QyYjRhYWE1OWYiLCJlbWFpbCI6InJhdmlAY2xvdWRyZW1lZHkuaW8ifQ.iMDUgzxVQ7sUP7yT9WMrOijOf0kwdqep-rlS7QsVmj2qdgWildsE3xAjSygSmJLMY38rLU9r4yxh0FetyUeFrU1R9mbxuV2Xry4AnJy3zMiWpyV1W88sCHGcyi9Jsw9BK9AhrqQnRDANX9ffEBcm0p4VdGl8zZEykOovzptoAFYIWKQVHiuIIucUGCE4F9QumbVp-OJN-MlcA2cRKMKORf3-LSqbox0ZLlIkFUTw-CltJG_g56Dnp5buyohV9fnLx0dJRAXe1gPjKIBN5owjo3Z0AL0yqLdBqh4p9JRMPWHbEnoRIa4eyNLJpl5M07eMzPtzdF3lwUWZaXqRsfnlFA"

const useAuthToken = () => {
  //redux
  const dispatch = useDispatch();

  const onPageInit = async () => {
    dispatch(setIsTokenValid(await isTokenValid()));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
  // localStorage.setItem("token", TOKEN)
  const isTokenValid = async (): Promise<boolean> => {
    // localStorage.setItem('token', TOKEN)
    const token = localStorage.getItem("token");

    if (token === null) {
      const code = getCode();

      if (code) {
        await dispatch(listToken(code));
      } else {
        return false;
      }
    }

    return true;
  };
};

export default useAuthToken;
