import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  checkbox: {
    padding: "10px 17px",
  },
  wrapperText: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  btnTrigger: {
    position: "relative",
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#1B2E3F',
      textAlign: 'left',
      width: "100%",
    },
    '& span': {
      fontWeight: 600,
      color: '#99A9B8',
      fontSize: 14,
      lineHeight: '14px',
      background: 'rgba(183, 194, 205, 0.2)',
      borderRadius: 4,
      padding: '3px 4px',
      marginLeft: 8
    },
    '& svg': {
      minWidth: 24,
      marginLeft: 8,
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)'
    }
  },
  grayText: {
    "& p": {
      color: "rgb(183, 194, 205)",
    }
  },
  btnTriggerActive: {
    '& svg': {
      transform: 'rotate(-180deg)'
    }
  }
}));
