import React from 'react'

import Table from "components/table";
import TableRow from "components/table/table-row";
import { TableRowProps } from "components/table/table-row/table-row";
import TableColumn from "components/table/table-column";

import { ReactComponent as Checkmark } from 'icons/checkmark.svg';
import { ReactComponent as TriangleMark } from 'icons/triangleMark.svg';

import { TColumn } from 'interfaces/table-interface';
import { InventoryWorkstations } from 'interfaces/inventory-interface';
import useStyles from './inventoryWorkstations.styles'
import { Avatar } from '@material-ui/core';

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'uniqueID',
    title: 'Unique ID'
  },
  {
    flex: '1 0 calc(18% - 24px)',
    field: 'os',
    title: 'O.S.'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'antiVirus',
    title: 'Anti Virus',
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'osPatching',
    title: 'O.S. Patching'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'diskEncryption',
    title: 'Disk Encryption',
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'assignedTo',
    title: 'Assigned To'
  },
]

interface TableItemProps extends TableRowProps {
  item: any,
  SearchValue: string,
  textValue: any,
}

const TableItem = ({ item, SearchValue, textValue, ...otherProps }: TableItemProps) => {
  const classes = useStyles();
  let shortName;
  if(item.assigned_to){
    const fullName = item.assigned_to && item.assigned_to.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    shortName = initials.toUpperCase();
  }
  
  
  return (
    <TableRow {...otherProps}>
      <TableColumn className={[classes.nameCol].join(" ")}>
        <div className={classes.noWrap}>
          <p>{textValue(item.workstation_id)}</p>
        </div>
      </TableColumn>
      <TableColumn className={[classes.colName].join(" ")}>
        <div className={classes.noWrap}>
          <p>{textValue(item.operating_system)}</p>
        </div>
      </TableColumn>
      <TableColumn className={[classes.colName].join(" ")}>
        <div className={[classes.noWrap,classes.mfaStatus].join(" ")}>
          <p><span className="mfaWrapperP">
            {item.anti_virus_status === 'unavailable' ? 
              <span className='notAplicable'><span>!</span> <TriangleMark /></span> 
              : item.anti_virus_status === 'compliant' ?
                <span className='mfaSuccess'><Checkmark /></span> 
              : null
            }</span>
          </p>
        </div>
      </TableColumn>
      <TableColumn className={[classes.colName].join(" ")}>
        <div className={[classes.noWrap,classes.mfaStatus].join(" ")}>
          <p><span className="mfaWrapperP">
            {item.os_patching_status === 'at_risk' ? 
              <span className="mfaComplaint">!</span> 
              : null
            }</span>
          </p>
        </div>
      </TableColumn>
      <TableColumn className={[classes.colName].join(" ")}>
        <div className={[classes.noWrap,classes.mfaStatus].join(" ")}>
          <p><span className="mfaWrapperP">
            {item.disk_encryption_status === 'non_compliant' ? 
              <span className="mfaComplaint">!</span> 
              : item.disk_encryption_status === 'not_applicable' ? 
              <span className='notAplicable'><span>!</span> <TriangleMark /></span> 
              : item.disk_encryption_status === 'in_progress' ?  
              <span className='mfaSuccess'><Checkmark /></span> 
              : null
            }</span>
          </p>
      </div>
      </TableColumn>
      <TableColumn className={[classes.colName].join(" ")}>
        <div className={[classes.noWrap,classes.nameCol].join(" ")}>
          {item.assigned_to ? 
            <p>
              {/* <span className={classes.icon}><DpIcon /></span> */}
              <Avatar className={classes.Icon}>{shortName}</Avatar>
                {textValue(item.assigned_to)}
            </p>
          : null}
        </div>
      </TableColumn>
    </TableRow>
  )
}

const InventoryTestEmployee = ({ data, SearchValue, isLoading, textValue }: { data: InventoryWorkstations[], SearchValue: string, textValue: any, isLoading: boolean }) => {
  const rowRenderer = (item: any) => {
    return (
      <TableItem key={item.workstation_id} SearchValue={SearchValue} textValue={textValue} item={item} />
    )
  }
  return (
    <Table
      columns={tableColumns}
      data={data.filter((d: any) => 
        d.workstation_id.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1 ||
        (d.operating_system && d.operating_system.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1) ||
        (d.assigned_to && d.assigned_to.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1)
      )}
      isLoading={isLoading}
      rowRenderer={rowRenderer}
    />
  )
}
export default InventoryTestEmployee;