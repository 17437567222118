import React from "react";
import useStyles from './checkbox.styles'
import { ReactComponent as CheckMarkIcon } from 'icons/checkmark.svg'
import clsx from "clsx";

interface CheckboxProps {
  className?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  tab?: string;
}

function Checkbox({ className, checked, onChange, name, label, tab = '' }: CheckboxProps) {
  const classes = useStyles()

  return (
    <label className={clsx(className, classes.root, { [classes.disabled]: tab === 'retired' })}>
      <div className={clsx(classes.checkmark, { [classes.checked]: checked })}>
        <CheckMarkIcon />
      </div>
      <p>{ label }</p>
      <input type="checkbox" name={name} checked={checked} onChange={onChange} />
    </label>
  )
}

export default Checkbox
