import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, TextField, FormControlLabel, RadioGroup, Radio, Checkbox,IconButton } from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Switcher from "../../../../components/switcher";
import useStyles from "./vendor-form.style";
import { dateToString } from "../../../../utils/text-utils";
import TaskOwnerDropdown from '../taskOwner-dropdown';
import lodash from "lodash";
import { MenuItem } from "@material-ui/core";
import { AppState } from "interfaces/app-state-interface";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "interfaces/common-intefaces";
import { DeletedVendorsform, UpdateVendorsform, SwitchVendorsform, AddVendorsdata, setSingleVendorsData, fetchVendorTests } from "redux/features/settings-store";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import DatePicker from 'components/date-picker';
import { Add, Remove } from '@material-ui/icons';

export interface VendorsFormProps {
  setFormView: any;
}

const VendorForm = ({ setFormView }: VendorsFormProps) => {
  const singleVendordata = useSelector<AppState, any>(
    (state) => state.settings.singleVendor
  );

  const classes = useStyles();
  const [enableChecked, setEnableChecked] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState(dateToString(new Date()));
  const [taskOwner, setTaskOwner] = useState('');
  const [createThreshold, setCreateThreshold] = useState(0);
  const [form, setForm] = React.useState({});
  const [riskThreshold, setRiskThreshold] = useState(0);
  const [frequency, setFrequency] = useState('');
  const [handlescustomer, setHandlesCustomer] = useState<boolean>(false);
  const [vendorname, setVendorname] = useState('');
  const [vendorwebsite, setVendorwebsite] = useState('');
  const [pointofcontact, setPointofcontact] = useState('');
  const [riskrating, setRiskrating] = useState('');
  const [link, setLink] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const isEnabled = vendorname && pointofcontact && riskrating && taskOwner && createThreshold && riskThreshold && frequency;

  const ownersOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.employees.map(({ name, node_id }) => ({
      label: name,
      value: node_id
    }))
  );

  useEffect(() => {
    if (singleVendordata) {
      setForm(singleVendordata);
      if (singleVendordata.vendor_name) setVendorname(singleVendordata.vendor_name);
      if (singleVendordata.vendor_website) setVendorwebsite(singleVendordata.vendor_website);
      if (singleVendordata.point_of_contact) setPointofcontact(singleVendordata.point_of_contact);
      if (singleVendordata.handles_customer_data) setHandlesCustomer(singleVendordata.handles_customer_data === 'true' ? true : false);
      if (singleVendordata.risk_rating) setRiskrating(singleVendordata.risk_rating);
      if (singleVendordata.due_date) setDueDate(singleVendordata.due_date);
      if (singleVendordata.employee_id) setTaskOwner(singleVendordata.employee_id);
      if (singleVendordata.create_threshold) setCreateThreshold(parseInt(singleVendordata.create_threshold) || 0);
      if (singleVendordata.risk_threshold) setRiskThreshold(parseInt(singleVendordata.risk_threshold) || 0);
      if (singleVendordata.frequency) setFrequency(singleVendordata.frequency);
      if (singleVendordata.share_upload_link) setLink(singleVendordata.share_upload_link === 'true' ? true : false);
      if (singleVendordata.status) setEnableChecked(singleVendordata.status === 'active' ? true : false);
    }
  }, [singleVendordata, dispatch])

  const option = [
    {
      value: 'Annually',
      label: 'Annually'
    },
    {
      value: 'Semi-Annually',
      label: 'Semi-Annually',
    },
    {
      value: 'Quarterly',
      label: 'Quarterly',
    },
    {
      value: 'Monthly',
      label: 'Monthly',
    }];

  const RiskRating = [
    {
      value: 'High',
      label: 'High',
    },
    {
      value: 'Medium',
      label: 'Medium',
    },
    {
      value: 'Low',
      label: 'Low',
    },
  ]
  
  async function handleChange({ target: { name, value } }: { target: { name: string, value: any } }) {
    setForm(lodash.pickBy({ ...form, [name]: value }, function (value, key) {
      return !(value === false || value === "");
    }));
    
    if (name === 'vendor_name') {
      setVendorname(value)
    }
    if (name === 'vendor_website') {
      setVendorwebsite(value)
    }
    if (name === 'point_of_contact') {
      setPointofcontact(value)
    }
    if (name === 'handles_customer_data') {
      setHandlesCustomer(value);
      let formData: any = { ...form };
      if (value === true) {
        formData[name] = true;
        setForm(formData);
      } else if (value === false) {
        formData[name] = false;
        setForm(formData);
      }
    }
    if (name === 'risk_rating') {
      setRiskrating(value)
    }
    if (name === 'due_date') {
      setDueDate(value)
    }
    if (name === 'employee_id') {
      setTaskOwner(value)
    }
    if (name === 'create_threshold') {
      setCreateThreshold(parseInt(value))
    }
    if (name === 'risk_threshold') {
      setRiskThreshold(parseInt(value))
    }
    if (name === 'frequency') {
      setFrequency(value)
    }
  };

  const backButton = () => {
    setFormView(false);
    dispatch(setSingleVendorsData(''))
  };

  const onChangeSwitch = (e: any) => {
    setEnableChecked(e.target.value);
    if (e.target.value === true) {
      dispatch(SwitchVendorsform(singleVendordata.vendor_id, 'enable'))
      setForm(lodash.pickBy({ ...form, [e.target.name]: 'active' }));
    } else {
      dispatch(SwitchVendorsform(singleVendordata.vendor_id, 'disable'))
      setForm(lodash.pickBy({ ...form, [e.target.name]: 'inactive' }));
    }
  };

  const onChangeDelete = async () => {
    await dispatch(DeletedVendorsform(singleVendordata.vendor_id))
    dispatch(fetchVendorTests())
    backButton();
  };

  const handleSubmit = (value: any) => {
    if (singleVendordata) {
      dispatch(UpdateVendorsform(singleVendordata.vendor_id, form));
      backButton();
    } else {
      dispatch(AddVendorsdata(form));
    }
    setTimeout(() => {
      dispatch(fetchVendorTests())
    }, 3000)
  }

  const checkbox = (e: any) => {
    let formData: any = { ...form };
    setLink(e.target.checked)
    if (e.target.checked) {
      formData[e.target.name] = true;
      setForm(formData);
    } else {
      formData[e.target.name] = false;
      setForm(formData);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainClassVenderForm}>
      <div className={classes.mainClassVenderFormTpContent}>
        <Grid item md={12} className={classes.alignElement}>
          <Typography variant="body2" className={classes.backButton}>
            {" "}
            <Button onClick={backButton} variant="outlined" className={classes.backButtonEle} >
              <ArrowBackIosIcon fontSize="small" className={classes.backIcon} />{" "}
              Back{" "}
            </Button>
          </Typography>
          <Grid item md={12}>
            <Grid item sm={12} md={12} className={classes.disableButtonGrid}>
              <Grid
                container
                item
                sm={6}
                md={3}
                alignItems="center"
                className={classes.leftGrid}
              >
              </Grid>
              <Grid
                container
                item
                sm={6}
                md={9}
                alignItems="flex-start"
                className={classes.rightGrid}
              >
                <Grid container item sm={6} md={12} alignItems="center">
                  <Typography variant="body2" className={classes.serviceTitle}>
                    {vendorname !== '' ? vendorname : 'Add New Vendor'}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  sm={6}
                  md={12}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      label={enableChecked ? "Enable" : "Disable"}
                      labelPlacement="end"
                      className={classes.activeSwitch}
                      control={
                        <Switcher
                          value={enableChecked}
                          onChange={onChangeSwitch}
                          name="status"
                          className={classes.status}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleClickOpen}
                      color="secondary"
                      className={classes.DeleteBtn}>Delete</Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{"CONFIRMATION"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to Delete ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={onChangeDelete} color="primary" autoFocus>
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className={classes.alignElement}>
          <Typography variant="body2" className={classes.formTitle}>
            Vendor
          </Typography>
        </Grid>
      </div>
      <form onSubmit={(e) => { e.preventDefault() }}>
        <Grid container alignItems="center" className={classes.alignLeft}>
          <Grid
            container
            alignItems="center"
            className={classes.mainBoxContent}
          >
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Vendor Name
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <TextField
                size="small"
                placeholder="Vendor Name"
                className={classes.InputField}
                variant="outlined"
                fullWidth
                value={vendorname}
                name="vendor_name"
                onChange={handleChange}
                error={vendorname === ''}
                helperText={vendorname === '' ? '* required' : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Vendor Website
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <TextField
                size="small"
                placeholder="Vendor Website"
                className={classes.InputField}
                variant="outlined"
                fullWidth
                value={vendorwebsite}
                name="vendor_website"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Point of Contact
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <TextField
                size="small"
                placeholder="example@gmail.com"
                className={classes.InputField}
                variant="outlined"
                fullWidth
                type={"mail"}
                value={pointofcontact}
                name="point_of_contact"
                onChange={handleChange}
                error={pointofcontact === ''}
                helperText={pointofcontact === '' ? '* required' : ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Handles Customer Data
              </Typography>
            </Grid>
            <Grid item sm={10} className={classes.statusActiveBtn}>
              <Switcher
                className={classes.switch}
                name="handles_customer_data"
                value={handlescustomer}
                onChange={handleChange}
                label={handlescustomer ? "yes" : "no"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>Risk Rating</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl component="fieldset" fullWidth={true} error={riskrating === '' ? true : false}>
                <RadioGroup row onChange={handleChange} className={classes.radiogroup} name="risk_rating" value={riskrating}>
                  {RiskRating.map((radio) =>
                    <FormControlLabel
                      value={radio.value}
                      control={<Radio color="secondary" />}
                      label={radio.label}
                      labelPlacement="end"
                    />
                  )}
                </RadioGroup>
                <FormHelperText style={{color: "#F05E5E", fontSize: '0.75rem', fontWeight: 400}}>{riskrating === '' ? '* Please select an option.' : null}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Next Report Due Date
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <DatePicker
                className={classes.date}
                name="due_date"
                adopted={false}
                value={dueDate}
                onChange={handleChange}
                showIcon={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="body2" className={[classes.connTitle2, classes.mainCommonLabel].join(' ')}>
                Tracking & Timelines
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Task Owner
              </Typography>
            </Grid>
            <Grid item sm={10}>
              <TaskOwnerDropdown
                className=''
                name={"employee_id"}
                value={taskOwner}
                itemId={"2"}
                onChange={handleChange}
                options={ownersOptions}
                required={taskOwner === ''? true : false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Create Card/Ticket
              </Typography>
            </Grid>
            <Grid item sm={10} className={classes.ticket}>
              <FormControl>
                <Grid className={classes.cardPlusMinus} >
                  <IconButton
                    className={classes.plusMinusbutton}
                    name="create_threshold"
                    disabled={createThreshold <= 0}
                    size="small"
                    onClick={() => {
                      setCreateThreshold(createThreshold - 1);
                      setForm(lodash.pickBy({ ...form, 'create_threshold': createThreshold - 1 }));
                    }}
                  >
                    <Remove />
                  </IconButton>
                  <TextField
                    className={classes.riskthresholdtext}
                    name="create_threshold"
                    value={createThreshold}
                    onChange={handleChange}
                    variant="standard"
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <IconButton
                    className={classes.plusMinusbutton}
                    name="create_threshold"
                    disabled={createThreshold > 364}
                    size="small"
                    onClick={() => {
                      setCreateThreshold(createThreshold + 1);
                      setForm(lodash.pickBy({ ...form, 'create_threshold': createThreshold + 1 }));
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
                <FormHelperText style={{color: "#F05E5E", fontSize: '0.75rem', fontWeight: 400}}>{createThreshold === 0 ? '* required' : null}</FormHelperText>
              </FormControl>
              <Typography className={classes.text}>
                {" "}
                Days Before Due Date
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                Mark At-Risk
              </Typography>
            </Grid>
            <Grid item sm={10} className={classes.ticket}>
              <FormControl>
                <Grid className={classes.risk}>
                  <IconButton
                    className={classes.plusMinusbutton}
                    disabled={riskThreshold <= 0}
                    name="risk_threshold"
                    size="small"
                    onClick={() => {
                      setRiskThreshold(riskThreshold - 1);
                      setForm(lodash.pickBy({ ...form, 'risk_threshold': riskThreshold - 1 }));
                    }}
                  >
                    <Remove />
                  </IconButton>
                  <TextField
                    className={classes.riskthresholdtext}
                    name="risk_threshold"
                    value={riskThreshold}
                    onChange={handleChange}
                    variant="standard"
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <IconButton
                    className={classes.plusMinusbutton}
                    name="risk_threshold"
                    disabled={riskThreshold > 364}
                    size="small"
                    onClick={() => {
                      setRiskThreshold(riskThreshold + 1);
                      setForm(lodash.pickBy({ ...form, 'risk_threshold': riskThreshold + 1 }));
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
                <FormHelperText style={{color: "#F05E5E", fontSize: '0.75rem', fontWeight: 400}}>{riskThreshold === 0 ? '* required' : null}</FormHelperText>
              </FormControl>
              <Typography className={classes.text}>
                Days Before Due Date
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={2}>
              <Typography className={[classes.label, classes.commonLabel, classes.followup].join(' ')}>
                Follow Up
              </Typography>
            </Grid>
            <Grid item sm={10} className={classes.statusActiveBtn}>
              <Checkbox
                checked={link}
                onChange={checkbox}
                name="share_upload_link"
                className={classes.checkbox}
                value={link}
              />
              <Typography className={classes.checkbox}> Send file upload link to vendor</Typography>
            
            <Grid container sm={10} style={{paddingTop: "10px"}}>
                <Typography className={[classes.commonLabel, classes.frequency].join(' ')}>
                  Frequency
                </Typography>
                <TextField
                  size="small"
                  select
                  name="frequency"
                  className={classes.InputField3}
                  variant="outlined"
                  fullWidth
                  value={frequency}
                  onChange={handleChange}
                  error={frequency === ''}
                  helperText={frequency === '' ? '* required' : ''}
                >
                  {option.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12} md={2} className={classes.saveBtnWrapper}>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                color="primary"
                className={classes.saveConnectBtn}
                onClick={handleSubmit}
                disabled={!isEnabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} />
        </Grid>
      </form>
    </div>
  )

}

export default VendorForm;