import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expandedRowWrapper: {
    padding: theme.spacing(10),
    background: '#F0FBF9',
  },
  expandedRowContainer: {
    backgroundColor: theme.palette.common.white
  },
  withBorder: {
    /* border: `solid 1px ${(theme.palette as any).alto.main}` */
  },
  containerPadding: {
    padding: theme.spacing(6, 5),
  },
  headingStyle: {
    color: '#61778B',
    fontWeight: 600,
    fontSize: '14px',
    marginBottom: '8px',
    display: 'block',
    textTransform: 'uppercase',
    fontFamily: 'Inter'
  },
  bodyText: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: '14px',
    color: '#1B2E3F',
  }
}));
