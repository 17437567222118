import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  btnAction: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0
    },
    '& p': {
      marginLeft: 16,
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#61778B',
    }
  },
  iconContainer: {
    minWidth: 44,
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  }
}));
