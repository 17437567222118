import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  textChartContainer: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70%",
    width: "100%",
    marginTop: "15px",
    color: (theme.palette as any).oxfordBlue.main,
    [theme.breakpoints.down("xs")]: {
      height: "initial",
    },
    '& h3':{
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "39px",
      color: "#1B2E3F",
    }
  },
}));
