import React, {ChangeEvent, useRef, useState} from 'react'
import useStyles from './actions-dropdown.styles'
import DropdownWrapper from "../../../../components/dropdown-wrapper";
import Button from "../../../../components/button";
import { ReactComponent as EllipsisIcon } from "icons/ellipsis.svg";
import { ReactComponent as UploadIcon } from "icons/upload-gray.svg";
import Switcher from "../../../../components/switcher";

interface ActionsDropdownProps {
  status?: boolean;
  onChange?: (e: { target: { name: string, value: any } }) => void;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void
}

function ActionsDropdown({ status = false, onChange = () => {}, onFileChange }: ActionsDropdownProps) {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)
  const inputRef = useRef(null)

  function toggleDropdown() {
    setOpened(prevState => !prevState)
  }

  function handleStatusClick() {
    onChange({ target: { name: 'status', value: !status } })
  }

  function handleUploadClick() {
    // @ts-ignore
    inputRef.current.click()
  }

  return (
    <DropdownWrapper
      opened={opened}
      onClose={() => setOpened(false)}
      position={'right'}
      triggerEl={
        <Button onClick={toggleDropdown} type={'icon'}>
          <EllipsisIcon />
        </Button>
      }>
      {/*<div className={classes.btnAction} role="button">*/}
      {/*  <div className={classes.iconContainer}>*/}
      {/*    <InfoIcon />*/}
      {/*  </div>*/}
      {/*  <p>*/}
      {/*    Information*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div onClick={handleStatusClick} className={classes.btnAction} role="button">
        <div className={classes.iconContainer}>
          <Switcher name="status" value={!status} />
        </div>
        <p>
          Retire
        </p>
      </div>
      <div onClick={handleUploadClick} className={classes.btnAction} role="button">
        <div className={classes.iconContainer}>
          <UploadIcon />
        </div>
        <p>
          Upload
        </p>
      </div>
      <input
        className={classes.input}
        accept=".txt,.pdf"
        ref={inputRef}
        name="file"
        type="file"
        value=""
        onChange={onFileChange} />
    </DropdownWrapper>
  )
}

export default ActionsDropdown
