import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 44,
    minWidth: 44,
    height: 26,
    border: 'none',
    outline: 'none',
    backgroundColor: '#B7C2CD',
    transition: 'background-color 300ms cubic-bezier(0, 0, 0, 1)',
    borderRadius: '30px',
    display: 'block',
    cursor: 'pointer',
    '& span': {
      position: 'absolute',
      display: 'block',
      width: 20,
      height: 20,
      background: '#fff',
      left: 3,
      top: 3,
      borderRadius: '50%',
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)',
    }
  },
  active: {
    backgroundColor: '#33CCA8',
    '& span': {
      transform: 'translateX(18px)'
    }
  }
}));
