import React from 'react';
import { Grid, useTheme } from '@material-ui/core';

import { LineChartValues } from 'interfaces/charts-interface';
import { FetchHook } from 'interfaces/hooks-interface';

import ViolationSummaryChartWrapper from 'components/violation-summary-chart-wrapper';
import useStyles from './summary-by-date.styles';

interface IProps {
  summary: FetchHook<LineChartValues>;
}

const SummaryByDate = ({ summary }: IProps) => {
  const theme = useTheme();
  const chartValues = summary.data;
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
        <ViolationSummaryChartWrapper
          title="HIGH"
          helpIndex="dashboard/highTrend"
          textColor={theme.palette.error.main}
          severityCount={chartValues.High}
          chartValues={chartValues.highSeverity}
          loading={summary.loading}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
        <ViolationSummaryChartWrapper
          title="MED."
          helpIndex="dashboard/mediumTrend"
          textColor={(theme.palette as any).yellow.main}
          severityCount={chartValues.Medium}
          chartValues={chartValues.mediumSeverity}
          loading={summary.loading}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
        <ViolationSummaryChartWrapper
          title="LOW"
          helpIndex="dashboard/lowTrend"
          textColor={(theme.palette as any).blue.main}
          severityCount={chartValues.Low}
          chartValues={chartValues.lowSeverity}
          loading={summary.loading}
        />
      </Grid>
    </>
  );
};
export default SummaryByDate;
