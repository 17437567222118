import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import clsx from "clsx";
import useStyles from "./AddEditForm.style";
import { Grid, Typography, Button, Box, TextareaAutosize } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { TextField, MenuItem } from "@material-ui/core";

import { dateToString } from "utils/text-utils";
// import SectionWrapper from "components/section-wrapper";
import Badge from "components/badge";
import DatePicker from "components/date-picker/date-picker";
import Switcher from "components/switcher";
import DropDownControl from "../DropDownControl";
import DropDownPolicy from "../DropDownPolicy";
import TagInputControl from "../TagInputControl";
import TaskOwnerDropdown from '../taskOwner-dropdown';

import { AppState } from "interfaces/app-state-interface";
import { Option } from "interfaces/common-intefaces";

import {
  setsingleControlTestData,
  updateControlTests,
  AddControlTest,
} from "redux/features/settings-store";

export interface AddEditFormProps {
  setFormView: any;
}

export default function AddEditForm({ setFormView }: AddEditFormProps) {
  const singleControlTestdata = useSelector<AppState, any>(
    (state) => state.settings.singleControlTest
  );
  const classes = useStyles();
  const [TestDescription, setTestDescription] = useState();
  const [Policy, setPolicy] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [TicketTitle, setTicketTitle] = useState();
  const [TicketDescription, setTicketDescription] = useState();
  const [Status, setStatus] = useState<boolean>(false);
  const [TaskOwner, setTaskOwner] = useState('');
  const [Controls, setControls] = useState<string[]>([]);
  const [DueDate, setDueDate] = useState(dateToString(new Date()));
  const [CreateThreshold, setCreateThreshold] = useState(0);
  const [RiskThreshold, setRiskThreshold] = useState(0);
  const [frequency, setFrequency] = useState('');
  const [form, setForm] = React.useState({});
  const dispatch = useDispatch();

  const option = [
    {
      value: 'Monthly',
      label: 'Monthly'
    },
    {
      value: 'Quarterly',
      label: 'Quarterly',
    },
    {
      value: 'Semi-Annually',
      label: 'Semi-Annually',
    },
    {
      value: 'Annually',
      label: 'Annually',
    }];

  const policyOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.activePolicies.map(({ policy_name }) => ({
      label: policy_name,
      value: policy_name
    }))
  );

  const ownersOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.employees.map(({ name, node_id }) => ({
      label: name,
      value: node_id
    }))
  );

  const SearchCompilanceData = useSelector<AppState, Option[]>(
    (state) => state.settings.searchCompilanceData.map(({ name }) => ({
      label: name,
      value: name
    }))
  );

  useEffect(() => {
    if (singleControlTestdata) {
      setForm(singleControlTestdata);
      setTestDescription(singleControlTestdata.test_description);
      setPolicy(singleControlTestdata.policies);
      setTags(singleControlTestdata.tags);
      setTicketTitle(singleControlTestdata.ticket_title);
      setTicketDescription(singleControlTestdata.ticket_description);
      setStatus(singleControlTestdata.status === 'active' ? true : false);
      setTaskOwner(singleControlTestdata.owner_id);
      setControls(singleControlTestdata.controls);
      if (singleControlTestdata.due_date) {
        setDueDate(singleControlTestdata.due_date);
      }
      setCreateThreshold(parseInt(singleControlTestdata.create_threshold));
      setRiskThreshold(parseInt(singleControlTestdata.risk_threshold));
      setFrequency(singleControlTestdata.frequency);
    }
  }, [singleControlTestdata, dispatch]);

  const backButton = () => {
    setFormView(false);
    dispatch(setsingleControlTestData(undefined));
  };

  const handleSubmit = async () => {
    if(singleControlTestdata){
    await dispatch(updateControlTests(singleControlTestdata.node_id,form));
    }else{
    await dispatch(AddControlTest(form));
    }
    backButton();
  }

  async function handleChange({ target: { name, value } }: { target: { name: string, value: any } }) {
    setForm(lodash.pickBy({ ...form, [name]: value }, function (value, key) {
      return !(value === false || value === "");
    }));
    if (name === 'test_description') {
      setTestDescription(value);
    }
    if (name === 'controls') {
      setControls(value);
    }
    if (name === 'policies') {
      setPolicy(value)
    }
    if (name === 'ticket_title') {
      setTicketTitle(value)
    }
    if (name === 'ticket_description') {
      setTicketDescription(value)
    }
    if (name === 'tags') {
      setTags(value)
    }
    if (name === 'owner_id') {
      setTaskOwner(value)
    }
    if (name === 'frequency') {
      setFrequency(value)
    }
    if (name === 'status') {
      setStatus(value);
      if (value === true) {
        setForm(lodash.pickBy({ ...form, [name]: 'active' }));
      } else if (value === false) {
        setForm(lodash.pickBy({ ...form, [name]: 'inactive' }));
      }
    }
    if (name === 'due_date') {
      setDueDate(value)
    }
    if (name === 'create_threshold') {
      setCreateThreshold(parseInt(value))
    }
    if (name === 'risk_threshold') {
      setRiskThreshold(parseInt(value))  
    }
  }

  const [exit] = React.useState(false);

  return (
    <div
      className={clsx(classes.animatedItem, {
        [classes.animatedItemExiting]: exit
      })}
    >
      <div className={classes.mainWrapperAddEdit}>
        <Grid item md={12} className={classes.alignElement}>
          <Typography variant="body2" className={classes.backButton}>
            {" "}
            <Button onClick={backButton} variant="outlined" className={classes.backButtonEle} >
              <ArrowBackIosIcon fontSize="small" className={classes.backIcon} />{" "}
              Back{" "}
            </Button>
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.alignElement}>
          <Typography variant="body2" className={classes.formTitle}>
            Add/Edit Control
          </Typography>
        </Grid>
      </div>
      <Box className={classes.divider} />
        <form onSubmit={(e) => { e.preventDefault() }} className={classes.form}>
          <Grid container alignItems="center" className={classes.alignLeft}>
            <Grid
              container
              alignItems="center"
              className={classes.mainBoxContent}
            >
              <Grid item md={12}>
                <Typography variant="body2" className={classes.connTitle}>
                  Control Attributes
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.controltxt, classes.commonLabel].join(' ')}>
                  {" "}
                  Control text
                </Typography>
                <div>
                  {singleControlTestdata ?
                    <Badge className={classes.badge}>{singleControlTestdata.source_type}</Badge>
                    : null}
                </div>
              </Grid>
              <Grid item sm={10} className={classes.controlTextBox}>
                <TextareaAutosize
                  maxRows={2}
                  onChange={handleChange}
                  aria-label='TestDescription'
                  placeholder='TestDescription'
                  name={"test_description"}
                  value={TestDescription}
                  className={classes.fieldWidthTextarea}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Standard & Control
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <div >
                  <DropDownControl
                    name="controls"
                    values={Controls}
                    itemId={"1"}
                    onChange={handleChange}
                    options={SearchCompilanceData} />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Associated Policy
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <div >
                  <DropDownPolicy
                    name="policies"
                    values={Policy}
                    itemId={"1"}
                    onChange={handleChange}
                    options={policyOptions} />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Card/Ticket Title
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <TextField
                  size="small"
                  placeholder="Card/Ticket Title"
                  className={classes.InputField}
                  variant="outlined"
                  fullWidth
                  value={TicketTitle}
                  name="ticket_title"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Card/Ticket Description
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <TextField
                  placeholder="Card/Ticket Description"
                  className={classes.InputField1}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  name="ticket_description"
                  value={TicketDescription}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>Tags</Typography>
              </Grid>
              <Grid item sm={10}>
                <div >
                  <TagInputControl
                    name="tags"
                    values={tags}
                    itemId={"1"}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Task Owner
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <TaskOwnerDropdown
                  className=""
                  name={"owner_id"}
                  value={TaskOwner}
                  itemId={"2"}
                  onChange={handleChange}
                  options={ownersOptions}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={12}>
                <Typography variant="body2" className={[classes.connTitle2, classes.mainCommonLabel].join(' ')}>
                  Timelines
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Due Date
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <DatePicker
                  className={classes.date}
                  name="due_date"
                  adopted={false}
                  value={DueDate}
                  onChange={handleChange}
                  showIcon={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Create Card/Ticket
                </Typography>
              </Grid>
              <Grid item sm={10} className={classes.ticket}>
                <Grid className={classes.cardPlusMinus} >
                  <Button
                    className={classes.plusMinusbutton}
                    name="create_threshold"
                    disabled={CreateThreshold <= 0}
                    onClick={() => {
                      setCreateThreshold(CreateThreshold - 1);
                      setForm(lodash.pickBy({ ...form, 'create_threshold': CreateThreshold - 1 }));
                    }}
                  >
                    -
                  </Button>
                  <TextField 
                   className={classes.riskthresholdtext}
                   name="create_threshold"
                   value={CreateThreshold} 
                   onChange={handleChange}
                   variant="standard"
                   type="number"
                   InputProps={{
                    disableUnderline: true,
                  }}
                   /> 
                  <Button
                    className={classes.plusMinusbutton}
                    name="create_threshold"
                    disabled={CreateThreshold > 364}
                    onClick={() => {
                      setCreateThreshold(CreateThreshold + 1);
                      setForm(lodash.pickBy({ ...form, 'create_threshold': CreateThreshold + 1 }));
                    }}
                  >
                    +
                  </Button>

                </Grid>
                <Typography className={classes.text}>
                  {" "}
                  Days Before Due Date
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} >
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Mark At-Risk
                </Typography>
              </Grid>
              <Grid item sm={10} className={classes.ticket}>
                <Grid className={classes.risk}>
                  <Button
                    className={classes.plusMinusbutton}
                    disabled={RiskThreshold <= 0}
                    name="risk_threshold"
                    onClick={() => {
                      setRiskThreshold(RiskThreshold - 1);
                      setForm(lodash.pickBy({ ...form, 'risk_threshold': RiskThreshold - 1 }));
                    }}
                  >
                    -
                  </Button>
                  <TextField 
                   className={classes.riskthresholdtext}
                   name="risk_threshold"
                   value={RiskThreshold} 
                   onChange={handleChange}
                   variant="standard"
                   type="number"
                   InputProps={{
                    disableUnderline: true,
                  }}
                   /> 

                  <Button
                    className={classes.plusMinusbutton}
                    name="risk_threshold"
                    disabled={RiskThreshold > 364}
                    onClick={() => {
                      setRiskThreshold(RiskThreshold + 1);
                      setForm(lodash.pickBy({ ...form, 'risk_threshold': RiskThreshold + 1 }));
                    }}
                  >
                    +
                  </Button>

                </Grid>
                <Typography className={classes.text}>
                  Days Before Due Date
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography className={[classes.label, classes.commonLabel].join(' ')}>
                  Frequency
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <TextField
                  size="small"
                  select
                  name="frequency"
                  className={classes.InputField3}
                  variant="outlined"
                  fullWidth
                  value={frequency}
                  onChange={handleChange}
                >
                  {option.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={2}>
                <Typography variant="body2" className={[classes.connTitle3, classes.mainCommonLabel].join(' ')}>
                  Status
                </Typography>
              </Grid>
              <Grid item sm={10} className={classes.statusActiveBtn}>
                <Switcher
                  className={classes.switch}
                  name="status"
                  value={Status}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} md={2} className={classes.saveBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                  className={classes.saveConnectBtn}
                  onClick={handleSubmit}
                //   disabled={()=> {}}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid item sm={12} md={6} />
          </Grid>
        </form>
        {/* </SectionWrapper> */}
    </div>
  );
}
