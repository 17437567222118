import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    marginRight: 24,
    '&:last-child': {
        marginRight: 0
    },
    '& p': {
      width: '100%',
      margin: 0
    }
  },
  left: {
    justifyContent: 'flex-start',
    '& > p': {
      textAlign: 'left'
    }
  },
  center: {
    justifyContent: "center",
    '& > p': {
      textAlign: 'center'
    }
  },
  right: {
    justifyContent: 'flex-end',
    '& > p': {
      textAlign: 'right'
    }
  },
  columnText: {
    "& > p": {
      textAlign: "center",
    }
  },
  noWrap: {
    '& p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  }
}));
