import React from 'react';
import { BarChart, Tooltip, ResponsiveContainer, Legend, XAxis, YAxis, Bar } from 'recharts';

import BarChartSkeleton from 'components/skeletons/charts-skeleton/bar-chart-skeleton';
import { MultiLineChartValue } from 'interfaces/charts-interface';
import { Severity } from 'interfaces/violations-interface';

import useStyles from './cloud-bar-chart.styles';
import ViolationSummaryChartTooltip from '../violations-summary-bar-chart/violation-summary-chart-tooltip';

interface IProps {
  values: MultiLineChartValue[];
  isLoading?: boolean;
  aspect: number;
}

const CloudBarChart = (props: IProps) => {
  const classes = useStyles();

  if (props.isLoading) {
    return <BarChartSkeleton numberOfBars={10} maxBarHeight={150} />;
  }

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }: any) => {
    return <text x={x + width - 20} y={y} fill="#FFFFFF" textAnchor="middle" dy={15}>{value}</text>;
  };

  return (
    <ResponsiveContainer aspect={props.aspect} width="100%"  >
      <BarChart data={props.values} layout="vertical" className={classes.barChart} width={500} height={500} >

        <XAxis type="number" hide  />
        <YAxis type="category" dataKey="shortName" domain={[0, 100]} axisLine={false} tickLine={false}/>

        <Legend wrapperStyle={{color: '#61778B'}} width={200} height={15} align='right' iconType="circle" iconSize={5} />
        <Tooltip content={<ViolationSummaryChartTooltip />} isAnimationActive={false} />

        <Bar
          dataKey={Severity.High}
          stackId="x"
          fill={"#F38F8F"}
          isAnimationActive={false}
          barSize={20}
          radius={[3, 0, 0, 3]}
          label={renderCustomBarLabel}
        >
          {/* <LabelList dataKey="name" position="right" /> */}
        </Bar>
        <Bar
          dataKey={Severity.Medium}
          stackId="x"
          fill={"#FFBC00"}
          isAnimationActive={false}
          barSize={20}
          label={renderCustomBarLabel}
        >
        </Bar>
        <Bar
          dataKey={Severity.Low}
          stackId="x"
          fill={"#33CCA8"}
          isAnimationActive={false}
          barSize={20}
          radius={[0, 3, 3, 0]}
          label={renderCustomBarLabel}
        >
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CloudBarChart;
