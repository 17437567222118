import {AxiosPromise} from "axios";

import {
    ControlTests,
    ControlTestsList,
    PolicyResponse,
    ControlIcon
} from "interfaces/settings-interface";
import { axiosIntegrationsInstance } from "./http-service";

export const listControlTests = (): AxiosPromise<
ControlTests
> => {
  return axiosIntegrationsInstance.get(`/settings-compliance/v1/control-tests/summary`);
};

export const listControlTestsConfig = ( query?: string): AxiosPromise<
ControlTestsList[]
> => {
  let url = `/settings-compliance/v1/control-tests`
  if(query) {
    url = `${url}?${query}`;
  }
  return axiosIntegrationsInstance.get(url);
};

export const putControlTests = (
  controlId:string,
  payload: any,
): AxiosPromise<any> => {
  return axiosIntegrationsInstance.put(`/settings-compliance/v1/control-tests/${controlId}`,{
    ...payload});
};

export const getResultsearchCompilance = (query: string): AxiosPromise => {
  return axiosIntegrationsInstance.get(`/v1/compliance/search?query=${query}`);
};

export const getActivePolicies = (): AxiosPromise<{
  policies: PolicyResponse[]
}> => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/policies?status=active");
};

export const getControlIcon = (query:string): AxiosPromise< ControlIcon[]> => {
  return axiosIntegrationsInstance.get(`integrations/v1/services-metadata/${query}`);
}

export const addControlTest = (payload:any) : AxiosPromise<any> => {
  return axiosIntegrationsInstance.post(`/settings-compliance/v1/control-tests`,payload);
};
