/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "./profile-settings-control-tests.styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Grid, LinearProgress } from "@material-ui/core";

import { AppState } from "interfaces/app-state-interface";
import { ControlTestsList, ControlTests } from "interfaces/settings-interface";
import SearchInput from "components/search-input";
import TabsWrapper from "components/tabs-wrapper";
import ControlTable from "./tables/control-table";
import AllControlTable from "./tables/allcontrols-table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    fetchControlTests,
    fetchControlTestsConfig,
    fetchEmployees,
    setHelpIndex,
    setControlFilterData,
    fetchControlIcon,
} from "redux/features/settings-store";
import AddEditForm from './AddEditForm/AddEditForm';

const ProfileSettingsControlTests = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [formview, setFormView] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [Loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState("active");
    const isLoading = useSelector<AppState, boolean>(
        (state) => state.ui.isLoading
    );
    const controlTests = useSelector<AppState, ControlTests>(
        (state) => state.settings.controlTests
    );
    const active = useSelector<AppState, ControlTestsList[]>(
        (state) => state.settings.control_tests_config.filter(({ status }: any) => status === 'active')
    );
    const inactive = useSelector<AppState, ControlTestsList[]>(
        (state) => state.settings.control_tests_config.filter(({ status }: any) => status === 'inactive')
    );
    const allData = useSelector<AppState, ControlTestsList[]>(
        (state) => state.settings.control_tests_config
    );
    const filterData = useSelector<AppState, ControlTestsList[]>(
        (state) => state.settings.control_filter_data
    );
    const tabs = [
        {
            label: 'Active',
            value: 'active',
            count: active.length
        },
        {
            label: 'InActive',
            value: 'inactive',
            count: inactive.length
        },
        {
            label: 'All Controls',
            value: 'allcontrols',
            count: (active.length + inactive.length)
        },
    ]

    const onPageInit = async () => {
        setLoading(true);
        dispatch(setHelpIndex("settings/controlTest"));
        dispatch(fetchControlTests());
        dispatch(fetchControlTestsConfig());
        dispatch(fetchControlIcon('Task Management'));
        dispatch(fetchEmployees());
        setLoading(false);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        onPageInit()
    }, [])
    /* eslint-enable react-hooks/exhaustive-deps */

    /* eslint-disable react-hooks/exhaustive-deps */
    // useEffect(() => {
    //     onPageInit()
    // }, [formview])
    /* eslint-enable react-hooks/exhaustive-deps */

    if (Loading) {
        return <LinearProgress data-testid="Linear-Progress"/>;
    }

    const Controls = Object.entries(controlTests).map(([keyindex, valueindex]) => {
        return { key: keyindex, value: valueindex }
    });

    const onSearch = (e: any) => {
        setSearchValue(e);
    }

    const controlTestBox = (control: { key: any; }) => {
        let title = '';
        switch (control.key) {
            case "active_control_tests":
                title = "Active Tests"
                break;
            case "automated_control_tests":
                title = "Automated Tests"
                break;
            case "unconfigured_control_tests":
                title = "Un-configured Tests"
                break;
            case "custom_control_tests":
                title = "Custom Tests"
                break;
            default:
                break;
        }
        return title;
    }

    const handleTabChange = async(tabs: any) => {
        // if(tabs === 'allcontrols'){
        //     dispatch(fetchControlTestsConfig());
        // }else{
        //    await dispatch(fetchControlTestsConfig(`status=${tabs}`));
        //     dispatch(fetchControlTestsConfig());
        // }
        setCurrentTab(tabs);
    }
    
    const handleBoxClick = (control: any) => {
        if(control.key === 'custom_control_tests'){
            if(currentTab === 'active'){
                dispatch(setControlFilterData(active.filter((item)=>  item.source_type === 'custom')));
            }else if(currentTab === 'inactive'){
                dispatch(setControlFilterData(inactive.filter((item)=>  item.source_type === 'custom')));
            }else if(currentTab === 'allcontrols'){
                dispatch(setControlFilterData(allData.filter((item)=>  item.source_type === 'custom')));
            }
        }else if(control.key === 'active_control_tests'){
            if(currentTab === 'active'){
                dispatch(setControlFilterData(active));
            }
        }else{
            dispatch(setControlFilterData([]));
        }
    }

    return (
        <Box className={classes.boxWrapper} >
        <Box m={20} mt={0} className={classes.boxWrapperInner}>
        {formview === false ?
            <div>
                {Controls.length !== 0 ?
                        <React.Fragment>
                            <Grid container spacing={10} alignItems="flex-start" justifyContent="flex-start" className={classes.gridBoxWrapperInner}>
                                {Controls.map((control: any, index: any) => {
                                    if (controlTestBox(control)) {
                                        return (
                                            <Grid item className={classes.gridItemBox} key={index}>
                                                <Box
                                                    key={index}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    className={clsx(classes.card)}
                                                    onClick={() => handleBoxClick(control)}
                                                >
                                                    <Grid container>
                                                        <Grid item md={12} className={classes.textTitle}>
                                                            <Typography variant="body2" className={classes.gridItemBoxContentTitle}>
                                                                {controlTestBox(control)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item md={12} className={classes.text}>
                                                            <Typography variant="body2" className={classes.gridItemBoxtext}>
                                                                {control.value}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        )
                                    }
                                })}
                                <Grid item className={classes.addGridItemBox} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        className={clsx(classes.card, classes.addcard)}
                                        onClick={()=>{setFormView(true)}}
                                    >
                                        <Grid container>
                                            <Grid item md={12} className={classes.textTitle1}>
                                                <AddCircleIcon className={classes.addIcon} />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item md={12} className={classes.text}>
                                                <Typography variant="body2" className={classes.addtext}>
                                                    {"Add Custom Test"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                : null}
                <div className={classes.paddingSpac}>
                    <Grid container alignItems="center">
                        <Grid item sm={8} xs={6} className={classes.header}>
                            <div className={classes.stepText}>
                                <TabsWrapper value={currentTab} tabs={tabs} className={classes.subTabs} onChange={(e: any)=> handleTabChange(e)} />
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={6} className={classes.search}>
                            {<SearchInput onChangeText={(e) => onSearch(e)} />}
                        </Grid>
                    </Grid>
                    {
                        currentTab === 'active' &&
                        <ControlTable isLoading={isLoading} data={filterData.length > 0 ? filterData : active} setFormView={setFormView} searchValue={searchValue}/>
                    }
                    {
                        currentTab === 'inactive' &&
                        <ControlTable isLoading={isLoading} data={filterData.length > 0 ? filterData : inactive} setFormView={setFormView} searchValue={searchValue}/>
                    }
                    {
                        currentTab === 'allcontrols' &&
                        <AllControlTable isLoading={isLoading} data={filterData.length > 0 ? filterData : allData} setFormView={setFormView} searchValue={searchValue} />
                    }
                    {/* </SectionWrapper> */}
                </div>
            </div>
        : null}
        {formview === true ?
            <React.Fragment>
                <AddEditForm setFormView={setFormView} />
            </React.Fragment>
        : null}
        </Box>
        </Box>
    );
};
export default ProfileSettingsControlTests;