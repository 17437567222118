import React from 'react'
import useStyles from './table-column.styles'
import {TColumn} from "interfaces/table-interface";
import clsx from "clsx";

function TableColumn({ className, maxWidth, minWidth, align = 'left', field, children, noWrap = true, flex, display, justifyContent }: TColumn) {
  const classes = useStyles()

  const columnStyle = {
    flex: flex || '1 0 0',
    maxWidth: maxWidth || 'inherit',
    minWidth: minWidth || 50,
    display,
    justifyContent,
    '@media (maxWidth:900px)': {
      flex: '1 0 0 !important',
    },
  }

  const columnClassNames = clsx(
    className,
    classes.root,
    classes[align],
    {
      [classes.columnText]: justifyContent,
      [classes.noWrap]: noWrap
    }
  )

  return (
    <div className={columnClassNames} style={columnStyle}>
      { children }
    </div>
  )
}

export default TableColumn
