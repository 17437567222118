import React, { useState, useEffect } from "react";
import { AlertProps } from "@material-ui/lab";

import Snackbar from "components/snackbar";

export type SnackbarsData = { text: string; severity?: AlertProps["severity"] };
export interface ISnackbars {
  [key: string]: SnackbarsData;
}

interface IProps {
  snackbars: ISnackbars;
  onClose: (key: string) => void;
}

const SnackbarContainer = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState<string>();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!open && Object.keys(props.snackbars).length > 0) {
      setTimeout(() => {
        const newKey = Object.keys(props.snackbars)[0];
        setSnack(newKey);
        setOpen(true);
      }, 100);
    }
  }, [props.snackbars]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onClose = () => {
    setOpen(false);
    props.onClose(snack!);
  };

  return (
    <>
      {snack && (
        <Snackbar
          open={open}
          message={props.snackbars[snack]?.text}
          type={props.snackbars[snack]?.severity}
          onClose={onClose}
          autoHideDuration={5000}
        />
      )}
    </>
  );
};

SnackbarContainer.defaultProps = {
  snackbars: {},
};

const SnackbarContainerWrapper = React.memo(
  SnackbarContainer,
  (prevProps: IProps, nextProps: IProps) => {
    const firstOldKey = Object.keys(prevProps.snackbars)[0];
    const firstNewKey = Object.keys(nextProps.snackbars)[0];
    return firstNewKey === firstOldKey;
  }
);

export default SnackbarContainerWrapper;
