import React from 'react'

import Table from "components/table";
import TableRow from "components/table/table-row";
import {TableRowProps} from "components/table/table-row/table-row";
import TableColumn from "components/table/table-column";

import useStyles from './inventoryEmployees.styles';
import {InventoryEmployees} from 'interfaces/inventory-interface';
import { TColumn } from 'interfaces/table-interface';

import { ReactComponent as Checkmark } from 'icons/checkmark.svg';
import { ReactComponent as LoaderIcon } from 'icons/loader.svg';
import { ReactComponent as TriangleMark } from 'icons/triangleMark.svg';
import { Avatar } from '@material-ui/core';

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(10% - 24px)',
    field: 'fullName',
    title: 'Full Name'
  },
  {
    flex: '1 0 calc(8% - 24px)',
    field: 'status',
    title: 'Status'
  },
  {
    flex: '1 0 calc(8% - 24px)',
    field: 'role',
    title: 'Role',
    //   display: 'flex',
    //   justifyContent: 'center',
  },
  {
    flex: '1 0 calc(8% - 24px)',
    //   align: 'center',
    field: 'orientation',
    title: 'Orientation'
  },
  {
    flex: '1 0 calc(8% - 24px)',
    //   align: 'center',
    field: 'backgroundCheck',
    title: 'Background Check',
    noWrap: false
  },
  {
    flex: '1 0 calc(10% - 24px)',
    //   align: 'center',
    field: 'annualPolicy',
    title: 'Annual Policy Acceptance'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    // align: 'center',
    field: 'annualTraining',
    title: 'Annual Training'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    // align: 'center',
    field: 'authentication',
    title: 'Multi-Factor Authentication'
  },
]

interface TableItemProps extends TableRowProps {
  item: any,
  SearchValue: string,
  textValue: any
}

const TableItem = ({item, SearchValue, textValue, ...otherProps}: TableItemProps) => {
  const classes = useStyles();
  let shortName;

  if(item.full_name){
    const fullName = item.full_name && item.full_name.split(' ');
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    shortName = initials.toUpperCase();
  }
  
return(
  <TableRow { ...otherProps }>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.nameCol].join(" ")}>
        <p>
          {/* <span className={classes.Icon}><DpIcon /></span> */}
          <Avatar className={classes.Icon}>{shortName}</Avatar>
            {textValue(item.full_name)}
        </p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.statusCol].join(" ")}>
        <p className={ item.employment_status === 'active' ? classes.activeStatus : item.employment_status === 'terminated' ? classes.terminatedStatus : classes.newStatus }>
          {textValue(item.employment_status)}
        </p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.roleColm].join(" ")}>
        <p>{textValue(item.role)}</p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.orienaTion].join(" ")}>
        <p><span>{<Checkmark />}</span></p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.backGroundCheck].join(" ")}>
        <p><span className={item.background_check_status}>
          {item.background_check_status === 'in_progress' ? 
            <LoaderIcon /> 
            : item.background_check_status === 'compliant' ? 
            <span><span>!</span><TriangleMark /></span>  
            : 'n/a'
          }</span>
          </p>
        </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.policy].join(" ")}>
        <p><span className="policyWrapperP">
          {item.policy_status === 'at_risk' ? 
            <span className="policySuccess"><Checkmark /></span> 
            : <span className='policyComplaint'>!</span> 
          }</span>
        </p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.annualTraining].join(" ")}>
        <p><span className="trainingWrapperP">
          {item.policy_status === 'at_risk' ? 
            <span className="trainingSuccess"><Checkmark /></span> 
            : <span className='trainingComplaint'>!</span> 
          }</span>
        </p>
      </div>
    </TableColumn>
    <TableColumn className={[classes.colName].join(" ")}>
      <div className={[classes.noWrap,classes.mfaStatus].join(" ")}>
        <p><span className="mfaWrapperP">
          {item.mfa_status === 'compliant' ? 
            <span className="mfaComplaint">!</span> 
            : item.mfa_status === 'not_applicable' ? 
            <span className='notAplicable'><span>!</span> <TriangleMark /></span> 
            : <span className='mfaSuccess'><Checkmark /></span> 
          }</span>
        </p>
      </div>
    </TableColumn>
  </TableRow>
  )
}

const InventoryTestEmployee = ({data, SearchValue, isLoading, textValue}: {data: InventoryEmployees[], SearchValue: string, isLoading: boolean, textValue: any }) => {
  const rowRenderer = (item: any) => {
    return (
      <TableItem key={item.employee_id} SearchValue={SearchValue} item={item} textValue={textValue}/>
    )
  }
  return (
    <Table
      columns={tableColumns}
      data={data.filter((d: any) => 
        d.full_name.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1 || 
        d.employment_status.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1 ||
        d.role.toUpperCase().indexOf(SearchValue.toUpperCase()) > -1
      )}
      isLoading={isLoading}
      rowRenderer={rowRenderer}
    />
  )
}
export default InventoryTestEmployee;