import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  btnTrigger: {
    width: '400px',
    padding: 0,
    fontFamily: `"Inter", sans-serif`,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1B2E3F',
    border: "1px solid rgba(183, 194, 205, 0.5)",
    position: "relative",
    background: "#FFFFFF",
    boxSizing: "border-box",
    marginTop: "35px",
    borderRadius: "8px 8px 8px 8px",
    '&:hover': {
      borderColor: "#1B2E3F",
    },
    '&:focus-within': {
      border: "2px solid #1B2E3F",
      borderColor: "#1B2E3F",
    },
    '& svg': {
      marginLeft: 8,
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)'
    }
  },
  wrapperText: {
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6px",
    textAlign: "left",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  wrapperTextPaddingLeft: {
    paddingLeft: "48px",
  },
  wrapperInput: {
    width: "100%",
    position: "relative",
    // marginBottom: "5px",
    // marginTop: "5px"
  },
  search: {
    font: "inherit",
    color: "currentColor",
    width: "100%",
    margin: 0,
    display: "block",
    padding: "10px 10px 10px",
    background: "none",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    "-webkit-tap-highlight-color": "transparent",
    border: "0",
    borderRadius: "8px 8px 8px 8px",
    '&:focus-visible': {
      outline: "-webkit-focus-ring-color auto 0px !important",
  },
  },
  iconSearch: {
    position: "absolute",
    top: "50%",
    left: "10px",
    bottom: 0,
    transform: "translate(0, -50%)",
  },
  notResult: {
    color: "#B7C2CD",
    textAlign: "center",
    margin: "10px 0"
  },
  inputText: {
    width: '100%',
    padding: "13px 16px 13px 44px",
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
    fontWeight: 500,
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    border: 'initial',
    borderBottom: "1px solid rgba(183, 194, 205, 0.5)",
    "&::placeholder": {
      color: "#B7C2CD"
    },
    "&:focus": {
      border: 'initial',
      borderBottom: "1px solid #33CCA8",
      boxShadow: "0px 3px 0px 0px rgb(51 204 168 / 20%)"
    }
  },
  errorName: {
    border: "1px solid #F38F8F",
    boxShadow: "0px 0px 0px 3px rgba(255, 94, 94, 0.1);"
  },
  spinnerWrapper: {
    marginRight: 8,
    display: "flex",
    margin: "15px 0",
    justifyContent: "center",
    "& div": {
      width: 22,
      height: 22,
      borderTopColor: '#33CCA8',
    }
  },
}));
