import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  divider: {
    width: "100%",
    backgroundColor: (theme.palette as any).tableBackground ? (theme.palette as any).tableBackground.main : '',
    height: theme.spacing(1),
    margin: `${theme.spacing(20)}px 0`,
  },
  dividerWithChip: {
    marginBottom: theme.spacing(8),
  },
  cardsWrapper: {
    margin: theme.spacing(1.5, 2.5),
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  chipContainer: {
    display: "flex",
  },
  gridItemBox: {
    width: "20%",
  },
  gridItemBoxTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    marginBottom: "12px",
  },
  connectedChip: {
    marginLeft: "auto",
  },
  boxWrapper: {
    position: "relative",
  },
  boxWrapperInner: {
    margin: "20px",
  },
  gridBoxWrapperInner: {
    marginBottom: "20px",
  },
}));
