import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableBorder: {
    /* border: `solid 1px ${(theme.palette as any).alto.main}`, */
    backgroundColor:"#FFF",
    '& .MuiTableCell-alignRight:nth-last-child(1)':{
      borderBottom:0,
    }
  },
  columnTitle: {
    padding: theme.spacing(3, 8),
    '&:nth-child(1)':{
      width: "460px",
    },
    '&:nth-child(3)':{
      width: "50px",
    }
  },
  sortBtn: {
    cursor: 'pointer'
  },
  headerHeigth: {
    background: 'rgba(183, 194, 205, 0.15)',
    border: '0',
    height: '65px',
  },
  headerText: {
    color: '#7B90A3',
    fontSize: '15px',
    fontWeight: 'normal'
  },  
}));
