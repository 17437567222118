import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import * as DashboardStore from "redux/features/dashboard-store";

import {
  useViolationsSummary,
  useViolationSummaryByDate,
} from "hooks/useViolationsSummary";
import useIAMSummary from "hooks/useIAMSummary";
import useStorageSummary from "hooks/useStorageSummary";
import useViolationsStandards from "hooks/useViolationsStandards";
import useRemediationAgeSummary from "hooks/useRemediationAgeSummary";
import useInventorySummary from "hooks/useInventorySummary";

import SummaryLineChart from "components/charts/summary-line-chart";
import SimpleBarChart from "components/charts/simple-bar-chart";
import SimpleTreemap from "components/charts/simple-treemap";
import VerticalComposedChart from "components/charts/vertical-composed-chart";
import SimpleTextChart from "components/charts/simple-text-chart";
import ViolationsSummaryBarChart from "components/charts/violations-summary-bar-chart";
import SectionWrapper from "components/section-wrapper";
import TextChartWrapper from "components/text-chart-wrapper";
import SummaryByDate from "./components/summary-by-date";
import useStyles from "./dashboard.styles";
import CloudBarChart from "components/charts/cloud-bar-chart";
import PieChartSummary from "components/charts/pie-chart";
import Badge from "components/badge";

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [SecurityView, setSecurityView] = useState(true);
  const [ComplianceView, setComplianceView] = useState(false);
  const dateViolationsSummary = useViolationSummaryByDate();

  const {
    data: serviceViolationsSummary,
    loading: serviceViolationsSummaryLoading,
  } = useViolationsSummary();

  const {
    data: remediationAgeViolationsSummary,
    loading: remediationAgeViolationsSummaryLoading,
  } = useRemediationAgeSummary();

  const {
    data: violationsStandardsSummary,
    loading: violationsStandardsSummaryLoading,
  } = useViolationsStandards();

  const {
    data: [groupsCount, policiesCount],
    loading: IamSummaryLoading,
  } = useIAMSummary();

  const {
    data: inventorySummary,
    loading: inventorySummaryLoading,
  } = useInventorySummary();
  const {
    data: storageCount,
    loading: storageCountLoading,
  } = useStorageSummary();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(DashboardStore.onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleSecurity = () => {
    setSecurityView(true);
    setComplianceView(false);
  }
  const handleCompilance = () => {
    setComplianceView(true);
    setSecurityView(false);
  }
  const charttest = [{ name: 'test', value: 10 }, { name: 'test1', value: 8 }, { name: 'test2', value: 20 }, { name: 'test3', value: 5 }]
  const barvalues: any = [
    {
      name: 'test',
      shortName: 'test',
      High: 3,
      Medium: 10,
      Low: 7,
    },
    {
      name: 'test1',
      shortName: 'test1',
      High: 8,
      Medium: 4,
      Low: 15,
    },
    {
      name: 'test2',
      shortName: 'test2',
      High: 6,
      Medium: 9,
      Low: 3,
    },
    {
      name: 'test3',
      shortName: 'test3',
      High: 12,
      Medium: 5,
      Low: 7,
    },
  ];
  const data = [
    { name: 'Group A', value: 400, color: '#B7C2CD' },
    { name: 'Group B', value: 300, color: '#33CCA8' },
    { name: 'Group C', value: 300, color: '#FBC16B' },
  ];

  return (
    <div className={classes.root} > 
      <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className={classes.mainHeading}>Dashboard</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid className={classes.buttonborder}>
              <Typography onClick={handleSecurity} className={SecurityView === true ? [clsx(classes.button, classes.buttonBackground)].join(" ") : classes.button} >
                Security
              </Typography>
              <Typography onClick={handleCompilance} className={ComplianceView === true ? [clsx(classes.button, classes.buttonBackground)].join(" ") : classes.button}>
                Compilance
              </Typography>
            </Grid>
          </Grid>
      </Grid>
      {ComplianceView === true ?
        <>
          <Grid container spacing={10} className={classes.mainWrapperPage}>
            <Grid container className={classes.pieBox}>
              <Grid item xs={12} sm={12} md={5} className={classes.pieBoxInnerBox}>
                  <Typography className={classes.pieTitle}>{'STANDARD'}</Typography>
                  <Grid className={classes.chipWrapper}>
                    <Badge className={classes.chipItem}>{'CIS CSC'}</Badge>
                    <Badge className={classes.chipItem}>{'GDPR'}</Badge>
                    <Badge className={[clsx(classes.chipItem, 'activeChip')].join(" ")}>{'COBIT 5'}</Badge>
                    <Badge className={classes.chipItem}>{'HIPAA'}</Badge>
                    <Badge className={classes.chipItem}>{'CIS CSC'}</Badge>
                    <Badge className={classes.chipItem}>{'GDPR'}</Badge>
                    <Badge className={classes.chipItem}>{'COBIT 5'}</Badge>
                    <Badge className={classes.chipItem}>{'HIPAA'}</Badge>
                  </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3} className={classes.pieBoxInnerBox}>
                <Typography className={classes.pieTitle}>{'CONTROLS'}</Typography>
                <Badge className={classes.controlChip}><span className={classes.dot}></span>{'CIS CSC'}</Badge>
                <PieChartSummary
                  data={data}
                  aspect={2.5}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={12} sm={12} md={3} className={classes.pieBoxInnerBox}>
                <Typography className={classes.pieTitle}>{'CONTROL TESTS'}</Typography>
                <Badge className={classes.controlChip}><span className={classes.dot}></span>{'CIS CSC'}</Badge>
                <PieChartSummary
                  data={data}
                  aspect={2.5}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h3" className={classes.complienceTitle}>
                Compliance Posture
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h3" className={classes.complienceTitle}>
                Assets
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.borderBox}>
              <div className={classes.chartContainer}>
                <SummaryLineChart
                  values={charttest}
                  lineColor={'#33CCA8'}
                  aspect={3.5}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} >
              <Grid container spacing={10} className={classes.textChartsConatiner}>
                <Grid item xs={12} className={classes.rightBox}>
                  <TextChartWrapper type="compilance" title="Cloud Assets" helpIndex="compliance/controlsViewTable">
                    <SimpleTextChart
                      value={123}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                </Grid>
                <Grid item xs={12} className={classes.rightBox}>
                  <TextChartWrapper type="compilance" title="Employees" helpIndex="dashboard/inventory">
                    <SimpleTextChart
                      value={456}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                </Grid> 
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} >
            <Grid container spacing={10} >
                <Grid item xs={12} className={classes.rightBox}>
                  <TextChartWrapper type="compilance" title="vendors" helpIndex="settings/vendorForm">
                    <SimpleTextChart
                      value={123}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                </Grid>
                <Grid item xs={12} className={classes.rightBox}>
                  <TextChartWrapper type="compilance" title="Workstations" helpIndex="dashboard/inventory">
                    <SimpleTextChart
                      value={456}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                 </Grid>
               </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Typography variant="h3" className={[clsx(classes.complienceTitle, classes.cloudMiscon)].join(" ")}>
                Cloud Misconfiguration
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <Typography variant="h3" className={[clsx(classes.complienceTitle, classes.cloudMiscon)].join(" ")}>
                Asset Count by Cloud Service
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.borderBox}>
              <div className={classes.chartContainer}>
                <CloudBarChart
                  values={barvalues}
                  isLoading={serviceViolationsSummaryLoading}
                  aspect={3.6}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={[clsx(classes.assetCount , classes.box)].join("")}>
              <div className={classes.chartContainer}>
                <SimpleTreemap
                  type="compilance"
                  data={inventorySummary}
                  isLoading={inventorySummaryLoading}
                />
              </div>
            </Grid>
          </Grid>
        </>
        :
        <>
          <Grid container spacing={10}>
            <SummaryByDate summary={dateViolationsSummary} />
            <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
              <SectionWrapper
                title="Issue Count by Cloud Service"
                helpIndex="dashboard/violationsByService"
              >
                <ViolationsSummaryBarChart
                  values={serviceViolationsSummary}
                  isLoading={serviceViolationsSummaryLoading}
                />
              </SectionWrapper>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
              <SectionWrapper title="Issue Aging" helpIndex="dashboard/issueAge">
                <VerticalComposedChart
                  values={remediationAgeViolationsSummary}
                  isLoading={remediationAgeViolationsSummaryLoading}
                />
              </SectionWrapper>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className={classes.borderBox}>
              <SectionWrapper
                title="NCI Count by Standards"
                helpIndex="dashboard/violationsByStandards"
              >
                <SimpleBarChart
                  data={violationsStandardsSummary}
                  isLoading={violationsStandardsSummaryLoading}
                />
              </SectionWrapper>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Grid container spacing={10} className={classes.textChartsConatiner}>
                <Grid item xs={12}>
                  <TextChartWrapper
                    title="Principals"
                    helpIndex="dashboard/principals"
                  >
                    <SimpleTextChart
                      value={groupsCount}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                </Grid>
                <Grid item xs={12}>
                  <TextChartWrapper title="Policies" helpIndex="dashboard/policies">
                    <SimpleTextChart
                      value={policiesCount}
                      isLoading={IamSummaryLoading}
                    />
                  </TextChartWrapper>
                </Grid>
                <Grid item xs={12}>
                  <TextChartWrapper title="Storage" helpIndex="dashboard/storage">
                    <SimpleTextChart
                      value={storageCount}
                      isLoading={storageCountLoading}
                    />
                  </TextChartWrapper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={9} className={classes.assetCountCloud}>
              <SectionWrapper
                title="Asset Count by Cloud Service"
                helpIndex="inventory/servicesChart"
              >
                <SimpleTreemap
                  data={inventorySummary}
                  isLoading={inventorySummaryLoading}
                />
              </SectionWrapper>
            </Grid>
          </Grid>
        </>
      }

    </div>
  );
};

export default Dashboard;
