import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    borderBox:{
        '& > div':{
          background: "#FFFFFF",
          border: "1px solid rgba(183, 194, 205, 0.7)",
          boxSizing: "border-box",
          borderRadius: "8px",
          boxShadow: "none",
        }
    }
}));
