import React from "react";
import clsx from "clsx";
import { Box, Typography, Grid } from "@material-ui/core";
import useStyles from "./integrations-card.styles";
interface IProps {
  imageUrl: string;
  service: string;
  serviceType: string;
  onIntegrationClick(serviceType: string, service: string): void;
}

const IntegrationsCard = (props: IProps) => {
  const classes = useStyles();
  const onIntegrationClick = (service: string) => {
    props.onIntegrationClick(props.serviceType, service);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.card)}
      onClick={() => onIntegrationClick(props.service)}
      data-testid="integrations-card"     
    >
      <Grid container>
        <Grid item md={12} className={classes.imgSection}>
          <img width={58} src={props.imageUrl} alt="Integration" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12} className={classes.imgTxtSection}>
          <Typography variant="body2" className={classes.gridItemBoxContent}>
            {props.service}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntegrationsCard;
