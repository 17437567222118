import React, { useState } from 'react';
import moment from 'moment';
import { TableCell, TableRow, Collapse, Tooltip } from '@material-ui/core';

import ViolationsRowExpanded from '../violations-row-expanded';

import { Row, Alignment } from 'interfaces/table-interface';

import useStyles from './violations-row.styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
interface IProps {
  row: Row<string>;
}

const ViolationsRow = ({ row }: IProps) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfColumns = row.values.length + 1;
  const centeredColumns = [4, 5];

  const getClassName = (value: any) => {
    let className: any;
    switch (value) {
      case "High":
        className = classes.highRatingCell
        break;
      case "Medium":
        className = classes.mediumRatingCell
        break;
      case "Low":
        className = classes.lowRatingCell
        break;

      default:
        break;
    }
    return className;
  }
  
  const renderSwitch = (i: any, value: any) => {
    switch(i) {
      case 3:
        return <span className={[getClassName(value), classes.riskRatingCell].join(' ')}>{value}</span>
      case 4:
        return (
          <Tooltip title={moment(value).format('MM/DD/YY hh:mm:ss a')} placement="bottom">
            <span className={classes.bodyText}>{moment(value).format('MMM D, YYYY')}</span>
          </Tooltip>
          )
      default:
        return <span className={classes.bodyText}>{value}</span>
    }
  }
  
  return (
    <>
      <TableRow>
        {row.values.map((value, i) => {
          const alignment = centeredColumns.includes(i) ? Alignment.center : Alignment.left;
          return (
            <TableCell align={alignment} key={i} className={classes.rowBorderBottom}>
              {renderSwitch(i, value)}
            </TableCell>
          );
        })}
        <TableCell align="right">
          <div className={classes.expandBtn}
            color="secondary"
            onClick={() => setExpanded(!isExpanded)}>
            {isExpanded ?
              <ExpandLessIcon className={classes.actionIconBorder}></ExpandLessIcon>
              :
              <ExpandMoreIcon className={classes.actionIconBorder}></ExpandMoreIcon>
            }
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expandedRow}>
        <TableCell colSpan={numberOfColumns} className={classes.expandedCell}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <ViolationsRowExpanded ruleId={row.ruleId} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ViolationsRow;
