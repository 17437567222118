import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 250;
const closedDrawerWidth = 70;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: closedDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    overflowX: "hidden",
    backgroundColor: '#1B2E3F',
    color:"#FFFFFF",
    borderRight: `0px solid ${theme.palette.secondary.main}`,
  },
  content: {
    position: "relative",
    minHeight: "100vh",
    paddingBottom: theme.spacing(30),
    flexGrow: 1,
    // padding: theme.spacing(12),
    padding: 0,
    width: "calc(100% - 60px)",
    // background: "#fff",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(30),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: closedDrawerWidth,
      padding: theme.spacing(5),
      paddingBottom: theme.spacing(39),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: closedDrawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 0,
    },
  },
  icon: {
    color: "#A4ABB2",
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
  },
  textActive: {
    color: '#1B2E3F',
  },
  drawerListItem: {
    opacity: 0.5,
    marginLeft:"10px",
  },
  drawerListItemActive: {
    opacity: 1,
    backgroundColor: '#FFFFFF !important',
    color:'#1B2E3F',
    borderRadius: '16px 0px 0px 16px',
  },
  bottomListContainer: {
    marginTop: "auto",
  },
  logo: {
    marginLeft: theme.spacing(-4),
  },
  logoListItem: {
    marginBottom: theme.spacing(10),
    alignItems: "flex-start",
    "&.MuiListItemIcon-root": {
      paddingLeft: '10px',
    }
  },
  logotext: {
    "&.MuiListItemText-primary": {
      fontWeight: "bolder !important",
    },
    marginBottom: '15px'
  },
  footer: {
    maxWidth: "95%",
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(10, 0),
  },
  covermenu:{
      width: "20px",
      height: "20px",
      background: "#1b2e3f",
      position: "absolute",
      right:" 4px",
      top: "-15px",
      borderBottomRightRadius: "25px",
      // transform: 'rotate(180deg)',
      border: "5px solid #fff",
      borderLeft: 0,
      borderTop: 0,
  },
  covermenu2:{
      width: "20px",
      height: "20px",
      background: "#1b2e3f",
      position: "absolute",
      right:" 4px",
      bottom: "-15px",
      borderTopRightRadius: "25px",
      // transform: 'rotate(180deg)',
      border: "5px solid #fff",
      borderLeft: 0,
      borderBottom: 0,
  }

}));
