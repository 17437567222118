import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  colName: {
    maxWidth: "10rem",
    '& p': {
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  statusCol:{
    textAlign: 'center',
    '& p': {
    padding: "6px 5px 5px",
    position: "static",
    width: "auto",
    height: "auto",
    fontWeight: 500,
    // background: "#E0F8F1",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    // display: "flex",
    alignItems: "center",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
    display: "inline-block",
    // color: "#0C79DA",
    // background: "#DEEFFF",
    }
  },
  inputText: {
    width: '100%',
    padding: "13px 16px",
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
    fontWeight: 500,
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    border: 'initial',
    borderBottom: "1px solid rgba(183, 194, 205, 0.5)",
    "&::placeholder": {
      color: "#B7C2CD"
    },
    "&:focus": {
      border: 'initial',
      borderBottom: "1px solid #33CCA8",
      boxShadow: "0px 3px 0px 0px rgb(51 204 168 / 20%)"
    }
  },
  errorName: {
    '& input': {
      border: "1px solid #F38F8F",
      boxShadow: "0px 0px 0px 3px rgba(255, 94, 94, 0.1)"
    },
  },
  btnSquare: {
    width: 24,
    height: 24,
    background: '#497DAB !important',
    borderRadius: '3px',
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  btnsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  btnSave: {
    height: 30,
    width: 30,
    backgroundColor: '#33CCA8',
    fontSize: '15px',
    padding: '0 7px',
    marginRight: '8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  btnCancel: {
    height: 30,
    width: 30,
    backgroundColor: '#F38F8F',
    fontSize: '15px',
    padding: '0 8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      borderTopColor: 'white',
    }
  },
  noWrap: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  activeStatus: {
    padding: "6px 6px 5px !important",
    color: "#47C2A4 !important",
    background: "#E0F8F1 !important",
  },
  terminatedStatus: {
    color: "#99A9B8 !important",
    background: "#E7ECF1 !important",
  },
  newStatus: {
    color: "#0C79DA !important",
    background: "#DEEFFF !important",
  },
  roleColm: {
    '& p': {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px !important",
    lineHeight: "19px !important",
    textAlign: "center",
    color: "#1B2E3F",
    }
  },
  nameCol: {
    '& p':{
      textAlign:"left",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#1B2E3F",
    },
  },
  Icon: {
    '&.MuiAvatar-root': {
      backgroundColor: "#FAB26F !important",
      textAlign: "center",
      display: "inline-grid",
      borderRadius: "33px",
      marginRight: "10px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: '14px',
      width: '25px',
      height: '26px',
    },
    // padding: "6px 4px 0px 4px",
  },
  orienaTion: {
    '& span': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    }
  },
  backGroundCheck: {
    '& span.unavailable': {
      /* background: red; */
      display: "inline-block",
      padding: "7px 4px 7px 6px",
      background: "#e2e7eb",
      /* margin-right: 10px; */
      borderRadius: "33px",
      fontSize: "11px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "13px",
      /* display: flex; */
      alignItems: "center",
      textAlign: "center",
      color: "#61778B",
    },
    '& span.compliant span': {
      position: "relative",
    },
    '& span.compliant span > span': {
        position: "absolute",
        zIndex: 9999,
        top: "-2px",
        left: "10px",
        color: "#fff",
    }
  },
  policy: {
    '& .policyWrapperP': {
      position: "relative",
    },
    '& .policyWrapperP .policySuccess': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    },
    '& .policyComplaint': {
        color: "#fff",
        display: "inline-block",
        padding: "2px 9px 2px 9px",
        background: "#F38F8F",
        borderRadius: "33px",
    }
  },
  annualTraining: {
    '& .trainingWrapperP': {
      position: "relative",
    },
    '& .trainingWrapperP .trainingSuccess': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    },
    '& .trainingComplaint': {
        color: "#fff",
        display: "inline-block",
        padding: "2px 9px 2px 9px",
        background: "#F38F8F",
        borderRadius: "33px",
    }
  },
  mfaStatus: {
    '& .mfaWrapperP': {
      position: "relative",
    },
    '& .mfaWrapperP .mfaSuccess': {
      background: "#47c2a4",
      padding: "4px 4px 0px 4px",
      display: "inline-block",
      borderRadius: "33px",
    },
    '& .mfaComplaint': {
        color: "#fff",
        display: "inline-block",
        padding: "2px 9px 2px 9px",
        background: "#F38F8F",
        borderRadius: "33px",
    },
    '& span.notAplicable': {
      position: "relative",
    },
    '& span.notAplicable > span': {
        position: "absolute",
        top: "-2px",
        left: "9px",
        color: "#fff",
    }
  }
}));
