import React, { useState, useRef, ChangeEvent } from 'react';
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { TColumn } from "interfaces/table-interface";
import { Vendorslist } from 'interfaces/settings-interface';
import useStyles from './vendor-table.style';
import Table from 'components/table';
import { TableRowProps } from "components/table/table-row/table-row";
import TableRow from "components/table/table-row";
import TableColumn from "components/table/table-column";
import Button from 'components/button/button';
import DropdownWrapper from "components/dropdown-wrapper";
import {
  fetchVendorTests,
  SingleVendordata,
  DownloadVendorsfile,
  DeletedVendorsfile,
  UploadVendorfile,
} from "redux/features/settings-store";
import { MenuItem } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ReactComponent as UploadIcon } from "icons/upload.svg";
import {ReactComponent as SettingIcon} from "icons/setting.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import { ReactComponent as PdfIcon } from "icons/pdf.svg";
import { ReactComponent as JpegIcon } from "icons/jpeg.svg";
import { ReactComponent as DocxIcon } from "icons/docx.svg";
import { ReactComponent as XlsIcon } from "icons/xls.svg";
import { ReactComponent as PngIcon } from "icons/png.svg";
import { ReactComponent as GreenTick } from "icons/greentick.svg";


import moment from 'moment';

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'vendor',
    title: 'Vendor'
  },
  {
    flex: '1 0 calc(15% - 24px)',
    field: 'contact',
    title: 'Contact'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    field: 'risk rating',
    title: 'Risk Rating',
    display: 'flex',
    justifyContent: 'center',
  },
  {
    flex: '1 0 calc(10% - 24px)',
    align: 'center',
    field: 'customer-Data',
    title: 'Customer-Data'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    align: 'center',
    field: 'security artifacts',
    title: 'Security Artifacts'
  },
  {
    flex: '1 0 calc(10% - 24px)',
    align: 'center',
    field: 'actions',
    title: 'Actions'
  },
]
interface TableItemProps extends TableRowProps {
  item: Vendorslist,
  setFormView: any,
  searchValue: string,
}

function TableItem({ item, setFormView, searchValue, ...otherProps }: TableItemProps) {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [hideTooltipVendorName, setHideTooltipVendorName] = useState(false);
  const [adopted] = useState<string>(item.status);

  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null)

  const VendorForm = () => {
    setFormView(true);
    dispatch(SingleVendordata(item.vendor_id))
  }

  function toggleDropdown(item: any) {
    if(item) setOpened(prevState => !prevState);
  }

  const downloadfile = (evidence_id: string) => {
    dispatch(DownloadVendorsfile(item.vendor_id, evidence_id))
  }

  const deletefile = async (evidence_id: string) => {
    await dispatch(DeletedVendorsfile(item.vendor_id, evidence_id))
    dispatch(fetchVendorTests())
  }

  const Uploadfile = () => {
    fileInputRef.current?.click()
  }

  async function handleFileChange({ target: { files } }: ChangeEvent<HTMLInputElement>) {
    if (files && !item.copy) {
      const updatedVendor = { ...item, file: files[0] }
      await dispatch(UploadVendorfile(updatedVendor))
      dispatch(fetchVendorTests())
    }
  }

  const DefaultCard = (props: any) => {
    return (
      <span>
        <span>{props.nameBefore}</span>
        <span className={classes.bold}>{props.bold}</span>
        <span>{props.nameAfter}</span>
      </span>
    );
  }

  function displayTextWidth(text: any, font: any): HTMLCanvasElement {
    // @ts-ignore
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  }

  function handleCell(target: any) {
    const parentWidth = target.currentTarget.offsetWidth
    if (parentWidth < displayTextWidth(target.currentTarget.firstChild.outerText, "normal 16px 'Inter', sans-serif ")) {
      setHideTooltipVendorName(true)
    } else {
      setHideTooltipVendorName(false)
    }
  }


  const textValue = (value?: string) => {
    let isHighlighted = false;
    searchValue.trim().replace(/ +(?= )/g, '');

    const index = (value ? value.toLowerCase().indexOf(searchValue.toLowerCase()) : 0);
    let nameBefore = value || '';
    let queryData = '';
    let nameAfter = '';
    if (index !== -1) {
      nameBefore = (value ? value.slice(0, index) : '');
      queryData = (value ? value.slice(index, index + searchValue.length) : '');
      nameAfter = (value ? value.slice(index + searchValue.length) : '');
    }
    if (isHighlighted) {
      return <DefaultCard isHighlighted={true} nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter} />;
    }
    else {
      return (
        <DefaultCard nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter} />
      );
    }
  }

  return (
    <>
      <TableRow {...otherProps}>
        <TableColumn >
          <div style={{ color: adopted === 'inactive' ? "rgb(183, 194, 205)" : "" }} onMouseEnter={(e) => handleCell(e)} data-tip data-for={'registerTip-' + item.point_of_contact + item.vendor_name + item.vendor_id}><p>{textValue(item.vendor_name)}</p></div>
          {hideTooltipVendorName && <ReactTooltip id={'registerTip-' + item.point_of_contact + item.vendor_name + item.vendor_id} place="top" effect="solid">
            {item.vendor_name}
          </ReactTooltip>}
        </TableColumn>
        <TableColumn>
          <div style={{ color: adopted === 'inactive' ? "rgb(183, 194, 205)" : "" }} onMouseEnter={(e) => handleCell(e)} data-tip data-for={'registerTip-' + item.point_of_contact + item.vendor_name + item.vendor_id}><p>{textValue(item.point_of_contact)}</p></div>
        </TableColumn>
        <TableColumn className={item.risk_rating}>
          <span style={{ color: adopted === 'inactive' ? "rgb(183, 194, 205)" : "" }} onMouseEnter={(e) => handleCell(e)} data-tip data-for={'registerTip-' + item.point_of_contact + item.vendor_name + item.vendor_id}>{textValue(item.risk_rating)}</span>
        </TableColumn>
        <TableColumn>
          <span className={item.handles_customer_data.toString()}>{item.handles_customer_data.toString() === 'true' ? <span><>{ adopted === 'inactive' ? <GreenTick style={{opacity:0.5}}/>:<GreenTick />}</></span> : <span className="circlena">n/a</span>}</span>
        </TableColumn>
        <TableColumn className="securityArti">
          <DropdownWrapper
            opened={opened}
            onClose={() => setOpened(false)}
            position={'right'}
            triggerEl={
              <Button onClick={() => {toggleDropdown(item.security_evidences)}} type={'icon'} className={adopted === 'inactive' ? classes.iconDisabled : ''}>
                <AttachFileIcon />
                {item.security_evidences ?
                <>
                {adopted === 'inactive' ? 
                  <span style={{opacity:0.5}}>{item.security_evidences.length}</span> : <span>{item.security_evidences.length}</span> }
                  </>
                  : null}
              </Button>
            }>{item.security_evidences ?
              <div className='tablePopupWrapper'>
                <ul className={classes.tableHeader}>
                  <MenuItem>
                    <span>File Name</span>
                    <span>Upload Date</span>
                    <span>Actions</span>
                  </MenuItem></ul>
                <ul className={classes.tableData}>
                  {item.security_evidences.map((evidence) => {
                    const fileEx = evidence.file_name.split('.')[2] || ''
                    const renderSwitch = () => {
                      switch (fileEx) {
                        case "pdf":
                          return <PdfIcon />
                        case "doc":
                          return <DocxIcon />
                        case "jpg":
                          return <JpegIcon />
                        case "xls":
                          return <XlsIcon />
                        case "png":
                          return <PngIcon />
                        default:
                          return <></>
                      }
                    }
                    return (
                      <MenuItem
                        key={evidence.evidence_id}
                        value={evidence.file_name}>
                        <span className='redIcon'>
                          {renderSwitch()}
                          <p>{evidence.file_name}</p>
                        </span>)
                        <span >{moment(evidence.created).format('DD MMM,YYYY')}</span>
                        <span className={classes.thirdRow}><DownloadIcon onClick={() => downloadfile(evidence.evidence_id)} /><DeleteIcon onClick={() => deletefile(evidence.evidence_id)} /></span>
                      </MenuItem>
                    )

                  })}
                </ul></div> : null}
          </DropdownWrapper>
        </TableColumn>
        <TableColumn className='Actions'>
          {/* { isLoadingSaveFile && <Spinner />} */}
          {/* { !isLoadingSaveFile && <> */}
          <input
            className={classes.input}
            accept=".txt,.pdf,.doc,.jpg,.png,.xls"
            ref={fileInputRef}
            name="file"
            type="file"
            value=""
            onChange={handleFileChange} />
          {/* </> */}
          {/* } */}
          <div className={classes.settingIcon}>
            {adopted === 'inactive' ?
              <SettingIcon style={{opacity:0.5}} onClick={() => VendorForm()} />:<SettingIcon onClick={() => VendorForm()} /> 
            }
          </div>
          <div>
            {adopted === 'inactive' ?
              <UploadIcon onClick={Uploadfile} style={{opacity:0.5}}/>:<UploadIcon onClick={Uploadfile} /> 
            }
          </div>
          
        </TableColumn>
      </TableRow>
    </>
  )
}

const VendorsTable = ({ data, setFormView, searchValue }: { data: Vendorslist[], setFormView: any, searchValue: string }) => {
  function rowRenderer(item: Vendorslist) {
    return (
      <TableItem key={item.vendor_id} item={item} setFormView={setFormView} searchValue={searchValue} />
    )
  }
  return (
    <Table
      columns={tableColumns}
      data={data.filter((d: any) =>
        d.vendor_name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 ||
        d.point_of_contact.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 ||
        d.risk_rating.toUpperCase().indexOf(searchValue.toUpperCase()) > -1
      )}
      isLoading={false}
      rowRenderer={rowRenderer}
    />
  )
}
export default VendorsTable;