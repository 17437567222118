import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as InventoryService from "services/inventory-service";
import { addSnackbar } from "./notifications-store";
import { setLoading, getHelp } from "./ui-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  InventorySummary,
  InventoryServiceSummary,
  InventoryRegionData,
  InventoryServiceArn,
  InventoryResource,
  InventoryEmployees,
  InventoryWorkstations,
} from "interfaces/inventory-interface";
import { DownloadFormat } from "interfaces/download-interface";

export interface InventoryStore {
  iamSummary: InventorySummary;
  storageSummary: InventorySummary;
  inventorySummary: InventoryServiceSummary;
  regions: InventoryRegionData[];
  arns: InventoryServiceArn[];
  inventoryEmployees:InventoryEmployees[];
  inventoryWorkstations:InventoryWorkstations[];
  cloudCount: any;
}

const initialState: InventoryStore = {
  iamSummary: {
    summary: [],
  },
  storageSummary: {
    summary: [],
  },
  inventorySummary: {
    summary: [],
  },
  regions: [],
  arns: [],
  inventoryEmployees:[],
  inventoryWorkstations:[],
  cloudCount: {},
};

const slice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setIamSummary: (
      store: InventoryStore,
      action: PayloadAction<InventorySummary>
    ) => {
      store.iamSummary = action.payload;
    },
    setStorageSummary: (
      store: InventoryStore,
      action: PayloadAction<InventorySummary>
    ) => {
      store.storageSummary = action.payload;
    },
    setInventorySummary: (
      store: InventoryStore,
      action: PayloadAction<InventoryServiceSummary>
    ) => {
      store.inventorySummary = action.payload;
    },
    setRegions: (
      store: InventoryStore,
      action: PayloadAction<InventoryRegionData[]>
    ) => {
      store.regions = action.payload;
    },
    setArns: (
      store: InventoryStore,
      action: PayloadAction<InventoryServiceArn[]>
    ) => {
      store.arns = action.payload;
    },
    setResource: (
      store: InventoryStore,
      action: PayloadAction<{ arnId: string; data: InventoryResource }>
    ) => {
      const { arnId, data } = action.payload;
      const currentArn = store.arns.find((arn) => arn.arn === arnId);

      if (currentArn) {
        currentArn.resource = data;
      }
    },
    setInventoryEmployees: (
      store: InventoryStore,
      action: PayloadAction<InventoryEmployees[]>
    ) => {
      store.inventoryEmployees = action.payload;
    },
    setInventoryWorkstations: (
      store: InventoryStore,
      action: PayloadAction<InventoryWorkstations[]>
    ) => {
      store.inventoryWorkstations = action.payload;
    },
    setCloudCount: (
      store: InventoryStore,
      action: PayloadAction<any>
    ) => {
      store.cloudCount = action.payload;
    },
  },
});

export const onPageInit = (): AppThunk => async (dispatch, store) => {
  await dispatch(fetchRegions());
  await dispatch(fetchRegionSummary());
  await dispatch(fetchInventorySummary());

  dispatch(loadHelpText());
};

const loadHelpText = (): AppThunk => async (dispatch, store) => {
  const indexes = [
    "inventory/map",
    "inventory/servicesChart",
    "inventory/assetsTable",
  ];

  for (const index of indexes) {
    dispatch(getHelp(index));
  }
};

export const fetchIamSummary = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await InventoryService.listIamSummary()
      .then(({ data }) => {
        dispatch(setIamSummary(data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchStorageSummary = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await InventoryService.listStorageSummary()
      .then((res) => {
        dispatch(setStorageSummary(res.data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchInventorySummary = (): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    dispatch(setLoading(true));

    await InventoryService.listInventorySummary()
      .then((res) => {
        dispatch(setInventorySummary(res.data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });

    dispatch(setLoading(false));
  }
};

export const fetchRegions = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    dispatch(setLoading(true));

    await InventoryService.listRegions()
      .then((res) => {
        dispatch(setRegions(res.data.regions));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });

    dispatch(setLoading(false));
  }
};

export const fetchRegionSummary = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    const regions = store().inventory.regions;
    const localRegions: InventoryRegionData[] = [];

    for (let region of regions) {
      await InventoryService.listRegionSummary(region.name)
        .then((res) => {
          localRegions.push({
            ...region,
            summary: res.data.summary,
          });
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            dispatch(setIsTokenValid(false));
          }

          dispatch(addSnackbar({ text: "Can't fetch data" }));
        });
    }

    dispatch(setRegions(localRegions));
  }
};

export const fetchArns = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    dispatch(setLoading(true));

    await InventoryService.listArns()
      .then(({ data }) => {
        dispatch(setArns(data.resources));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });

    dispatch(setLoading(false));
  }
};

export const fetchRegionArns = (region: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await InventoryService.listArns(region)
      .then(({ data }) => {
        dispatch(setArns(data.resources));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchResource = (arnId: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    const arns = store().inventory.arns;
    let arn = arns.find((item) => item.arn === arnId);

    if (!arn) {
      return;
    }

    await InventoryService.listResources(arn.arn, arn.type)
      .then(({ data }) => {
        dispatch(setResource({ data, arnId }));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const downloadInventory = (format: DownloadFormat): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await InventoryService.downloadInventory(format)
      .then((res) => res)
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const scanInventory = (region?: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await InventoryService.scanInventory(region)
      .then(({ data }) => dispatch(setArns(data.resources)))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchEmployees = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await InventoryService.employeesInventory()
      .then((res:any) => {
        const employeeList = res.data.employees;
        dispatch(setInventoryEmployees(employeeList));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchWorkstations = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await InventoryService.workstationsInventory()
      .then((res:any) => {
        const workstationList = res.data.workstations;
        dispatch(setInventoryWorkstations(workstationList));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchCloudsCount = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await InventoryService.cloudInventoryCount()
      .then((res: any) => {
        dispatch(setCloudCount(res.data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const { reducer } = slice;
export const {
  setIamSummary,
  setStorageSummary,
  setInventorySummary,
  setRegions,
  setArns,
  setResource,
  setInventoryEmployees,
  setInventoryWorkstations,
  setCloudCount,
} = slice.actions;
