import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core";

import * as InventoryStore from "redux/features/inventory-store";
import { truncate } from "utils/text-utils";

import { AppState } from "interfaces/app-state-interface";
import { InventoryServiceSummary } from "interfaces/inventory-interface";
import { ChartValue } from "interfaces/charts-interface";
import { FetchHook } from "interfaces/hooks-interface";

const useInventorySummary = (isBarChart = false): FetchHook<ChartValue[]> => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { summary } = useSelector<AppState, InventoryServiceSummary>(
    (state) => state.inventory.inventorySummary
  );

  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(InventoryStore.fetchInventorySummary());

    setLoading(false);
  };

  if (loading) {
    return {
      loading,
      data: [],
    };
  }

  const values: ChartValue[] = [];

  const {
    shade_400,
    shade_300,
    shade_200,
    shade_100,
  } = (theme.palette as any).tertiaryTreemapShades;
  const COLOR_SHADES = [shade_400, shade_300, shade_200, shade_100];

  for (const item of summary) {
    values.push({
      name: item.service,
      value: item.count,
      shortName: truncate(item.service, 8),
      fill: COLOR_SHADES[values.length % COLOR_SHADES.length],
    });
  }

  // bigger squares are positioned to the left and smaller squares to the right
  values.sort((a, b) => b.value - a.value);

  if (isBarChart) {
    const restValues = values.splice(9);
    const restValuesCount = restValues.reduce(
      (previousValue, currentValue) => previousValue + currentValue.value,
      0
    );

    const restValueChartData = {
      name: "Misc.",
      shortName: "Misc.",
      value: restValuesCount,
      fill: COLOR_SHADES[values.length % COLOR_SHADES.length],
    };

    values.push(restValueChartData);

    return {
      loading,
      data: values,
    };
  }

  return {
    loading,
    data: values,
  };
};

export default useInventorySummary;
