import {AxiosPromise} from "axios";
import {PolicyResponse} from "../interfaces/settings-interface";
import { axiosIntegrationsInstance } from "./http-service";

export const listPolicies = (): AxiosPromise<{
  policies: PolicyResponse[]
}> => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/policies");
};

export const updatePolicy = (policy: FormData): AxiosPromise => {
  return axiosIntegrationsInstance.put("/settings-compliance/v1/policies", policy);
};

export const listTemplates = (): AxiosPromise<{
  templates: any[]
}> => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/templates");
};

export const listTopics = (): AxiosPromise<{
  topics: string[]
}> => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/topics");
};

export const listEmployees = (): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/employees");
};

export const getResultSearchEmployees = (name: string): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/employees", {
    params: {
      name,
    }
  });
};

export const downloadPolicyFile = (policy_name: string): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/policies/download", {
    params: {
      policy_name,
    },
    responseType: "blob",
  })
}

export const downloadPolicies = (): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/policies/download", {
    responseType: "blob",
  })
}

export const downloadTemplates = (): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/templates/download", {
    responseType: "blob",
  })
}

export const downloadTemplateFile = (policy_name: string): AxiosPromise => {
  return axiosIntegrationsInstance.get("/settings-compliance/v1/templates/download", {
    params: {
      policy_name,
    },
    responseType: "blob",
  })
}
