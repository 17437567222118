import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    divider: {
        width: "100%",
        // color: (theme.palette as any).tableBackground.main,
        // height: theme.spacing(1),
        // margin: `${theme.spacing(20)}px 0`,
        // margin: "10px 0",
        marginBottom: "0px",
        boxShadow:"1px 1px 0px #F0F0F0"
      },
}));