import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    pieChartCircle:{
        position: "relative",
        top: "-40px",
        '& svg':{
            // height: "350px",
            position: "relative",
            // top: "-25px",
            // minHeight: "100px",
            width: "350px",
            // width: "258px",
            left: "60px",
            top: "-39px",
        },
        '@media (max-width:1370px)': {
            '& svg':{
                minHeight: "100px !important",
                width: "258px !important",
            },
          }
    },
    '@media (max-width:530px)': {
        pieChartCircle:{
            top:"10px !important",
            "& svg":{
                width: "130px !important",
                minHeight: "auto !important",
            }
        }
      },
      '@media (max-width:415px)': {
        pieChartCircle:{
            "& svg":{
                width: "81px !important",
                top:"0px !important",
                left:"0px !important",
            }
        }
      }
}));
