import React from "react";
import useStyles from './spinner.styles'
import clsx from "clsx";

function Spinner() {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, classes.animation)} />
  )
}

export default Spinner
