import React from 'react'
import {Option} from "interfaces/common-intefaces";
import Checkbox from "../checkbox";
import clsx from "clsx";

interface CheckboxGroupProps {
  className?: string;
  checkboxClassName?: string;
  name: string;
  options: Option[],
  values: string[],
  tab?: string;
  onChange: (e: { target: { name: string, value: string[] } }) => void;
}

function CheckboxGroup({ className, checkboxClassName, name, options, values, onChange, tab = '' }: CheckboxGroupProps) {

  function handleChange({ target: { name: checkboxName, checked } }: React.ChangeEvent<HTMLInputElement>) {
    let updatedValues = [...values]

    if (checked)
      updatedValues.push(checkboxName)
    else
      updatedValues = values.filter(value => value !== checkboxName)

    onChange({ target: { name, value: updatedValues } })
  }

  return (
    <div className={className}>
      {
        options.map((option) => (
          <Checkbox
            key={option.value}
            tab={tab}
            className={clsx(checkboxClassName)}
            checked={values.includes(option.value)}
            onChange={tab === 'retired' ? () => {} : handleChange}
            name={option.value}
            label={option.label} />
        ))
      }
    </div>
  )
}

export default CheckboxGroup
