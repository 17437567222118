/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./control-table.styles";
import { Typography } from "@material-ui/core";

import { ControlTestsList } from "interfaces/settings-interface";
import { TColumn } from "interfaces/table-interface";
import { AppState } from "interfaces/app-state-interface";
import { Option } from "interfaces/common-intefaces";
import { ReactComponent as EllipsisIcon } from "icons/ellipsis.svg";
import { ControlIcon} from 'interfaces/settings-interface';

import Button from "components/button";
import Table from "components/table";
import TableRow from "components/table/table-row";
import TableColumn from "components/table/table-column";
import OwnerDropdown from "../../owner-dropdown";
import Badge from "components/badge";
import { TableRowProps } from "components/table/table-row/table-row";

import {
  setsingleControlTestData,
  updateControlTests,
} from "redux/features/settings-store";

const tableColumns: TColumn[] = [
  {
    flex: '1 0 calc(24% - 24px)',
    field: 'control-tests',
    title: 'Control Tests'
  },
  {
    flex: '1 0 calc(2% - 24px)',
    field: 'task-owner',
    title: 'Task Owner'
  },
  {
    flex: '1 0 calc(5% - 20px)',
    field: 'source',
    title: 'Source',
    display: 'flex',
    justifyContent: 'center',
  },
  {
    flex: '1 0 calc(5% - 30px)',
    align: 'center',
    field: 'standards',
    title: 'Standards'
  },
  {
    flex: '1 0 calc(2% - 24px)',
    align: 'center',
    field: '',
    title: ''
  },
]

interface TableItemProps extends TableRowProps {
  item: ControlTestsList,
  setFormView: any,
  searchValue: string,
}

function TableItem({ item, setFormView, searchValue, ...otherProps }: TableItemProps) {
  const classes = useStyles()
  const dispatch = useDispatch();

  const ownersOptions = useSelector<AppState, Option[]>(
    (state) => state.settings.employees.map(({ name,node_id }) => ({
      label: name,
      value: node_id
    }))
  );
  const controlIcon = useSelector<AppState,ControlIcon[]>(
    (state) => state.settings.controlTestIcon)
  

  const [owner, setOwner] = useState(item.owner_id);
  const controlCount = item.controls.length > 3;
  const totalcontrolCount = item.controls.length - 3;

  async function handleChange({ target: { name, value } }: { target: { name: string, value: any }}) {
    const updatedControls = { ...item }
    if (name === 'owner_id') {
      setOwner(value)
        updatedControls.owner_id = value
    }
    if (!item.copy) {
      await dispatch(updateControlTests(item.node_id,updatedControls))
    }
  }
  
  const showCount = () => {
    return (
      <span className={classes.showCount}>
        {totalcontrolCount}
      </span>
    );
  }

  const addEditForm = (item: any) => {
    setFormView(true);
    dispatch(setsingleControlTestData(item));
  }
  
  const DefaultCard = (props: any) => {
    return (
      <span>
        <span>{props.nameBefore}</span>
        <span className={classes.bold}>{props.bold}</span>
        <span>{props.nameAfter}</span>
      </span>
    );
  }

  const textValue = (value?: string) => {
    let isHighlighted = false;
    searchValue.trim().replace(/ +(?= )/g, '');
    const index = (value ? value.toLowerCase().indexOf(searchValue.toLowerCase()) : 0);
    let nameBefore = value || '';
    let queryData = '';
    let nameAfter = '';
    if (index !== -1) {
      nameBefore = (value ? value.slice(0, index) : '');
      queryData = (value ? value.slice(index, index + searchValue.length) : '');
      nameAfter = (value ? value.slice(index + searchValue.length) : '');
    }
    if (isHighlighted) {
      return <DefaultCard isHighlighted={true}  nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter}  />;
    }
    else{
      return (
        <DefaultCard nameBefore={nameBefore} bold={queryData} nameAfter={nameAfter} />
      );
    }
  }
 
  return (
    <TableRow {...otherProps} >
      <TableColumn>
        <div>
          <div className={classes.badgeFixWidth}>
            <Badge className={classes.btnSquare}>
              <span className={classes.btnSquare}>{item.source_type}</span>
            </Badge>
          </div>
          <div className={classes.descriptionText}>{textValue(item.test_description)}</div>
        </div>
      </TableColumn>
      <TableColumn align={'center'}>
        <OwnerDropdown
          className=""
          name="owner_id"
          value={owner}
          copy={item.copy}
          itemId={item.control_test_id}
          onChange={handleChange}
          options={ownersOptions}
          textValue={textValue} />
      </TableColumn>
      <TableColumn align={'center'}>
        <div className={classes.sourceColumn}>
          <img width={40} src={controlIcon && controlIcon[0] && controlIcon[0].icon} alt="ControlTests" />
          <Typography className={classes.commonText}>
          {textValue(controlIcon && controlIcon[0] && controlIcon[0].service)}
          </Typography>
        </div>
      </TableColumn>
      <TableColumn>
          {item.controls.map((control: any, index) => {
            if(index < 3){
              let badgeClasses;
              if (control.includes('SOC 2')) badgeClasses = classes.badgeyellow;
              else if (control.includes('NIST CSF')) badgeClasses = classes.badgepurple;
              else if (control.includes('NIST 800-53')) badgeClasses = classes.badgeparrot;
              else if (control.includes('PCI DSS')) badgeClasses = classes.badgepink;
              else if (control.includes('ISO27001')) badgeClasses = classes.badgesky;
              else if (control.includes('GDPR')) badgeClasses = classes.badgered;
              else if (control.includes('CIS CSC')) badgeClasses = classes.badgeorange;
              else if (control.includes('HIPAA')) badgeClasses = classes.badgegreen;
              else if (control.includes('COBIT 5')) badgeClasses = classes.badgeblue;
              return (
                <Badge className={[badgeClasses, classes.commonChip].join(' ')}>
                  {textValue(control)}
                </Badge>
              );
            }
          })}
          {controlCount ?
            showCount()
            : null}
      </TableColumn>
      <TableColumn>
        <Button 
          onClick={() => addEditForm(item)} 
          type={"flat"}>
          <EllipsisIcon />
        </Button>
      </TableColumn>
    </TableRow>
  )
}

function ControlTable({ data, isLoading, setFormView, searchValue }: { data: ControlTestsList[], isLoading: boolean, setFormView: any, searchValue: string}) {
 
  const controlIcon = useSelector<AppState,ControlIcon[]>(
    (state) => state.settings.controlTestIcon)

  function rowRenderer(item: ControlTestsList) {
    return (
      <TableItem key={item.control_test_id} item={item} setFormView={setFormView} searchValue={searchValue} />
    )
  }
  return (
    <Table
      columns={tableColumns}
      data={data.filter((d: any) => 
        (controlIcon[0] && controlIcon[0].service.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) ||
        d.test_description.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 ||
        // d.owner_name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 ||
        d.controls.filter((t:any) => t.toUpperCase().indexOf(searchValue.toUpperCase()) > -1).length !== 0 
        )}
      isLoading={isLoading}
      rowRenderer={rowRenderer} />
  )
}

export default ControlTable;