import React, { useState, useMemo } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import TableSkeleton from "components/skeletons/table-skeleton";

import {
  Row,
  Column,
  Alignment,
  SortDirection,
} from "interfaces/table-interface";

import { sortTable } from "utils/table-utils";

import useStyles from "./table-wrapper.styles";

interface IProps<T, K> {
  columns: Column[];
  rows: Row<T, K>[];
  search?: string;
  emptyColumn?: JSX.Element;
  isLoading?: boolean;
  renderRow?: (row: Row<T, K>, index: number) => void;
  renderColumn?: (column: Column, index: number) => void;
}

function TableWrapper<T = string, K = undefined>(props: IProps<T, K>) {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [sortedColumnIndex, setSortedColumnIndex] = useState<number>(-1);
  const numberOfColumns = props.emptyColumn
    ? props.columns.length + 1
    : props.columns.length;
  const noResultsFound = !props.isLoading && props.rows.length === 0;

  const onTriggerSort = (columnIndex: number) => {
    setSortDirection((prevState) =>
      prevState === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
    );

    setSortedColumnIndex(columnIndex);
  };

  const renderColumn = (column: Column, index: number) => {
    if (props.renderColumn) {
      return props.renderColumn(column, index);
    }

    const columnAlignment = column.alignment
      ? column.alignment
      : Alignment.left;

    return (
      <TableCell
        className={classes.columnTitle}
        size="small"
        key={`column-${index}`}
      >
        <Box
          fontWeight="bold"
          justifyContent={columnAlignment}
          display="flex"
          whiteSpace="nowrap"
        >
          <div className={classes.headerText}>{column.title}</div>
          {column.sortable && (
            <Box
              ml={4}
              display="flex"
              alignItems="center"
              onClick={() => onTriggerSort(index)}
              className={classes.sortBtn}
            >
              <UnfoldMoreIcon fontSize="inherit" />
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const renderRow = (row: Row<T, K>, index: number) => {
    if (props.renderRow) {
      return props.renderRow(row, index);
    }

    return (
      <TableRow>
        {row.values.map((rowCell, k) => (
          <TableCell size="small" key={`row-value-${k}`}>
            {rowCell || "Not available"}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const getSortedRows = useMemo(() => {
    return sortTable<T, K>(props.rows, sortedColumnIndex, sortDirection);
  }, [sortDirection, props.rows]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getSearchedRows = () => {
    const { search } = props;
    const rows = getSortedRows;

    if (!search || search.length === 0) {
      return rows;
    }

    const results = [];

    for (let rowId in rows) {
      const { values } = rows[rowId];

      for (let value of values) {
        if (String(value).toLowerCase().includes(search.toLowerCase())) {
          results.push(rows[rowId]);
          break;
        }
      }
    }

    return results;
  };

  const renderNoResultsRow = () => {
    return (
      <TableRow>
        <TableCell colSpan={numberOfColumns} align="center">
          No results found
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer>
      <Table className={classes.tableBorder}>
        <TableHead className={classes.headerHeigth}>
          <TableRow >
            {props.columns.map(renderColumn)}
            {props.emptyColumn}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading && (
            <TableSkeleton columns={numberOfColumns} rows={10} />
          )}
          {!props.isLoading &&
            props.rows.length !== 0 &&
            getSearchedRows().map(renderRow)}
          {noResultsFound && renderNoResultsRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableWrapper;
