import { makeStyles } from '@material-ui/core/styles';

interface TextProps {
  color: string;
}

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    height: '100%',
    // boxShadow: '0px 0px 24px -15px rgba(0,0,0,0.75)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5)
    }
  },
  text: (props: TextProps) => ({
    // color: props.color,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.07em",
    textTransform: "uppercase",
    color: "#F38F8F",
  }),
  tooltip: {
    // color: (theme.palette as any).gunSmoke.main
    color: "#33CCA8",
  },
  severityCount: {
    // marginTop: theme.spacing(5),
    marginRight: theme.spacing(10),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px !important",
    lineHeight: "39px !important",
    color: "#1B2E3F !important",
  },
  chartContainer: {
    width: '100%',
    marginLeft: theme.spacing(10)
  }
}));
