import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
  },
  radio: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    userSelect: 'none',
    padding: "10px 17px",
    '&:hover': {
      background: "rgba(183, 194, 205, 0.2)",
    },
    '&:first-child': {
      marginTop: 10
    },
    '& input': {
      border: 0,
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 1
    },
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#61778B',
    }
  },
  circle: {
    position: 'relative',
    width: 20,
    minWidth: 20,
    height: 20,
    border: '2px solid #B7C2CD',
    borderRadius: '50%',
    background: '#FFFFFF',
    overflow: 'hidden',
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'border-color',
    '&::after': {
      content: `""`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 10,
      height: 10,
      backgroundColor: '#47C2A4',
      borderRadius: '50%',
      transition: '300ms cubic-bezier(0, 0, 0, 1)',
      transitionProperty: 'opacity, transform',
      transform: 'translate(-50%, -50%) scale(0.3)',
      opacity: 0
    }
  },
  checked: {
    backgroundColor: 'rgba(183, 194, 205, 0.2)',
  }
}));
