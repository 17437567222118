import React, { useEffect,useState } from "react";
import useStyles from "./profile-setting-vendor.styles";
import Button from "components/button/button";
import VendorsTable from "./vendor-table/vendor-table";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { fetchVendorTests, fetchEmployees, setHelpIndex, } from "redux/features/settings-store";
import { useSelector} from "react-redux";
import {AppState} from "../../../interfaces/app-state-interface";
import { Vendorslist } from 'interfaces/settings-interface';
import VendorForm from './vendor-form/vendor-form';
import SearchInput from "components/search-input"

const ProfileSettingVendor = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [formView, setFormView] = useState(false)
    const [searchValue,setSearchValue] = useState('')


    const vendors = useSelector<AppState, Vendorslist[]>(
        (state) => state.settings.vendors
        );

    const onPageInit = async () => {
        dispatch(setHelpIndex("settings/vendorForm"));
        dispatch(fetchVendorTests());
        dispatch(fetchEmployees());
    };

    useEffect(() => {
        onPageInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSearch = (e: any) => {
        setSearchValue(e);
    }

return(
    <Box className={classes.boxWrapper} >
        <Box m={20} mt={0} className={classes.boxWrapperInner}>
            <div className={classes.vendorPage}>
            {formView === false ?
                <React.Fragment>
                    <div className={classes.header}>
                        <div className={classes.buttons}>
                            <div className={classes.searchInputWrapper}>
                                <SearchInput onChangeText={(e) => onSearch(e)}/>
                            </div>
                            <Button onClick={() => setFormView(true)} className={classes.btnAdd} type={'info'}>
                            <span>+</span>
                                Add New Vendor
                            </Button>
                        </div>
                    </div> 
                    <VendorsTable data={vendors} setFormView={setFormView} searchValue={searchValue}/> 
                </React.Fragment>
            :null}
            {formView === true ?
                <VendorForm  setFormView={setFormView} />
            :null}
            </div>
        </Box>
    </Box>
    )
}

export default ProfileSettingVendor;