import React from "react";
import useStyles from './badge.styles'
import clsx from "clsx";

interface BadgeProps {
  children?: React.ReactNode,
  className?: string,
}

function Badge({ children, className } : BadgeProps) {
  const classes = useStyles()
  const btnClassNames = clsx(
    className,
    classes.root,
  )
  return (
    <span className={btnClassNames}>
      { children }
    </span>
  )
}

export default Badge
