import React from "react";
import useStyles from './button.styles'
import clsx from "clsx";

interface ButtonProps {
  className?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  htmlType?: 'button' | 'submit',
  children?: React.ReactNode,
  type: 'icon' | 'accent' | 'flat' | 'info' | 'infoBlue',
  disabled?: boolean
}

function Button({ className, onClick, htmlType, type, children, disabled = false }: ButtonProps) {
  const classes = useStyles()

  const btnClassNames = clsx(
    className,
    classes.root,
    classes[type],
    {
      [classes.disabled]: disabled
    }
  )

  return (
    <button className={btnClassNames} onClick={onClick} type={htmlType}>
      { children }
    </button>
  )
}

export default Button
