import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  gridItemBox: {
    /* width: "15%", */
  },
  addGridItemBox: {
    /* width: "10%", */
  },
  gridItemBoxTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "27px",
    marginBottom: "12px",
  },
  boxWrapper: {
    position: "relative",
  },
  boxWrapperInner: {
    margin: "20px 0",
    "& .headerHelpClass":{
      position: "absolute",
      right: "24px",
    }
  },
  gridBoxWrapperInner: {
    marginBottom: "20px",
    display: "grid",
    padding: "5px 20px",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr 0.5fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 0.7fr",
    '@media (max-width:767px)': {
      gridTemplateColumns: "auto",
    }
  },
  card: {
    backgroundColor: theme.palette.common.white,
    // border: `${theme.spacing(1)}px solid ${theme.palette.tableBackground.main}`,
    cursor: 'pointer',
    // padding: theme.spacing(5),
    padding: '20px',
    boxShadow: '0px 2px 4px rgb(230 230 230 / 60%)',
    borderRadius: '8px',
    border: '1px solid #E2E9EF',
    display: "block",
    height: "100px",
    '&:hover':{
      border: `${theme.spacing(1)}px solid #51BDCD`,
      background: 'rgba(81, 189, 205, 0.04)',
    }
  },
  gridItemBoxContentTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "0",
    align: "center",
    color: "#7B90A3",
  },
  textTitle: {
    textAlign: "center",
    width: "100%",
    marginTop: "0",
  },
  textTitle1: {
    textAlign: "center",
    width: "100%",
    marginTop: "0",
    lineHeight: 0,
    position: "relative",
    top: "-5px",
  },
  text: {
    textAlign: "center",
    width: "100%",
  },
  gridItemBoxtext: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "34px",
    align: "center",
    marginBottom: "0",
    color: "#1B2E3F",
    marginTop: "10px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    '@media (max-width:900px)': {
      flexDirection: "column",
    }
  },
  subTabs: {
    order: 1,
    '@media (max-width:900px)': {
      overflow: 'scroll',
      width: "100%",
      order: 2,
      "&::-webkit-scrollbar": {
        display: "none",
      }
    },
    "& button": {
      paddingBottom: "25px",
      paddingTop: "25px",
    }
  },
  addcard: {
    backgroundColor: 'rgba(183, 194, 205, 0.1)',
    border: '1px solid rgba(183, 194, 205, 0.5)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  addIcon: {
    fontSize: theme.spacing(12),
    color: "#497dab",
    border: "2px solid #fff",
    borderRadius: "50px",
  },
  addtext: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    align: "center",
    marginBottom: "12px",
    color: "#1B2E3F",
  },
  search: {
    align: "right",
    paddingRight: "20px",
  },
  paddingSpac: {
    // padding: theme.spacing(0, 10),
    padding: theme.spacing(0, 0),
  },
  stepText: {
    '& button': {
      fontFamily: "Inter",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#1B2E3F',
      lineHeight: '22px',
    }
  },
  /* commonText: {
    fontFamily: "Inter",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1B2E3F',
  } */
}));
