import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    userSelect: 'none',
    '&:first-child': {
      marginTop: 10,
    },
    '&:hover': {
      background: "rgba(183, 194, 205, 0.2)",
    },
    '& input': {
      border: 0,
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 1
    },
    '& p': {
      fontFamily: `"Inter", sans-serif`,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#61778B',
    }
  },
  disabled: {
    backgroundColor: "rgba(183, 194, 205, 0.2)",
    "& $checked": {
      backgroundColor: 'rgba(183, 194, 205, 0.2)',
      borderColor: 'rgba(183, 194, 205, 0.2)',
    }
  },
  checkmark: {
    marginRight: 10,
    position: 'relative',
    width: 22,
    minWidth: 22,
    height: 22,
    border: '1px solid #B7C2CD',
    borderRadius: 5,
    background: '#FFFFFF',
    overflow: 'hidden',
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'background-color, border-color',
    '& svg': {
      position: 'absolute',
      top: '50% ',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(0.2)',
      transition: 'transform 300ms cubic-bezier(0, 0, 0, 1)'
    }
  },
  checked: {
    backgroundColor: '#47C2A4',
    borderColor: '#47C2A4',
    '& svg': {
      transform: 'translate(-50%, -50%) scale(1)'
    }
  }
}));
