import React from "react";
import useStyles from './switcher.styles'
import clsx from "clsx";

interface SwitcherProps {
  className?: string;
  name: string;
  value: boolean;
  adopted?: boolean;
  label?:string;
  onChange?: (e: { target: { name: string, value: boolean } }) => void
}

function Switcher({ className, name, value,label = '', onChange = () => {}, adopted = false}: SwitcherProps) {
  const classes = useStyles()

  function handleClick() {
    onChange({ target: { name, value: !value } })
  }

  return (
    <>
    <button
      type="button"
      onClick={adopted ? undefined : handleClick}
      className={clsx(className, classes.root, { [classes.active]: value })}>
      <span />
    </button>
    <span style={{fontSize:"18px",fontWeight:"500",margin: "0px 16px",order: 1}}>{label}</span></>
  )
}

export default Switcher
