import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    cursor: 'pointer',
    fontFamily: `"Inter", sans-serif`,
    transition: '300ms cubic-bezier(0, 0, 0, 1)',
    transitionProperty: 'opacity, background-color'
  },
  icon: {
    background: "none",
    border: 'none'
  },
  accent: {
    height: 54,
    padding: '0 32px',
    backgroundColor: '#497DAB',
    borderRadius: '30px',
    fontWeight: 600,
    fontSize: '18px',
    color: '#fff',
    border: 'none'
  },
  info: {
    color: "white",
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: "#47C2A4",
    padding: "12px 20px",
    border: "initial",
    borderRadius: "8px",
  },
  infoBlue: {
    color: "white",
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: "#497DAB",
    padding: "12px 20px",
    border: "initial",
    borderRadius: "8px",
  },
  flat: {
    background: "none",
    border: 'none',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#33CCA8',
    padding: 0
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}));
