import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {

  },
  bold: {
    fontWeight: 'bold',
  },
  row: {
    backgroundColor: "#b7c2cd0d",
  },
  colName: {
    maxWidth: "10rem",
    '& p': {
      fontWeight: 500
    },
  },
  inputText: {
    width: '100%',
    padding: "13px 16px",
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
    fontWeight: 500,
    fontFamily: `"Inter", sans-serif`,
    fontSize: 16,
    lineHeight: '19px',
    color: '#1B2E3F',
    border: 'initial',
    borderBottom: "1px solid rgba(183, 194, 205, 0.5)",
    "&::placeholder": {
      color: "#B7C2CD"
    },
    "&:focus": {
      border: 'initial',
      borderBottom: "1px solid #33CCA8",
      boxShadow: "0px 3px 0px 0px rgb(51 204 168 / 20%)"
    }
  },
  errorName: {
    '& input': {
      border: "1px solid #F38F8F",
      boxShadow: "0px 0px 0px 3px rgba(255, 94, 94, 0.1)"
    },
  },
  showCount: {
    display: "flex",
    color: "#99A9B8",
    fontSize: "inherit",
    border: "1px solid #99A9B8",
    borderRadius: "4px",
    marginRight: "7px",
    alignItems: "center",
    padding: "6px 8px",
    width: "9px",
    height: "20px",
    justifyContent:'center'
  },
  showCountinactive: {
    display: "flex",
    color: "#99a9b880 !important",
    fontSize: "inherit",
    border: "1px solid #99A9B8",
    borderRadius: "4px",
    marginRight: "7px",
    alignItems: "center",
    padding: "6px 8px",
    width: "9px",
    height: "20px",
    justifyContent:'center'
  },
  text:{
    color: "#1b2e3f80 !important",
    fontColor: "#1b2e3f80 !important",
  },
  btnSquareInactive: {
    color: "#99999980 !important",
    background: "#9999990d !important",
  },
  btnSquare: {
    color: "#999999 !important",
    background: "none !important",
    '& span': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 'normal',
    fontSize: "16px",
    color: '#1B2E3F',
    lineHeight: "19px",
  },
  commonChip: {
    height: '26px',
    borderRadius: '4px',
    display: 'inline-block!important',
    whiteSpace: 'nowrap',
    overflow: 'hidden!important',
    textOverflow: 'ellipsis',
  },
  badgeyellow: {
    color: "#ffb800 !important",
    background: "#ffc63333 !important",
    width:"70px"
  },
  badgepurple: {
    color: "#9327E8 !important",
    background: "#9327e81a !important",
    width: '88px',
  },
  badgeparrot: {
    color: "#4DD662 !important",
    background: "#4dd66226 !important",
    width: '105px',
  },
  badgepink: {
    color: "#fb63ac !important",
    background: "#fb63ac1a !important",
    width: '85px',
  },
  badgesky: {
    color: "#2cb3fe !important",
    background: "#2cb3fe1a !important",
    width: '88px'
  },
  badgered: {
    color: "#ff5e5e !important",
    background: "#ff5e5e1a !important",
    width: '70px',
  },
  badgeorange: {
    color: "#f7981d !important",
    background: "#f7981d26 !important",
    width: '85px',
  },
  badgegreen: {
    color: "#009d44 !important",
    background: "#009d441a !important",
    width: '70px',
  },
  badgeblue: {
    color: "#1078FF !important",
    background: "rgba(16, 120, 255, 0.1) !important",
    width: "80px",
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: 0,
    height: 0,
    overflow: "hidden"
  },
  btnsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  btnSave: {
    height: 30,
    width: 30,
    backgroundColor: '#33CCA8',
    fontSize: '15px',
    padding: '0 7px',
    marginRight: '8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  btnCancel: {
    height: 30,
    width: 30,
    backgroundColor: '#F38F8F',
    fontSize: '15px',
    padding: '0 8px',
    "& svg": {
      width: "16px",
      height: "16px",
    }
  },
  spinnerWrapper: {
    marginRight: 8,
    "& div": {
      borderTopColor: 'white',
    }
  },
  noWrap: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  badgeFixWidth: {
      width: '78px',
      marginBottom: '5px',
      background: "rgba(153, 153, 153, 0.1)",
      borderRadius: "4px",
  },
  sourceColumn: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  },
  commonText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#1B2E3F',
  }
}));
