import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  MenuItem,
  Popper,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";

import {
  FindInPage as FindInPageIcon,
  ExpandMore as ExpandMoreIcon,
  Description as DescriptionIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import GetAppIcon from '@material-ui/icons/GetApp';

import SearchInput from "components/search-input";

import useStyles from "../section-wrapper.styles";

export interface IProps {
  toolsPosition?: "flex-start" | "flex-end";
  defaultVisibility?: boolean;
  visibilityTooltipText?: string;
  scanBtnTitle?: string;
  onScan?: () => void;
  onSearch?: (text: string) => void;
  onDownloadXLS?: () => void;
  onDownloadJSON?: () => void;
  onToggleVisibility?: (visible: boolean) => void;
  onCreateCustomRule?: () => void;
  isDownloadIcon?: boolean;
}

const SectionToolsWrapper = (props: IProps) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const downloadList = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(props.defaultVisibility || false);
  const visibilityTooltipInfo = props.visibilityTooltipText
    ? props.visibilityTooltipText
    : "Show only controls with gaps";

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  const useOutsideAlerter =(ref: any) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
              setOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  useOutsideAlerter(downloadList);

  const onChangeVisibility = () => {
    if (!props.onToggleVisibility) {
      return;
    }

    props.onToggleVisibility(!isVisible);
    setVisible(!isVisible);
  };

  function toggleDropdown() {
    setOpen(prevState => !prevState)
  }
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.setHeaderGrid}>
        <div>
          {props.onSearch && <SearchInput onChangeText={props.onSearch} />}
        </div>
        <div  className={classes.wrapCol}>
          <div>
            {props.onToggleVisibility && (
              <Tooltip title={visibilityTooltipInfo} placement="top">
                <Button
                  className={`${classes.button} ${classes.visibleBtn}`}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={onChangeVisibility}
                >
                  {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Button>
              </Tooltip>
            )}
          </div>
          <div>
            {props.onScan &&
              (props.scanBtnTitle ? (
                <Tooltip
                  placement="top"
                  title="Re-evaluates the miscofiguration policies against the system state. The backend system state is updated near-real time."
                >
                  <Button
                    className={[classes.button, classes.bgColor].join(' ')}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={props.onScan}
                  >
                    {props.scanBtnTitle}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  className={[classes.button, classes.bgColor].join(' ')}
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={props.onScan}
                >
                  Scan Now
                </Button>
              ))}
          </div>
          <div>
            {(props.onDownloadXLS || props.onDownloadJSON) && (
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <>
                  <Button
                    className={`${classes.button} ${classes.downloadBtn}`}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    ref={anchorRef}
                    onClick={toggleDropdown}
                    endIcon={<ExpandMoreIcon fontSize="small" />}
                  >
                    {props.isDownloadIcon? <GetAppIcon></GetAppIcon> : null}
                    Download
                  </Button>

                  <Popper open={isOpen} anchorEl={anchorRef.current} ref={downloadList}>
                    <Box mt={3}>
                      <Paper>
                        {props.onDownloadXLS && (
                          <MenuItem onClick={props.onDownloadXLS}>
                            <DescriptionIcon fontSize="small" />
                            <Box ml={3}>Download.xls</Box>
                          </MenuItem>
                        )}

                        {props.onDownloadJSON && (
                          <MenuItem onClick={props.onDownloadJSON}>
                            <FindInPageIcon fontSize="small" />
                            <Box ml={3}>Download.json</Box>
                          </MenuItem>
                        )}
                      </Paper>
                    </Box>
                  </Popper>
                </>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionToolsWrapper.defaultProps = {
  toolsPosition: "flex-end",
  isDownloadIcon: false,
};
export default SectionToolsWrapper;

