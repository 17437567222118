import { useSelector } from 'react-redux';

import { AppState } from 'interfaces/app-state-interface';
import { Violation, ViolationDetails } from 'interfaces/violations-interface';
import { Column, Row, Alignment } from 'interfaces/table-interface';

export const ViolationColumns: Column[] = [
  { title: 'Rule Name', sortable: false },
  { title: 'Platform', sortable: true },
  { title: 'Service', sortable: false },
  { title: 'Risk Rating', sortable: true },
  { title: 'Last Assessed', sortable: false, alignment: Alignment.center },
  { title: 'Assets', sortable: false, alignment: Alignment.center }
];

type ReturnValue = [Column[], Row<string>[]];

export const useViolationsRules = (): ReturnValue => {
  const violations = useSelector<AppState, Violation[]>((state) => state.violations.misconfig);

  const violationsRow = violations.map((violation) => {
    const { name, service, rule_id, last_executed_on, platform, severity } = violation.rule;
    const assets_count = violation.resources.reduce((prevSuppress, currentSuppress) => {
      if (currentSuppress.suppressed !== false) {
        prevSuppress += 1;
      }

      return prevSuppress;
    }, 0);

    // const last_executed_on_date = moment(last_executed_on).format('MMM D, YYYY');
    const values = [
      name,
      platform,
      service,
      severity,
      last_executed_on,
      assets_count.toString()
    ];

    return { values, ruleId: rule_id };
  });

  return [ViolationColumns, violationsRow];
};

const columns: Column[] = [
  {
    title: 'Asset',
    sortable: false
  },
  {
    title: 'Region',
    sortable: true
  },
  {
    title: 'Account number',
    sortable: false
  }
];

type ReturnViolationAssets = [Column[], ViolationDetails] | undefined;

export const useViolationAssets = (rule_id: string): ReturnViolationAssets => {
  const violations = useSelector<AppState, Violation[]>((state) => state.violations.misconfig);

  const violationRow = violations.find((violation) => violation.rule.rule_id === rule_id);

  if (violationRow) {
    const assetsDetails = violationRow.resources.map((resource) => {
      let node = resource.nodes[0];

      return {
        values: [node.properties.node_id, node.region, node.account, resource.suppressed?.toString()],
        ruleId: node.label
      };
    });

    return [
      columns,
      {
        assetsDetails,
        description: violationRow.rule.description,
        remediation: violationRow.rule.remediation
      }
    ];
  }
};
