import React, { useState, useRef, useEffect } from "react";
import useStyles from './DropDownControl.style';
import clsx from "clsx";
import {
    searchCompilance, 
} from "redux/features/settings-store";
import { Option } from "interfaces/common-intefaces";
import {AppState} from "interfaces/app-state-interface";
import DropdownWrapper from "components/dropdown-wrapper";
import CheckboxGroup from "components/checkbox-group/checkbox-group";
import { ReactComponent as CrossIcon } from "icons/cross.svg";
import Spinner from "components/spinner";
import {useDispatch, useSelector} from "react-redux";
import ChipInput from 'material-ui-chip-input';
import { ReactComponent as TriangleIcon } from "icons/triangle.svg";


interface DropDownControlprops {
    options: Option[];
    name: string;
    values?: string[];
    itemId?: string;
    adopted?: boolean;
    tab?: string;
    onChange: (e: { target: { name: string, value: string[] } }) => void
}

function DropDownControl({ options, name, values = [], onChange, itemId, adopted = false, tab = '' }: DropDownControlprops) {
    const classes = useStyles();
    const [opened, setOpened] = useState(false);
    const [searchControl, setSearchControl] = useState<string>();
    // const [optionsTest, setOptionsTest] = useState<any>(options);
    const [isLoadingResultControls, setIsLoadingResultControls] = useState<boolean>(false);
    const controlInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    let resultSearchCompilance: Option[] = useSelector<AppState, any[]>(
        (state) => state.settings.searchCompilanceData
      ).map((item: any) => {
        return {label: item, value: item}
      });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => {
        setIsLoadingResultControls(true)
        const delayDebounceFn = setTimeout(() => {
            if (searchControl) {
                (async () => {
                  dispatch(searchCompilance(searchControl))
                // setOptionsTest(options.filter((option: any) => option.value.toUpperCase().indexOf(searchControl.toUpperCase()) > -1));
                })()
            }
        }, 2000)
        const delayDebounceSpinner = setTimeout(() => {
            setIsLoadingResultControls(false)
        }, 3000)
        return () => {
            clearTimeout(delayDebounceFn)
            clearTimeout(delayDebounceSpinner)
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchControl])

    useEffect(() => {
        controlInputRef.current?.focus();
    }, []);

    function toggleDropdown() {
        setOpened(prevState => !prevState)
    }

    function handleClose() {
        setOpened(false)
    }

    function handleCross(index: any) {
        let updatedValues = [...values]
        updatedValues = values.filter(value => value !== values[index])
        onChange({ target: { name, value: updatedValues } })
    }
    
    const resultSearchCompilanceData = searchControl ? resultSearchCompilance.filter(compilance => compilance.value.toUpperCase().indexOf((searchControl as any).toUpperCase()) > -1) : [];

    return (
        <DropdownWrapper
            className={classes.root}
            opened={opened}
            search={true}
            topicsSearch={true}
            addTopAuto
            triggerEl={
                <div className={classes.btnTrigger}>
                    <div className={classes.wrapperText} data-tip data-for={'registerTip-' + itemId + ((values?.length === 0) ? 'Topic' : values[values?.length - 1])} >
                    <div className={values?.length === 0 ? clsx(classes.wrapperInput) : clsx(classes.wrapperInputWithVal)} onClick={adopted ? undefined : toggleDropdown}>
                        <ChipInput
                            ref={controlInputRef}
                            fullWidthInput
                            fullWidth
                            placeholder= {values?.length === 0 ? "Standard & Control(s)" : ""}
                            alwaysShowPlaceholder
                            disableUnderline
                            chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) : any => {
                                return (
                                <React.Fragment key={key + itemId}><span>{value}<span onClick={() => handleCross(key)}><CrossIcon/></span></span>
                                </React.Fragment>
                                )
                            }}
                            value={values}
                            onUpdateInput={(e: any) => setSearchControl(e.target.value)}
                            onDelete={(chip, index) => handleCross(index)}
                        />
                        <span className={classes.triabgleButton}>{adopted ? "" : <TriangleIcon onClick={toggleDropdown} />}</span>
                    </div>
                    </div>
                </div>
            }
            onClose={handleClose}>
            {isLoadingResultControls && 
                <div className={classes.spinnerWrapper}>
                    <Spinner />
                </div>
            }
            {
                resultSearchCompilanceData.length === 0 && <div className={classes.notResult}>No Controls found</div>
            }
            {!isLoadingResultControls && resultSearchCompilance &&
                <CheckboxGroup
                    name={name}
                    tab={tab}
                    checkboxClassName={classes.checkbox}
                    options={resultSearchCompilanceData}
                    values={values}
                    onChange={onChange} />
            }
        </DropdownWrapper>
    );
}

export default DropDownControl;