import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

import palette from "./palette";
import typography from "./typography";
import borderRadius from "./border-radius";

const theme = createTheme({
  spacing: 2,
  palette,
  typography,
  borderRadius,
} as any);

export default responsiveFontSizes(theme);
