import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    color: "#47C2A4",
    alignItems: "center",
    fontSize: "12px",
    backgroundColor: "#E0F8F1",
    borderRadius: "4px",
    marginRight: "7px",
    padding: "6px 10px",
    textTransform: "uppercase",
    lineHeight: 1,
  },
  // wrapper: {
  //   verticalAlign: "sub"
  // }
}));
