import { AxiosPromise } from 'axios';
import httpService from './http-service';

import { RuleType, Rule, CreateCustomRule } from 'interfaces/rule-interface';

export const getRules = (type: RuleType): AxiosPromise<{ rules: Rule[] }> => {
  return httpService.get(`/aws/rules?type=${type}`);
};

export const createCustomRule = (values: CreateCustomRule) => {
  return httpService.post(`/aws/rules`, values);
};

export const updateCustomRule = (values: CreateCustomRule) => {
  return httpService.put(`/aws/rules`, values);
};

export const deleteCustomRule = (id: string) => {
  return httpService.delete(`/aws/rules/${id}`);
};

export const executeRules = () => {
  return httpService.get(`/aws/rules`);
};

export const activateRule = (id: string) => {
  return httpService.put(`/aws/rules/${id}/activate`);
};

export const deactivateRule = (id: string) => {
  return httpService.put(`/aws/rules/${id}/deactivate`);
};
