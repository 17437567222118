import { AxiosPromise } from "axios";

import httpService from "./http-service";
import { axiosIntegrationsInstance } from "./http-service";


import {
  InventorySummary,
  InventoryServiceSummary,
  RegionsService,
  TableArnResponse,
  InventoryResource,
  InventoryEmployees
} from "interfaces/inventory-interface";
import { DownloadFormat } from "interfaces/download-interface";

export const listArns = (
  region: string | undefined = undefined
): AxiosPromise<TableArnResponse> => {
  return httpService.get("/aws/inventory", {
    params: {
      region,
    },
  });
};

export const listIamSummary = (): AxiosPromise<InventorySummary> => {
  return httpService.get("/aws/inventory/iam-summary");
};

export const listStorageSummary = (): AxiosPromise<InventorySummary> => {
  return httpService.get("/aws/inventory/storage-summary");
};

export const listInventorySummary = (): AxiosPromise<
  InventoryServiceSummary
> => {
  return httpService.get("/aws/inventory/summary");
};

export const listRegions = (): AxiosPromise<RegionsService> => {
  return httpService.get("/aws/regions");
};

export const listRegionSummary = (
  region: string
): AxiosPromise<InventoryServiceSummary> => {
  return httpService.get("/aws/inventory/summary", {
    params: {
      region,
    },
  });
};

export const listResources = (
  arn: string,
  label: string
): AxiosPromise<InventoryResource> => {
  return httpService.get("/aws/inventory/resource", {
    params: {
      "node-id": arn,
      label,
    },
  });
};

export const scanInventory = (
  region?: string
): AxiosPromise<TableArnResponse> => {
  if (region) {
    return httpService.put(`/aws/inventory`, {
      params: {
        region,
      },
    });
  } else {
    return httpService.put(`/aws/inventory`);
  }
};

export const downloadInventory = (format: DownloadFormat) => {
  return httpService
    .get("/aws/inventory/download", {
      params: {
        format,
      },
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inventory." + format);
      document.body.appendChild(link);
      link.click();
    });
};

export const employeesInventory = (): AxiosPromise<InventoryEmployees> => {
  return axiosIntegrationsInstance.get("/compliance/v1/employees");
};

export const workstationsInventory = (): AxiosPromise<InventoryEmployees> => {
  return axiosIntegrationsInstance.get("/compliance/v1/workstations");
};

export const cloudInventoryCount = (): AxiosPromise<InventoryEmployees> => {
  return axiosIntegrationsInstance.get("/v1/cloud/inventory/summary");
};

