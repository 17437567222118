import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import get from "lodash/get";

import * as ComplianceService from "services/compliance-service";
import { setLoading, getHelp } from "./ui-store";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  ComplianceStandard,
  ComplianceSection,
  ComplianceControl,
  ComplianceControlRule,
  ComplianceControlsSummary,
  ComplianceServiceSummary,
  ComplianceViolationAsset,
} from "interfaces/compliance-interface";
import { DownloadFormat } from "interfaces/download-interface";

export interface ComplianceStore {
  standards: ComplianceStandard[];
  sections: ComplianceSection[];
  controls: ComplianceControl[];
  control_rules: ComplianceControlRule[];
  active_standard: ComplianceStandard | undefined;
  active_section: ComplianceSection | undefined;
  controls_summary: ComplianceControlsSummary;
  services_summary: ComplianceServiceSummary[];
}

const initialState: ComplianceStore = {
  standards: [],
  sections: [],
  controls: [],
  control_rules: [],
  active_standard: undefined,
  active_section: undefined,
  controls_summary: {
    controls_with_rules: 0,
    controls_with_violations: 0,
    controls_without_rules: 0,
    controls_without_violations: 0,
    total_controls: 0,
  },
  services_summary: [],
};

const slice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    setStandards: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceStandard[]>
    ) => {
      store.standards = action.payload;
    },
    setSections: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceSection[]>
    ) => {
      store.sections = action.payload;
    },
    setControls: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceControl[]>
    ) => {
      store.controls = action.payload;
    },
    setControlRules: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceControlRule[]>
    ) => {
      store.control_rules = action.payload;
    },
    setActiveStandard: (
      store: ComplianceStore,
      action: PayloadAction<string>
    ) => {
      const standard = store.standards.find(
        ({ Standard }) => Standard.node_id === action.payload
      );
      store.active_standard = standard;
    },
    setActiveSection: (
      store: ComplianceStore,
      action: PayloadAction<string>
    ) => {
      const section = store.sections.find(
        ({ Section }) => Section.node_id === action.payload
      );
      store.active_section = section;
    },
    setControlsSummary: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceControlsSummary>
    ) => {
      store.controls_summary = action.payload;
    },
    setServicesSummary: (
      store: ComplianceStore,
      action: PayloadAction<ComplianceServiceSummary[]>
    ) => {
      store.services_summary = action.payload;
    },
    addViolationsToRule: (
      store: ComplianceStore,
      action: PayloadAction<{
        ruleId: string;
        violations: ComplianceViolationAsset[];
      }>
    ) => {
      const { ruleId, violations } = action.payload;

      const rules = store.control_rules.filter(
        (control) => control.Rule.rule_id === ruleId
      );

      if (rules.length === 0) {
        return;
      }

      rules.forEach((rule) => (rule.Violations = [...violations]));
    },
  },
});

export const onPageInit = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    dispatch(setLoading(true));

    await ComplianceService.listSupportedStandards()
      .then(async (res) => {
        const { response } = res.data;
        const activeStandard = response[0].Standard.node_id;

        await dispatch(setStandards(response));
        await dispatch(changeActiveStandard(activeStandard));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });

    dispatch(loadHelpText());
    dispatch(setLoading(false));
  }
};

const loadHelpText = (): AppThunk => async (dispatch, store) => {
  const indexes = [
    "compliance/nciByService",
    "compliance/nciBySection",
    "compliance/controlsViewTable",
  ];

  for (const index of indexes) {
    dispatch(getHelp(index));
  }
};

export const fetchSupportedStandards = (): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ComplianceService.listSupportedStandards()
      .then((res) => {
        dispatch(setStandards(res.data.response));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const changeActiveStandard = (standard_id: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    dispatch(setActiveStandard(standard_id));

    await ComplianceService.listStandardSections(standard_id)
      .then(async (res) => {
        const { response } = res.data;
        const activeSection = response[0].Section.node_id;

        await dispatch(setSections(response));
        await dispatch(changeActiveSection(activeSection));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const changeActiveSection = (section_id: string): AppThunk => async (
  dispatch,
  store
) => {
  dispatch(setLoading(true));

  await dispatch(setActiveSection(section_id));
  await dispatch(fetchServicesSummary());

  dispatch(setLoading(false));
};

export const fetchStandardControls = (section_id?: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    const sectionId =
      section_id ||
      get(store().compliance, "active_section.Section.node_id", "");

    await ComplianceService.listStandardControls(sectionId)
      .then(async (res) => {
        const { response } = res.data;
        await dispatch(setControls(response));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchControlRules = (control_id: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    const controls = store().compliance.controls;
    const control_rules = [...store().compliance.control_rules];

    const currentControl = controls.find(
      (control) => control.Control.node_id === control_id
    );
    const hasFetchedRules = control_rules.find(
      (rule) => rule.control_id === control_id
    );

    if (!currentControl || hasFetchedRules) {
      return;
    }

    try {
      const controlRulesResponse = await ComplianceService.listControlRules(
        control_id
      );
      const controlRules: ComplianceControlRule[] =
        controlRulesResponse.data.response;

      for (let rule of controlRules) {
        control_rules.push({
          ...rule,
          Violations: [], // violations are set later, when a rule is expanded
          control_id,
        });
      }
    } catch (error) {
      if ((error as any).response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    }

    dispatch(setControlRules(control_rules));
  }
};

export const fetchServicesSummary = (): AppThunk => async (dispatch, store) => {
  const active_standard = store().compliance.active_standard;
  const active_section = store().compliance.active_section;

  if (localStorage.getItem("token")) {
    await ComplianceService.listServicesSummary(
      active_standard!.Standard.node_id,
      active_section!.Section.node_id
    )
      .then(({ data }) => {
        dispatch(setServicesSummary(data.response));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchControlsSummary = (): AppThunk => async (dispatch, store) => {
  const active_standard = store().compliance.active_standard;
  const active_section = store().compliance.active_section;

  if (localStorage.getItem("token")) {
    await ComplianceService.listControlsSummary(
      active_standard!.Standard.node_id,
      active_section!.Section.node_id
    )
      .then(({ data }) => {
        dispatch(setControlsSummary(data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchViolationsAssets = (ruleId: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ComplianceService.listViolationsAssets(ruleId)
      .then(({ data }) => {
        dispatch(addViolationsToRule({ ruleId, violations: data.violations }));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const downloadCompliance = (
  standard: string,
  format: DownloadFormat
): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await ComplianceService.downloadCompliance(standard, format)
      .then((res) => res)
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const scanComplianceControls = (section_id?: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    const sectionId =
      section_id ||
      get(store().compliance, "active_section.Section.node_id", "");

    await ComplianceService.scanComplianceControls(sectionId)
      .then(({ data }) => dispatch(setControls(data.response)))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const { reducer } = slice;
export const {
  setStandards,
  setSections,
  setControls,
  setControlRules,
  setActiveStandard,
  setActiveSection,
  setControlsSummary,
  setServicesSummary,
  addViolationsToRule,
} = slice.actions;
