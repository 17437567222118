import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expandedRow: {
    backgroundColor: (theme.palette as any).alto.main
  },
  expandedCell: {
    padding: 0
  },
  expandBtn: {
    whiteSpace: 'nowrap'
  },
  actionIconBorder: {
    border: '1px solid #B7C2CD80',
    borderRadius: '4px',
  },
  riskRatingCell: {
    padding: '5px 9px',
    textTransform: 'uppercase',
    borderRadius: '5px',
    width: '100px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    fontWeight: 600,
  },
  highRatingCell: {
    border: '1px solid #F38F8F',
    color: '#F38F8F'
  },
  mediumRatingCell: {
    border: '1px solid #FFBC00',
    color: '#FFBC00'
  },
  lowRatingCell: {
    border: '1px solid #33CCA8',
    color: '#33CCA8',
  },
  bodyText: {
    color: '#1B2E3F',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
  },
  rowBorderBottom: {
    borderBottom: '0px solid rgba(183, 194, 205, 0.3)',
  }
}));
