import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: theme.spacing(26),
    height: theme.spacing(14),
    padding: 1
  },
  switchBase: {
    padding: theme.spacing(1.5),
    color: (theme.palette as any).gunSmoke.main,
    '&$checked': {
      transform: 'translateX(23px)',
      color: theme.palette.secondary.main,
      '& + $track': {
        backgroundColor: (theme.palette as any).gallery.main,
        opacity: 1
      }
    }
  },
  checked: {},
  thumb: {
    width: theme.spacing(11),
    height: theme.spacing(11)
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: (theme.palette as any).gallery.main,
    opacity: 1
  }
}));
