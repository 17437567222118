import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grey: {
    backgroundColor: (theme.palette as any).tableBackground.main
  },
  subtextColor: {
    color: (theme.palette as any).subtext.main
  }
}));
