import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    '@media (max-width:900px)': {
      overflowY: "scroll"
    }
  },
  tableHeader: {
    width: '100%',
    height: 64,
    background: 'rgba(183, 194, 205, 0.15)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: '0 28px 0 40px',
    padding: '0 28px 0 24px',
    borderTop: '1px solid rgba(183, 194, 205, 0.3)',
    '& p': {
      // fontFamily: `"Inter", sans-serif`,
      // fontWeight: 400,
      // fontSize: 15,
      // lineHeight: '18px',
      // color: '#7B90A3',
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "18px",
      textAlign: "center !important",
      color: "#7B90A3",
    },
    '& div:nth-child(1) p': {
      textAlign: "left !important",
    },
    '@media (max-width:900px)': {
      background: "transparent"
    }
  },
  tableBody: {
    // minHeight: "35vh",
    // maxHeight: "66vh",
    // overflowY: "scroll",
    width: '100%',
    '@media (max-width:900px)': {
      overflowY: "initial"
    }
  }
}));
