import React, {useState} from "react";
import useStyles from './topics-dropdown.styles'
import DropdownWrapper from "../../../../components/dropdown-wrapper";
import CheckboxGroup from "../../../../components/checkbox-group/checkbox-group";
import {Option} from "../../../../interfaces/common-intefaces";
import Button from "../../../../components/button";
import { ReactComponent as TriangleIcon } from "icons/triangle.svg";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

interface TopicsDropdownProps {
  options: Option[];
  name: string;
  values?: string[];
  itemId?: string;
  adopted?: boolean;
  tab?: string;
  onChange: (e: { target: { name: string, value: string[] } }) => void
}

function TopicsDropdown({ options, name, values = [], onChange, itemId, adopted = false, tab = '' }: TopicsDropdownProps) {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)
  const [hideTooltipPolicyName, setHideTooltipPolicyName] = useState(false)

  function handleCell() {
    if(values.length > 1) {
      setHideTooltipPolicyName(true)
    } else {
      setHideTooltipPolicyName(false)
    }
  }

  function toggleDropdown() {
    setOpened(prevState => !prevState)
  }

  function handleClose() {
    setOpened(false)
  }

  return (
    <DropdownWrapper
      className={classes.root}
      opened={opened}
      search={true}
      topicsSearch={true}
      triggerEl={
        <Button
          className={clsx(classes.btnTrigger, { [classes.btnTriggerActive]: opened, [classes.grayText]: adopted })}
          onClick={adopted ? undefined : toggleDropdown}
          type={'icon'}>
          <div className={classes.wrapperText} data-tip data-for={'registerTip-' + itemId + ((values?.length === 0) ? 'Topic' : values[values?.length - 1])} onMouseEnter={(e) => handleCell()}>
            <p>{ values?.length === 0 ? <div style={{color: "#B7C2CD"}}>Select Topic(s)</div> : values?.map((item, index) => <React.Fragment key={item + index + itemId}>{index === 0 ? null : ","} {item}</React.Fragment>)}</p>
          </div>
          { hideTooltipPolicyName && <ReactTooltip id={'registerTip-' + itemId + ((values?.length === 0) ? 'Topic' : values[values?.length - 1])} place="top" effect="solid">
            { values?.length === 0 ? 'Select Topic(s)' : values.map((item, index) => { return(<React.Fragment key={item + index + itemId}>{index === 0 ? '' : ','} {item}</React.Fragment>) }) }
          </ReactTooltip> }
          <span>
            { values.length }
          </span>
          { adopted ? "" : <TriangleIcon/>}
        </Button>
      }
      onClose={handleClose}>
      <CheckboxGroup
        name={name}
        tab={tab}
        checkboxClassName={classes.checkbox}
        options={options}
        values={values}
        onChange={onChange} />
    </DropdownWrapper>
  )
}

export default TopicsDropdown
